

@font-face {
  font-family: 'centurygothic';
  src: local('centurygothic'),
      url('../src/fonts/CenturyGothic/centurygothic.ttf');
}
@font-face {
  font-family: 'centurygothic bold';
  src: local('centurygothic'),
      url('../src/fonts/CenturyGothic/centurygothic_bold.ttf');
}
@font-face {
  font-family: 'gothampro';
  src: local('gothampro'),
      url('../src/fonts/GothamPro/gothampro.ttf');
}
input[type='radio'] {
  accent-color: #18A099;
}
input, textarea, .input-selected input {
  outline-color: #3FD2C7;
}
input:focus, textarea:focus, .input-selected input:focus {
  background-color: #141414;
  opacity: 0.9;
  color: white;
}
input, textarea {
  border: 1px solid #00458B;
  padding: 0 5px;
  font-size: 16px;
}
input[type='file'] {
  border: none;
}
textarea {
  padding: 5px;
  line-height: 1.5;
}
.input-err {
  border: 1px solid #CD5C5C;
  outline: 1px solid #CD5C5C;
  background-color: #141414;
  color: white;
}
.input-selected {
  border: none;
  outline: 2px solid #3FD2C7;
  background-color: #141414;
  opacity: 0.9;
  color: white;
}
header { 
  margin: 0 auto;
  max-width: 1900px;
  width: 100%;
  display: flex;
  /* position: fixed;
  background-color: white;
  z-index: 11; */
  justify-content: space-evenly;
  max-height: 85px;
  padding: 10px;
  border-bottom: 1px solid;
  border-color: #00458B;
}
.sticky-header {
  position: fixed;
  background-color: white;
  z-index: 10;
}
@media (max-width: 1024px) {
  header {
      width: 100%;
      margin: 0;
      padding: 0;
  }
}

html {
  width: 100%;
  scrollbar-color: #3FD2C7 white;
  overflow-x: hidden;
}
body{
  font-family: centurygothic;
  color: #1E1E1E;
  margin:0 auto;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
}
main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify-content: center;
  align-items: center; */
  /* overflow-x: hidden; */
  width: 100%;
  margin: auto;
  align-items: center;
}
h1,h2,h3,h4,h5,h6 {
  font-family: gothampro bold;
}
a {
  color: #00458B;
}
a:hover {
  color: #3FD2C7;
}
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 1320px;
  max-width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .container {
      max-width: 1024px;
      width: 100%;
  }
}
@media (min-width: 2000px) and (max-width: 2600px){
  .container {
      width: 1600px;
  }
}
@media (min-width: 2601px) and (max-width: 4000px){
  .container {
      width: 1900px;
  }
}
section, .inner-page-main-title-wrap {
  animation: default_ani 1s;
}
section, .hero-section {
  margin-bottom: 80px;
}
section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.section-buff {
  width: 100%;
  padding-top: 350px;
  padding-bottom: 150px;
  min-height: 300px;
  background: #4BB5E3;
  clip-path: polygon(0 15%, 100% 0, 100% 87%, 0% 100%);
  width: 100%;
  opacity: 0;
}
.hero-section {
  animation: none;
  opacity: 1;
  background-color: #141414;
  border-bottom: 1px solid;
  border-color: #00458B;
}
@media (min-width: 200px) and (max-width: 1024px){
  .hero-section {
    margin-bottom: 40px;
    min-height: 525px;
    margin-bottom: 20px;
  }
}
.main-color-title {
  font-size: 28px;
  color: #00458B;
}
.off-color-title {
  color: #00458B;
}
.hero-section {
  width: 100%;
  max-width: 1920px;
}
@media (min-width: 1025px) and (max-width: 1319px) { 
  .container {
      max-width: 100%;
      width: 1024px;
  }
}
@media (min-width: 380px) and (max-width: 993px) { 
  .container {
      width: 100%;
      max-width: 900px;
      min-width: 100%
  }
}
.container-center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
/* header */

/* hero img */
.hero-img-wrap {
  display: flex;
  flex-direction: column;
  min-height: 900px;
  width: 100%;
  background-image: url("../src/img/hero_main.jpg");
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  position: relative;
  animation: fadeIn;
  animation-duration: 900ms;
}
.hero-img-main-title-wrap {
  max-width: 700px;
  height: 188px;
  position: absolute;
  top: 150px;
  left: 245px;
}

.hero-img-main-title-wrap h1 {
  font-size: 60px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  color: white;
}
.hero-img-main-title-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
}
.hero-img-main-title-description p {
  background-color: #141414;
  color: white;
  padding: 20px;
  line-height: 1.5;
  opacity: 0.7;
}
.hero-img-main-title-controls-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.hero-img-action-button-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 50%;
  width: 48%;
  margin-right: 2%;
  align-items: center;
}
.hero-img-action-button-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}
.hero-img-action-button-main:hover,
.callback-btn:hover,
.contact-form-order-main-btn-send:hover,
.service-background-order-btn:hover,
.services-view-all-services-btn:hover,
.prefooter-order-form-btn:hover,
.main-footer-btn-send:hover,
.send-review-btn:hover {
  background-color: #00458B;
  transition: background .4s ease;
}
.video-mainpage-prezent-btn a:hover {
  width: 100%;
  background-color: #00458B;
  color: white;
  padding: 0 40px;
  transition: background .4s ease;
  
}
/* video */
.video-mainpage-title-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.video-mainpage-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.video-mainpage-title h2 {
  font-size: 46px;
  margin: 0;
}
.video-mainpage-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 800px;
  line-height: 27px;
}
.video-mainpage-description p {
  font-size: 20px;
  margin: 0;
  padding: 0 40px;
  max-width: 90%;
  text-align: center;
}
.video-mainpage-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1200px;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.video-mainpage-textblock-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 45%;
  align-items: center;
  text-align: left;
  padding-left: 40px;
}
.video-mainpage-textblock-wrap p {
  margin-bottom: 20px;
}
.video-mainpage-textblock-wrap h2 {
  color: #00458B;
  margin: 0;
  font-size: 28px;
  line-height: 58px;
}
.video-mainpage-textblock-wrap p {
  line-height: 1.8;
  padding: 0 10px;
}
.video-mainpage-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 50%;
  /* overflow: hidden; */
}
.video-mainpage-wrap video,
.video-mainpage-wrap source {
  background-image: url('../src/img/video_holder.png');
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  width: 1000px;
  min-height: 100%;
  max-width: 100%;
}
.video-mainpage-prezent-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.video-mainpage-prezent-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.video-mainpage-prezent-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0px 40px;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
}
/* independence */
.independence-main-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
}
.independence-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.independence-main-title-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.independence-main-title-wrap h2 {
  font-size: 46px;
  margin: 0;
  line-height: 58px;
}
.independence-main-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
}
.yandex-reviews-user-original-link {
  font-size: 14px;
}
/* .independence-main-item:nth-child(2) {
  margin-right: 20px;
  margin-left: 20px;
} */
/* .independence-main-item:nth-child(5) {
  margin-right: 20px;
  margin-left: 20px;
} */
.independence-main-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 30%;
  max-width: 350px;
  border: 1px solid;
  margin-bottom: 40px;
  min-width: 300px;
  min-height: 300px;
  box-shadow: 0px 4px 32px 0px rgba(50,38,5,.06);
}
.independence-main-icon-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.independence-main-icon-title-wrap h3 {
  font-size: 24px;
  color: #00458B;
  text-align: center;
}
.independence-description-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
}
.independence-description-wrap p {
  padding: 0 10px;
}
.independence-main-icon-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
/* form */
.contact-form-order-main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.contact-form-order-main-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.contact-form-order-main-title-wrap h2 {
  font-size: 46px;
  margin: 0;
  line-height: 58px;
}
.contact-form-order-main-wrap h3 {
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}
.contact-form-order-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 20px;
  width: 100%;
  margin-left: 2%;
}
.contact-form-order-main-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.contact-form-order-main-item:nth-child(2) {
  margin: 0 10px;
}
.contact-form-order-main-item,
.contact-form-order-main-item-end {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 23.5%;
}
.contact-form-order-main-item-text {
  width: 30%;
}
.contact-form-order-main-item-end {
  align-items: start;
  justify-content: flex-end;
  margin-left: 20px;
}
.contact-form-order-main-item input {
  min-height: 27px;
}
.contact-form-order-main-input-wrap,
.contact-form-order-main-label-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.contact-form-order-main-input-wrap {
  margin-right: 20px;
}
.contact-form-order-main-input-wrap input {
  min-width: 220px;
}
.contact-form-order-main-input-wrap .main-form-message {
  min-width: 300px;
}
.contact-form-order-main-item-end {
  width: 150px;
}
.contact-form-order-main-btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 32px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}
.contact-form-order-main-label-wrap label {
  font-size: 20px;
  margin-bottom: 5px;
  font-family: gothampro bold;
  cursor: pointer;
}
.independence-main-order-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1024px;
  justify-content: center;
  align-items: center;
}
/* services */
.services-mainpage-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.services-mainpage-description-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
  width: 100%;
  max-width: 1300px;
  
  justify-content: center;
}
.services-mainpage-title-wrap h2 {
  font-size: 46px;
  line-height: 58px;
  margin: 0;
}
.services-mainpage-description-wrap p {
  font-size: 20px;
  margin: 0;
  font-size: 20px;
  margin: 0;
  text-align: center;
  width: 100%;
  max-width: 89%;
}
.services-main-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}
.services-main-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 300px;
  max-width: 23%;
  margin-bottom: 20px;
  cursor: pointer;
}
.service-main-item-animate {
  animation-name:  fadeInUp;
  animation-duration: 0.5s;
}
.service-background-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.service-background-wrap img {
  width: 100%;
  max-width: 400px;
  filter: brightness(0.8);
  border-radius: 5px 5px 0 0;
}
.service-background-wrap img:hover {
  transition: all 0.3s ease-in-out;
  filter: none;
}
.service-background-title {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 5%;
  text-align: center;
  padding: 10px;
  min-width: 200px;
  font-size: 20px;
}
.service-background-title h4 {
  text-transform: none;
}
.service-background-order-btn-wrap {
  position: absolute;
  z-index: 2;
  bottom: 50%;
  left: 16%;
  font-size: 22px;
  opacity: 0;
  animation: serviceBtnShowAni 0.5s forwards;
}
.service-background-order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 180px;
  height: 30px;
  font-size: 16px;
  padding: 2.5px 10px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  outline: none;
  transition: all 1s ease-in-out;
  cursor: pointer;
}
.services-view-all-btn-wrap-btn-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.services-view-all-btn-text {
  font-size: 20px;
  max-width: 600px;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  padding-right: 40px;
}
.services-view-all-services-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}
.service-popup-happy-state-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.service-name-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  background-color: #3FD2C7;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  border-radius: 0 0 5px 5px;
}
.service-name-wrap h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: white;
  text-align: center;
}
.our-facts-mainpage-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
}
.our-facts-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.our-facts-title-wrap h3 {
  font-size: 46px;
  line-height: 42px;
  margin: 0;
}
.our-facts-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.fact-item {
  display: flex;
  flex-wrap: wrap;
  min-width: 148px;
  min-height: 148px;
  max-width: 150px;
  max-height: 150px;
  margin-right: 20px;
  background: transparent;
  border-radius: 50%;
  border: 4px solid;
  position: relative;
  margin-bottom: 20px;
}
.fact-item-content-value {
  position: absolute;
  top: 40%;
  left: 39%;
  font-size: 34px;
}
.fact-item-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 148px;
  max-width: 150px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.fact-item-text h5 {
  margin: 0;
  padding: 0;
  text-align: center;
}
.orange-circle {
  border-color: #000;
}
.blue-circle {
  border-color: #000;
}
.red-circle {
  border-color: #000;
}
.green-circle {
  border-color: #000;
}
/* steps */
.how-towork-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.timeline{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1235px;
  padding: 0;
  margin: 0;
  counter-reset: year 0;
  position: relative;
}

.timeline li{
  list-style: none;
  float: left;
  width: 14.2%;
  min-height: 200px;
  position: relative;
  text-align: center;
  line-height: 22px;
}

.timeline li:before {
  counter-increment: year;
  /* content: ''; */
  font-size: 32px;
  content: counter(year);
  width: 75px;
  height: 75px;
  border: 3px solid #00458B;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 72px;
  margin: 0 auto 10px auto;
  background: #F1F1F1;
  color: #000;
  transition: all ease-in-out .2s;
  cursor: pointer;
}
.timeline li:after{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: grey;
  top: 40px;
  left: -50%;
  z-index: -999;
  transition: all ease-in-out .2s;
}

.timeline li:first-child:after{
  content: none;
}
.timeline li.active{
  color: #555555;
}
.timeline li.active:before {
  background: #00458B;
  color: #F1F1F1;
}

.timeline li.active + li:after {
  background:  #555555;
}
/* our clients */
.our-clients-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.timeline li:hover::before {
  background-color: #00458B;
  color: white;
}
.our-clients-title-wrap h3 {
  font-size: 46px;
  line-height: 58px;
  margin: 0;
}
.our-clients-map-background {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: 400px;
  background-image: url("../src/img/clientsmap.png");
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  justify-content: center;
  align-items: center;
}
.our-clients-list-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.client-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 16.6%;
  margin-bottom: 20px;
  cursor: pointer;
  filter: grayscale(0.4);
}
.client-item img {
  max-width: 150px;
  max-height: 50px;
}
.client-item:hover {
  filter: none;
}
.client-item h5 {
  font-size: 16px;
  margin: 5px 0;
  text-align: center;
}
/* footer */
.main-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 556px;
  background-color: #171717;
  color: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.footer-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1320px;
  width: 100%;
  justify-content: center;
}
.main-footer a {
  color: white;
  text-decoration: none;
  position: relative;
}

.main-footer a::before {
  content: "";
  position: absolute;
  display: flex;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #3FD2C7;
  transform: scaleX(0);
  transform-origin:left center;
  transition: transform 0.3s ease;
}
.main-footer a:hover::before {
  transform: scaleX(1);
}

.main-footer-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.main-footer-top-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1320px;
  max-width: 100%;
  margin-top: 80px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
}
.main-footer-logo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.footer-logo-social-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.footer-logo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 40%;
  margin-right: 40px;
}
.footer-logo img {
  max-width: 150px;
}
.footer-logo-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  margin-bottom: 60px;
}
.footer-socials-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 50%;
}
.social-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 10px;
  cursor: pointer;
}
.social-item a::before, 
.footer-logo a::before {
  width: 0%;
}
.social-item img {
  max-height: 30px;
}
.whatsapp-footer-icon {
  background-color: white;
  max-height: 30px;
}
.main-footer-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  align-items: center;
}
.main-footer-bottom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1320px;
  max-width: 100%;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 40px;
  padding: 0 40px;
}
.main-footer-contact-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 33.33%;
  justify-content: flex-start;
}
.main-footer-contact-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.main-footer-contact-title-wrap h4,
.main-footer-submenu-title-wrap h4 {
  margin: 0;
  font-size: 18px;
  /* color:  #3FD2C7; */
}
.main-footer-contact-list-wrap ul {
  margin: 0;
  padding: 0;
}
.main-footer-contact-list-wrap ul li {
  list-style: none;
  margin-bottom: 5px;
}
.main-footer-copy-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  width: 1320px;
  max-width: 100%;
}
.main-footer-form-label-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 250px;
}
.main-footer-form-label-wrap h5 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 20px;
}
.main-footer-input-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 380px;
}
.main-footer-input-wrap input {
  min-height: 28px;
  min-width: 250px;
  max-width: 250px;
  max-height: 24px;
}
.main-footer-btn-send-wrap .main-footer-btn-send {
  max-width: 100px;
  width: 100px;
  min-height: 24px;
  height: 30px;
  border-radius: 2px;
}
.main-footer-btn-send {
  display: flex;
  background-color: #3FD2C7;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.main-footer-description-wrap {
  max-width: 370px;
}
.main-footer-submenu-list-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.main-footer-submenu-title-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.main-footer-submenu-title-wrap h4 {
  margin: 0;
  padding: 0;
}
.main-footer-submenu {
  margin: 0;
  padding: 0;
}
.main-footer-submenu li {
  list-style: none;
  margin-bottom: 5px;
}
/* prefooter-form */
.prefooter-order-section {
  margin: 0;
  padding: 0;
}
.prefooter-order-form-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.prefooter-form-happystate-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  align-items: center;
}
.prefooter-order-form-background {
  min-width: 380px;
  min-height: 400px;
  background-image: url("../src/img/footer_background.jpg");
  /* filter: brightness(0.6); */
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.prefooter-form-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 14%;
  padding: 60px 0 20px 0;
}
.prefooter-from-title h3 {
  font-size: 22px;
  color: #00458B;
}
.prefooter-form-wrap form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 600px;
  background-color: #fff;
  min-width: 600px;
  padding: 40px;
  border: 1px solid;
  min-height: 500px;
  min-width: 300px;
  border: 1px solid #00458B;
}
.prefooter-form-wrap form input {
  min-height: 30px;
  margin-bottom: 10px;
}
.prefooter-form-wrap form textarea {
  min-height: 60px;
  margin-bottom: 20px;
  padding: 5px;
}
.prefooter-form-wrap form label {
  font-size: 20px;
  margin-bottom: 5px;
  color: #00458B;
  font-family: gothampro bold;
}
.prefooter-form-checkbox-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.prefooter-checkbox-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 10%;
}
.prefooter-form-checkbox-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  max-width: 300px;
}
.prefooter-form-checkbox-text p {
  margin: 0;
  font-size: 14px;
}
.prefooter-form label {
  cursor: pointer;
}
.checkbox-custom {
  display: none;
}
.checkbox-custom + label {
  position: relative;
  padding-left: 30px;
  height: 1.5em;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  user-select: none;
}
.checkbox-custom + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  background-image: url('../src/img/form_checkbox.svg');
  background-size: contain;
}
.checkbox-custom:checked + label::before {
  border-color: #000;
  background-image: url('../src/img/form_checkbox_checked.svg');
  background-size: contain;
}
.checkbox-custom-hero-form {
  display: none;
}
.checkbox-custom-hero-form + label {
  position: relative;
  padding-left: 30px;
  height: 1.5em;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  user-select: none;
}
.checkbox-custom-hero-form + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  background-image: url('../src/img/form_checkbox.svg');
  background-size: contain;
}
.checkbox-custom-hero-form:checked + label::before {
  border-color: #000;
  background-image: url('../src/img/form_checkbox_checked.svg');
  background-size: contain;
}
.checkbox-custom-hero-form-policy {
  display: none;
}
.checkbox-custom-hero-form-policy + label {
  position: relative;
  padding-left: 30px;
  height: 1.5em;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  user-select: none;
}
.checkbox-custom-hero-form-policy + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  background-image: url('../src/img/form_checkbox.svg');
  background-size: contain;
}
.checkbox-custom-hero-form-policy:checked + label::before {
  border-color: #000;
  background-image: url('../src/img/form_checkbox_checked.svg');
  background-size: contain;
}
.prefooter-form-btn-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.prefooter-order-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}
.footer-copy-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  max-width: 50%;
}
.footer-policy-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  max-width: 50%;
  justify-content: center;
}
/* popup + order form */
.hero-order-form-popup-background {
  background-color: rgba(0, 0, 0, .8);
  min-height: 200vh;
  width: 100%;
  position: fixed;
  top: -50%;
  z-index: 5;
  animation: grow;
  animation-duration: 0.3s;
}
.hero-order-form-popup-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 6;
  border-radius: 10px;
  width: 425px;
  max-width: 450px;
  min-height: 400px;
  background-color: white;
  padding: 35px 25px;
  border: 1px solid #00458B;
  border-radius: 10px;
}
.hero-order-form-options-title,
.hero-order-form-options-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  color: #00458B;
  font-family: gothampro bold;
  font-size: 20px;
}
.hero-order-form-wrap h2 {
  margin: 0 0 20px 0;
  color: #00458B;
  font-family: gothampro bold;
}
.hero-order-form-options-title label {
  cursor: pointer;
}
.hero-order-main-fields-title-wrap,
.contact-form-order-main-label-wrap label {
  margin-bottom: 5px;
  color: #00458B;
  font-family: gothampro bold;
  font-size: 18px;
}
.hero-order-main-fields-title-wrap label {
  cursor: pointer;
}
.hero-order-form-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.hero-order-form input,
.hero-order-form select {
  min-height: 30px;
  margin-bottom: 5px;
  min-width: 250px;
  min-height: 30px;
  width: 100%;
  max-width: 270px;
  /* border-color: #00458B;
  outline-color: #00458B; */
}
.hero-order-form select {
  min-width: 280px;
  min-height: 37px;
}
.hero-order-form textarea {
  min-width: 360px;
  min-height: 75px;
  padding: 5px;
}
.hero-order-form-mode-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.form-mode-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.form-mode-checkbox span {
  max-width: 90%;
  width: 90%;
}
.hero-order-main-fields-checkbox-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
}
.hero-order-main-fields-send-btn-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.hero-order-main-fields-send-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
}
.hero-order-main-fields-send-btn:hover {
  background-color: #00458B;
  transition: background .4s ease;
}
.hero-order-form-popup-close-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}
.close-popup-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 0;
  background-image: url("../src/img/close_popup.svg");
}
.close-popup-btn:hover {
  background-image: url("../src/img/close_popup_hover.svg");
}
.gotop-btn {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #3FD2C7;
  color: white;
  cursor: pointer;
  padding: 15px 18px;
  border-radius: 4px;
  max-height: 50px;
  max-width: 50px;
}
.main-order-form-happy-state-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: gray;
  width: 450px;
  max-width: 450px;
  min-height: 100px;
  position: absolute;
  left: 30%;
  background-color: white;
  padding: 35px 25px;
  border: 1px solid #00458B;
  border-radius: 10px;
  animation-duration: 0.3s;
  animation-name: grow;
  top: 2%;
}
.main-order-form-happy-state-descr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}
@media (max-width: 800px)  {
  .hero-img-main-title-wrap {
      top: 18%;
      left: 0;
      padding: 10px;
  }
}
@media (min-width: 501px) and (max-width: 799px)  {
  .hero-img-main-title-wrap {
    top: 25%;
    left: 5%;
    padding: 10px;
}
}
@media (min-width: 800px) and (max-width: 1024px)  {
  .hero-img-main-title-wrap {
    top: 25%;
    left: 10%;
    padding: 10px;
}
}
@media (min-width: 900px) and (max-width: 1024px)  {
  .hero-img-main-title-wrap {
    top: 25%;
    left: 13%;
    padding: 10px;
}
}
@media (max-width: 991px)  {
  .hero-img-main-title-wrap h1 {
      font-size:24px;
      text-align: center;
  }
}
@media (max-width: 991px)  {
  .hero-img-main-title-description {
      text-align: center;
      font-size:14px;
  }
}
@media (max-width: 500px)  {
  .hero-img-main-title-controls-wrap {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 65%;
  }
}
@media (min-width: 501px) and (max-width: 900px)  {
  .hero-img-main-title-controls-wrap {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
  }
}
@media (max-width: 991px)  {
  header {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
  }
}
@media (max-width: 600px)  {
  .hero-img-wrap {
      max-width: 100%;
      width: 100%;
      min-height: 400px;
  }
}
@media (min-width: 601px) and (max-width: 991px)  {
  .hero-img-wrap {
      max-width: 100%;
      width: 100%;
      min-height: 500px;
  }
}
@media (min-width: 200px) and (max-width: 400px)  {
  main {
    flex-wrap: nowrap;
  }
}
@media (min-width: 200px) and (max-width: 400px)  {
  .hero-img-main-title-controls-wrap {
    width: 50%;
  }
}

@media (min-width: 1000px) and (max-width: 1024px)  {
  .hero-img-wrap {
      max-width: 100%;
      width: 100%;
      min-height: 600px;
  }
}
@media (max-width: 991px) {
  .hero-img-action-button-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      font-size: 18px;
  }
}
@media (min-width: 500px) and (max-width: 991px) {
  .hero-img-action-button-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
  }
}
@media  (min-width: 380px) and (max-width: 1199.98px)  {
  .hero-order-form select,
  .hero-order-main-fields-title-wrap,
  .hero-order-form input,
  .hero-order-form select,
  .hero-order-form textarea,
  .contact-form-order-main-input-wrap input,
  .prefooter-form-wrap form input,
  .prefooter-form-wrap form select,
  .prefooter-form-wrap form textarea,
  .main-footer-input-wrap input {
      max-width: 100%;
      width: 95%;
      min-width: 300px;
      left:auto;
      top: auto;
  }
}


@media (max-width: 600px)  {
  .hero-order-form-popup-wrap {
    max-width: 350px;
  }
}
@media (max-width: 600px)  {
  .hero-order-main-fields-input-wrap input {
    max-width: 250px;
    min-width: 250px;
  }
}

@media (max-width: 400px)  {
  .hero-order-form-popup-wrap {
    max-width: 320px;
  }
}
@media (max-width: 360px)  {
  .hero-order-form-popup-wrap {
    max-width: 320px;
    padding: 10px;
  }
}
@media (max-width: 991px)  {
  .video-mainpage-wrap video {
    width: 100%;
  }
}
@media (max-width: 991px)  {
  .video-mainpage-title-wrap {
      width: 100%;
  }
}

@media (max-width: 991px)  {
  .video-mainpage-title h2,
  .independence-main-title-wrap h2,
  .contact-form-order-main-wrap h3,
  .services-mainpage-title-wrap h2,
  .our-facts-title-wrap h3,
  .services-view-all-btn-text,
  .our-clients-title-wrap h3 {
      width: 100%;
      font-size: 32px;
      text-align: center;
      padding: 0px;
  }
}
@media (max-width: 991px)  {
  .video-mainpage-title h2,
  .independence-main-title-wrap h2,
  .contact-form-order-main-wrap h3,
  .services-mainpage-title-wrap h2,
  .our-facts-title-wrap h3,
  .services-view-all-btn-text,
  .our-clients-title-wrap h3,
  .main-color-title {
    margin-bottom: 10px;
    font-size: 32px;
    max-width: 400px;
    line-height: 1.2;
    text-align: center;
  }
}
@media (max-width: 991px)  {
  .video-mainpage-title-wrap p,
  .services-mainpage-description-wrap p,
  .main-footer-logo p {
      width: 100%;
      font-size: 16px;
      padding: 10px;
  }
}
@media (max-width: 991px)  {
  .video-mainpage-description {
      padding: 10px;
  }
}
@media (max-width: 991px)  {
  .independence-main-title-wrap {
      margin-bottom: 20px;
  }
}
@media (max-width: 991px)  {
  .our-facts-title-wrap {
      margin-bottom: 20px;
  }
}
@media (max-width: 900px)  {
  .contact-form-order-main-row,
  .contact-form-order-main-input-wrap,
  .contact-form-order-main-item,
  .contact-form-order-main-item:nth-child(2),
  .contact-form-order-main-item-end {
      flex-direction: column;
      justify-content: center;
      /* align-items: center; */
      margin: 0;
      margin-bottom: 10px;
      width: 100%;
  }
}

@media (min-width: 200px) and (max-width: 1024px)  {
  .independence-main-row {
      justify-content: center;
  }
}
@media (min-width: 600px) and (max-width: 991px)  {
  .independence-main-item,
  .independence-main-item:nth-child(2),
  .independence-main-item:nth-child(5) {
      min-width: 300px;
      max-width: 300px;
      margin: 10px;
  }
}
@media (max-width: 991px)  {
  .services-main-item {
    min-width: 250px;
    max-width: 250px;
    margin: 0 40px;
  }
}
@media (max-width: 991px)  {
  .services-view-all-btn-wrap-btn-wrap{
    flex-direction: column;
  }
}

@media (max-width: 991px)  {
  .contact-form-order-main-item-end{
      align-items: center;
  }
}
@media (max-width: 1100px) {
  .timeline {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: self-start;
    max-width: 300px;
  }
}
@media (max-width: 991px) {
  .timeline li {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: 150px;
      width: 150px;
      min-height: 130px;
      max-height: 150px;
      margin-bottom: 20px;
  }
}
@media (max-width: 991px)  {
  .timeline li::after {
      display: none;
  }
}
@media (max-width: 991px)  {
  .client-item {
      margin: 0 20px 20px 20px;
  }
}
@media (max-width: 991px)  {
  .prefooter-form-wrap {
      margin: 0;
      width: 100%;
  }
}
@media (max-width: 991px)  {
  .main-footer-top-row,
  .main-footer-bottom-row,
  .main-footer-contact-row,
  .main-footer-copy-wrap {
      width: 100%;
      flex-direction: column;
  }
}
@media (max-width: 991px)  {
  .main-footer-logo{
      width: 100%;
  }
}
@media (max-width: 991px) {
  .main-footer-logo p{
      padding: 0px;
  }
}
@media (max-width: 991px) {
  .footer-logo-description {
      margin-bottom: 0;
      width: 300px;
      max-width: 300px;
  }
}
@media (max-width: 991px)  {
  .main-footer-input,
  .main-footer-submenu-title-wrap {
      margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .main-footer-copy-wrap {
      flex-direction: row;
  }
}
@media (max-width: 991px) {
  .footer-copy-text,
  .footer-policy-text {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      max-width: 300px;
      flex-direction: row;
      text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .hero-img-main-title-controls-wrap {
      width: 100%;
      flex-direction:row;
      top: 15%;
      left: 15%;
      padding: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .hero-img-main-title-wrap {
    width: 100%;
    flex-direction:row;
    top: 20%;
    left: 15%;
    text-align: center;
    padding: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .services-mainpage-description-wrap,
  .video-mainpage-title-wrap {
      margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .hero-img-main-title-wrap h1,
  .video-mainpage-title h2, 
  .independence-main-title-wrap h2, 
  .contact-form-order-main-wrap h3, 
  .services-mainpage-title-wrap h2, 
  .our-facts-title-wrap h3, 
  .services-view-all-btn-text, 
  .our-clients-title-wrap h3 {
      font-size: 32px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .services-main-item {
      font-size: 28px;
      margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .timeline {
      flex-direction: row;
      width: 100%;
      max-width: 1024px;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      align-items: center;
      max-width: 1024px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-top-row, 
  .main-footer-bottom-row, 
  .main-footer-contact-row, 
  .main-footer-copy-wrap,
  .main-footer-logo {
      flex-direction: row;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-logo {
      width: 49%;
      max-width: 50%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-bottom-row {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-contact-row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 33.33%;
      justify-content: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-contact-list-wrap ul li {
      max-width: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .contact-form-order-main,
  .contact-form-order-main-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 20px;
      width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .contact-form-order-main-item,
  .contact-form-order-main-item:nth-child(2) {
      max-width: 33%;
      width: 25%;
      margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .contact-form-order-main-item:nth-child(2) {
      margin: 0 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .contact-form-order-main-item-end {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}


@media (min-width: 992px) and (max-width: 1199.98px) { 
  .contact-form-order-main-input-wrap {
      max-width: 200px;
      margin-right: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-color-title {
      justify-content: center;
      margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .main-footer-input-wrap input {
      margin-bottom: 20px;
  }
}
@media (min-width: 1025.98px) { 
  .mobile-menu {
      display: none;
  }
}
@media (max-width: 1199.98px) { 
  .contact-email-mobile-wrap {
      display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1319px) { 
  .callback-btn-wrap {
      display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1319px) { 
  .callback-wrap, .email-wrap {
      width: 43%;
      font-size: 14px;
  }
}
@media (min-width: 1025px) and (max-width: 1319px) { 
  .main-nav-list li {
      font-size: 16px;
  }
}
@media (min-width: 680px) and (max-width: 1319px) { 
  .independence-main-item,
  .independence-main-item:nth-child(2),
  .independence-main-item:nth-child(5){
      width: 50%;
      margin:10px;
  }
}
@media (min-width: 1025px) and (max-width: 1319px) { 
  .independence-main-item,
  .independence-main-item:nth-child(2),
  .independence-main-item:nth-child(5) {
      width: 50%;
      margin:10px;
  }
}
@media (min-width: 380px) and (max-width: 700px) { 
  .video-mainpage-wrap {
      align-items: normal;
      margin: 0;
      width: 100%;
      min-width: 371px;
  }
}
@media (min-width: 701px) and (max-width: 1023px) { 
  .video-mainpage-wrap {
      align-items: normal;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
      min-width: 95%;
      margin-bottom: 20px;
  }
}
@media (min-width: 1191px) and (max-width: 1024px) { 
  .video-mainpage-wrap {
      align-items: normal;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
      min-width: 850px;
      margin-bottom: 20px;
      padding: 0;
  }
}
@media (min-width: 701px) and (max-width: 1025px) { 
  .video-mainpage-textblock-wrap {
      width: 100%;
      max-width: 1200px;
      padding: 0 40px;
  }
}
@media (min-width: 201px) and (max-width: 1025px) { 
  .video-mainpage-prezent-wrap {
      justify-content: center;
      align-items: center;
  }
}

@media (min-width: 280px) and (max-width: 1024px) { 
  .contact-form-order-main-label-wrap,
  .contact-form-order-main-label-wrap label {
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 200px) and (max-width: 600px) { 
  .contacts-row{
    right: 5%;
  }
}
@media (min-width: 200px) and (max-width: 500px) { 
  .contact-form-order-main,
  .contact-form-order-main-label-wrap {
    width: 80%;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 200px) and (max-width: 500px) { 
  .contact-form-order-main-btn-sendp {
    width: 74%;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 501px) and (max-width: 800px) { 
  .contact-form-order-main,
  .contact-form-order-main-label-wrap {
    width: 60%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 501px) and (max-width: 800px) { 
  .contact-form-order-main-btn-sendp {
    width: 46%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
}

/* end */
@keyframes default_ani {
  0% {opacity: 0;}
  100% {
    opacity: 1;
  }
}

@keyframes fade_in {
  from {
    width: 0;

  }
  to {
    width: 100%;
    transition: height 2s;
  }
}
@keyframes shift {
  10% {
      transform: translate3d(0, 0, 0);
  }
  20% {
      transform: translate3d(-20px, 0, 0);
  }
  50% {
      transform: translate3d(10px, 0, 0);
  }
  75% {
      transform: translate3d(-7px, 0, 0);
  }
  100% {
      transform: translate3d(0, 0, 0);
  }
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeInUp {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    transform: translateY(-5vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes from-top {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: 200px;
    width: 200px;
  }
  100% {
    opacity: 0;
    height: 0;
    width: 0;
  }
}
.hide-form {
  animation: from-top;
  animation-duration: 1s;
}
.box {
  animation: drop-in 1000ms;
}
.btnDisabled {
  background-color: #A9A9A9!important;
  pointer-events: none;
}
.reverseService {
  flex-direction: row-reverse!important;
}
.policy-main-title-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.policy-point-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1230px;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}
.policy-point-wrap p {
  margin: 0;
  padding: 0px 40px;
}
.policy-point-wrap ul {
  margin: 0;
}
.policy-point-wrap ul li {
  list-style: none;
  margin-bottom: 20px;
  padding: 0 40px;
}
.policy-point-wrap h4 {
  font-family: 'gothampro';
  margin: 40px 0;
  font-size: 22px;
}
@media (min-width: 380px) and (max-width: 1024px) { 
  .policy-point-wrap {
    justify-content: flex-start;
    padding: 0 20px 0 0px;
  }
}
@media (min-width: 380px) and (max-width: 1024px) { 
  .policy-point-wrap ul li {
    justify-content: flex-start;
    padding: 0 20px 0 0px;
  }
}
@media (min-width: 380px) and (max-width: 1024px) { 
  .policy-point-wrap h4 {
    text-align: center;
  }
}
@media (min-width: 200px) and (max-width: 1024px){
  .hero-img-action-button-main {
    width: 200px;
    height: 42px;
  }
}
@media (min-width: 200px) and (max-width: 800px){
  .services-view-all-btn-text {
    font-size: 22px;
    margin-bottom: 40px;
  }
}
@media (min-width: 801px) and (max-width: 991px){
  .services-view-all-btn-text {
    font-size: 32px;
    margin-bottom: 40px;
  }
}
@media (min-width: 200px) and (max-width: 400px){
  .video-mainpage-wrap {
    margin: 0;
  }
}
@media (min-width: 200px) and (max-width: 1024px){
  .contact-form-order-main-input-wrap .main-form-message{
    max-width: 300px;
    min-width: 0;
  }
}
@media (min-width: 200px) and (max-width: 1024px){
  .contact-form-order-main-input-wrap input,
  .contact-form-order-main-input-wrap .main-form-message {
    max-width: 310px;
    min-width: 310px;
  }
}


@media (min-width: 200px) and (max-width: 1024px){
  .independence-main-item,
  .services-main-item {
    margin:20px!important;
    max-width: 300px;
  }
}
@media (min-width: 200px) and (max-width: 1024px){
  .services-mainpage-title-wrap {
    margin-bottom: 0px;
  }
}
@media (min-width: 200px) and (max-width: 991px){
  .hero-order-form,
  .service-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 95%;
    height: auto;
    padding: 0px;
  }
}
@media (min-width: 680px) and (max-width: 1023px)  {
  .contact-form-order-main-label-wrap,
  .contact-form-order-main-input-wrap,
  .contact-form-order-main-label-wrap label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
@media (min-width: 680px) and (max-width: 1100px)  {
  .timeline {
    justify-content: center;
    align-items: baseline;
    width: 100%;
    max-width: 1024px;
  }
}
@media (min-width: 680px) and (max-width: 1100px)  {
  .prefooter-form-wrap form{
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    left: 25%;
    justify-content: center;
    align-items: center;
    width: 300px;
    max-width: 600px;
  }
}
@media (min-width: 800px) and (max-width: 991px)  {
  .contact-form-order-main-item, 
  .contact-form-order-main-item-end,
  .contact-form-order-main-input-wrap,
  .contact-form-order-main-row {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1024px)  {
  .contact-form-order-main-item, 
  .contact-form-order-main-item-end,
  .contact-form-order-main-input-wrap {
    width: 30%;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1024px)  {
  .contact-form-order-main-item-end {
    width: 100%!important;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
@media (min-width: 200px) and (max-width: 800px)  {
  .main-order-form-happy-state-wrap {
    left: 0%;
    width: 88%;
  }
}
@media (min-width: 200px) and (max-width: 800px)  {
  .service-name-wrap h3 {
    font-size: 16px;
  }
}
@media (min-width: 900px) and (max-width: 1025px)  {
  .user-for-buissness-item-name h4 {
    font-size: 16px;
  }
}
@media (min-width: 200px) and (max-width: 800px)  {
  .services-main-row {
    justify-content: center;
  }
}
@media (min-width: 200px) and (max-width: 700px)  {
  .video-mainpage-row {
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
}
@media (min-width: 200px) and (max-width: 700px)  {
  .video-mainpage-row video {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 1100px)  {
  .video-mainpage-row {
    justify-content: center;
  }
}
@media (min-width: 200px) and (max-width: 800px)  {
  .video-mainpage-textblock-wrap {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    margin-top: 20px;
  }
}
@media (min-width: 200px) and (max-width: 700px)  {
  .video-mainpage-textblock-wrap h2 {
    text-align: center;
    font-size: 19px;
  }
}
@media (min-width: 700px) and (max-width: 1300px)  {
  .video-mainpage-textblock-wrap h2 {
    text-align: center;
    font-size: 28px;
  }
}

@keyframes serviceBtnShowAni {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
