.production-mainpage-hover-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: absolute;
    z-index:99;
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    background-color: #3FD2C7;
    padding: 20px;
    animation-duration: 0.3s;
    border-radius: 10px;
    animation-name: grow;
}
.production-close-hover-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0;
    background-image: url("../../img/close_popup.svg");
}
@media (min-width: 200px) and (max-width: 800px)  {
    .production-mainpage-hover-wrap {
      position: fixed;
    }
  }
  