.callback-request-popup {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: gray;
    width: 500px;
    max-width: 800px;
    min-height: 250px;
    position: fixed;
    left: 30%;
    background-color: white;
    padding: 20px;
    border: 1px solid #00458B;
    animation-duration: 0.3s;
    animation-name: grow;
    border-radius: 10px;
}
.callback-request-popup h3,
.callback-request-popup-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #00458B;
    font-size: 24px;
    margin: 0;
}
.callback-request-popup-close {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    min-height: 40px;
}
.callback-input-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 260px;
    align-items: center;
    color: #00458B;
    font-family: gothampro bold;
    font-size: 18px;
    margin-bottom: 5px;
}
.callback-input-title label {
    cursor: pointer;
}
.callback-request-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.callback-request-popup-from-wrap,
.callback-request-popup-from-wrap form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;

}
.callback-request-popup-from-wrap {
    margin-bottom: 20px;
}
.callback-request-popup-from-wrap form input {
    min-height: 30px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 250px;
}
.callback-request-inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    justify-content: center;
    align-items: center;
}
.callback-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
}
.callback-from-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.callback-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    max-width: 200px;
    height: 35px;
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.callback-send-btn:hover{
    background-color: #00458B;
    transition: background .4s ease;
}
.callback-input-name {
    width: 150px;
    max-width: 130px;
}
.form-mode-checkbox-callback-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
}
.callback-policy-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 250px;
}
.form-happy-state-wrap {
    max-width: 300px;
    padding: 10px;
}