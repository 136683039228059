.job-form-popup-background {
    display: flex;
    position: fixed;
    top: 25%;
    left: 47%;
    transform: translate(-25%, -25%);
    width: 50%;
    z-index: 5;
}
.job-main-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1300px;
    justify-content: space-between;
    align-items: baseline;
    z-index: 1;
    animation: vacancy-fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}
.job-item-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 45%;
    max-width: 600px;
    background-color: #f5f5f5;
    margin-bottom: 40px;
    min-height: 370px;
    max-height: 370px;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    z-index: 1;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
}
.job-item-title-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
}
.job-item-title-group .departament-title {
    color: #1E1E1E;
    padding-right: 5px;
    font-weight: normal;
}
.job-item-title-group h4 {
    margin: 0;
}
.job-item-title-group h3,
.job-item-title-group h4,
.job-item-title-group h5,
.job-item-description h5,
.job-сonditions-wrap h5 {
    margin: 0;
}
.job-item-description h5 {
    font-size: 20px;
    margin-bottom: 10px;
}
.job-item-title-group h3 {
    font-size: 22px;
}
.job-item-main-title-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.job-item-main-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 70%;
    max-width: 70%;
}
.job-item-salary-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 30%;
    max-width: 30%;
    align-items: end;

}
.job-item-description,
.job-item-description-full {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
    max-height: 200px;
    width: 100%;
}
.description-full-active {
    max-height: 100%;
    padding: 20px;
    flex-direction: column;
    transition: max-height 0.20s ease-in;
    overflow-x: hidden;
}
.job-item-description p {
    margin: 0;
}
.job-сonditions-wrap,
.job-duties-wrap,
.job-requirements-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
}
.job-сonditions-wrap ul,
.job-duties-wrap ul,
.job-requirements-wrap ul{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 40px;
}
.job-сonditions-wrap ul li,
.job-duties-wrap ul li,
.job-requirements-wrap ul li {
    display: flex;
    margin-bottom: 5px;
    align-items: baseline;
}
.job-list-style li::before {
    content: '';
    padding: 0 5px;
    width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-left: 12px solid #3FD2C7;
	border-bottom: 6px solid transparent;
}
.job-item-controls-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #f5f5f5;
    position:relative;
    padding-bottom:0px;
}
.job-item-showmore-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
    justify-content: center;
    text-align: center;
}
.job-item-send-request-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
    justify-content: center; 
}
.job-item-send-request-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    max-width: 150px;
    height: 30px;
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.job-item-send-request-btn:hover,
.job-form-send-btn:hover,
.job-form-file-input-wrap:hover,
.job-happy-state-close-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
.job-item-showmore-btn-wrap a {
    cursor: pointer;
}
.job-additional-contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.job-additional-contacts strong {
    padding-right: 10px;
}
.job-item-time-added {
    font-size: 14px;
    font-family: gothampro bold;
}
.job-form-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    min-height: 325px;
    background-color: #f5f5f5;
    position: absolute;
    z-index: 5;
    padding: 20px;
    border: 1px solid rgba(0, 69, 139, 0.5);
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation-name: vacancy-fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}
.job-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}
.job-from-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}
.job-from-title-wrap h3 {
    margin: 0;
    font-size: 20px;
}
.job-form-inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.job-form-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 5px;
    margin-right: 10px;
    max-width: 50%;
}
.job-form-input-wrap input {
    min-height: 25px;
    max-width: 170px;
}
.job-form-input-title,
.job-form-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 300px;
    width: 100%;
}
.job-form-input-title label {
    color: #00458B;
    cursor: pointer;
}
.job-form-input-file-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 60%;
    margin-bottom: 20px;
}
.job-form-send-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 7%;
}
.job-form-btnclose-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    min-height: 20px;
    margin-bottom: 20px;
}
.job-form-btnclose {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0;
    background-image: url("../../../src/img/close_popup.svg");
}
.job-form-btnclose:hover {
    background-image: url("../../../src/img/close_popup_hover.svg");
}
.job-from-file-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95.5%;
    margin-top: 20px;
}
.job-form-file-input-wrap {
    max-width: 300px;
    position: relative;
    display: inline-flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 17px;
    height: 30px;
    padding: 6px 8px;
    box-sizing: border-box;
    border: none;
    transition: background-color 0.2s;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 20px;
}
.job-form-file-input-name {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50%;
    margin-left: 10px;
    overflow: hidden;
}
.job-form-file-input-wrap .input-file-btn {
    cursor: pointer;
}
.job-form-file-input label {
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    cursor: pointer;
}
.job-form-file-input-wrap input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.job-form-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    max-width: 150px;
    height: 35px;
    font-size: 16px;
    color: #fff;
    line-height: 22px;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 2px 20px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.job-form-policy-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.job-form-policy-checkbox {
    display: none;
}
.job-form-policy-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
}
.job-form-policy-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../../src/img/form_checkbox.svg');
    background-size: contain;
}
.job-form-policy-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../../src/img/form_checkbox_checked.svg');
    background-size: contain;
}
.job-form-happy-state-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    z-index: 5;
    width: 100%;
    max-width: 400px;
    min-height: 250px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
}
.job-form-happy-state-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
}
.job-form-happy-state-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.job-form-happy-state-description p {
    margin: 0;
    text-align: center;
}
.job-happy-state-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    max-width: 150px;
    height: 30px;
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}
.job-page-vacancy-empty-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

@keyframes vacancy-fadeOut {
    0% {opacity:0;}
    100% {
        opacity:1;
    }
}
@keyframes vacancy-fadeIn {
    0% {
        opacity: 0;
        transform: translate3d(0px, 10%, 0px);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}
@media (max-width: 992px) {
    .job-page-vacancy-empty-wrap {
        max-width: 900px;
        width: 80%;
    }
}
@media (max-width: 992px) {
    .job-main-row {
        max-width: 900px;
    }
}
@media (max-width: 992px) {
    .job-item-wrap {
        max-width: 380px;
    }
}
@media (max-width: 992px) {
    .job-form-popup-wrap  {
        min-height: 375px;
    }
}

@media (max-width: 992px) {
    .job-item-controls-wrap  {
        padding: 0;
        bottom: -15px;
    }
}
@media (max-width: 992px) {
    .job-form-inputs-row {
        max-width: 100%;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-wrap {
        max-width: 40%;
        min-height: auto;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-wrap:nth-child(even) {
        margin-right: 20px;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-wrap:nth-child(odd) {
        margin-left: 20px;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-controls-wrap  {
        padding: 0;
        bottom: -10px;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-send-request-btn  {
        width: 60px;
    }
}
@media (min-width: 700px) and (max-width: 850px) {
    .job-item-salary-title,
    .job-item-main-title  {
        width: 100%;
        max-width: 100%;
        align-items: start;
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .job-main-row  {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 800px) {
    .job-item-wrap  {
        width:80%;
        max-width: 80%;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media (max-width: 800px) {
    .job-item-salary-title,
    .job-item-main-title  {
        width: 100%;
        max-width: 100%;
        align-items: start;
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .job-item-send-request-btn  {
        width: 60px;
    }
}

@media (min-width: 700px) and (max-width: 850px) {
    .job-form-input input {
        max-width: 150px;
    }
}
@media (max-width: 800px) {
    .job-form-popup-background {
        display: flex;
        position: fixed;
        left: 28%;
        transform: translate(-25%, -25%);
        width: 75%;
        z-index: 5;
    }
}
@media (max-width: 800px) {
    .job-form-policy-wrap {
        font-size: 13px;
    }
}
@media (max-width: 800px) {
    .job-form-policy-wrap {
        font-size: 13px;
    }
}
@media (max-width: 800px) {
    .job-form-popup-wrap {
        min-height: 400px;
    }
}
@media (max-width: 800px) {
    .job-form-send-btn-wrap {
        bottom: 5%;
    }
}
@media (max-width: 800px) {
    .job-form-input-wrap {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 800px) {
    .job-form-input-wrap input{
        max-width: 200px
    }
}
@media (max-width: 800px) {
    .job-form-happy-state-wrap {
        width:80%;
        max-width: 80%;
        flex-direction: column;
        flex-wrap: wrap;
    }
}



/* @media (max-width: 800px) {
    .job-form-input-wrap input {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .job-form-policy-wrap {
        font-size: 14px;
    }
}
@media (max-width: 800px) {
    .job-form-popup-wrap {
        min-height: 400px;
    }
} */




/* # Mobile
only screen and (min-width: 480px)

# Tablet
only screen and (min-width: 768px) 

# Desktop
only screen and (min-width: 992px)

# Huge
only screen and (min-width: 1280px)  */