.reviews-mainpage-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
}
.reviews-mainpage-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.reviews-mainpage-title h3 {
    font-size: 46px;
    line-height: 42px;
    margin: 0;
}
.yandex-rating-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.yandex-raiting-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    align-items: center;
}
.yandex-rating-num {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin-right: 0;
    max-width: 120px;
    font-size: 22px;
    font-weight: bold;
}
.yandex-rating-stars-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    width: 80%;
}
.review-star {
    max-width: 30px;
    margin-right: 10px;
}
.yandex-sym-red {
    color: #ff495c;
    font-weight: bold;
}
.yandex-rating-description-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    letter-spacing: 0.1em;
}
.send-review-block-wrap {
    display: flex;
    flex-direction: wrap;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.send-review-block-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #00458B;
}
.send-review-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.yandex-reviews-row {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 20px;
}
.yandex-reviews-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    max-width: 20%;
    border: 1px solid;
    border-radius: 5px;
    min-width: 400px;
    min-height: 200px;
    padding: 10px;
    background-color: whitesmoke;
    margin-right: 2%;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 20ms;
}
.yandex-reviews-userinfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.yandex-reviews-userinfo-img-wrap {
    margin-right: 10px;
    max-width: 20%;
}
.yandex-reviews-userinfo-img-wrap img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.yandex-reviews-userinfo-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 80%;
    width: 80%;
    align-items: center;
}
.yandex-reviews-userinfo-name {
    padding-right: 5px;
}
.yandex-reviews-userinfo-name,
.yandex-reviews-userinfo-second {
    font-size: 16px;
    font-weight: bold;
    color: black;
}
.yandex-reviews-review-stars {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 5px;
}
.yandex-reviews-review-stars .review-star img {
    max-height: 15px;
    max-width: 15px;
}
.yandex-reviews-review-description {
    font-size: 14px;
}
.review-show-more {
    padding: 0 10px;
    color: #00458B;
    text-decoration: underline;
    cursor: pointer;
}
.review-show-more:hover {
    color: #3FD2C7;
    text-decoration: none;
}
.yandex-reviews-control-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}
.yandex-reviews-arrow-left-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 10px;
    max-width: 50px;
    width: 50%;
    cursor: pointer;
}
.yandex-reviews-arrow-right-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50px;
    width: 50%;
    cursor: pointer;
}
.reviews-mainpage-popup-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    min-height: 400px;
    width: 400px;
    background-color: white;
    color: #1E1E1E;
    position: absolute;
    z-index: 5;
    border: 1px solid #00458B;
    padding: 10px;
    animation-duration: 0.3s;
    animation-name: grow;
    border-radius: 10px;
}
.reviews-mainpage-popup-wrap .service-popup-close-wrap {
    min-height: 20px;
}
.review-place-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.review-place-item-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px
}
.review-place-item-wrap img {
    max-width: 200px;
    cursor: pointer;
}

@media (min-width: 200px) and (max-width: 800px)  {
    .yandex-reviews-item {
      min-width: 350px;
      min-height: 50px;
      width: 100%;
      margin: 0 20px;
    }
}

@media (min-width: 200px) and (max-width: 800px)  {
    .reviews-mainpage-popup-wrap {
      width: 100%;
      max-width: 360px;
      margin: 0 20px;
    }
}



@keyframes moveToRight {
    0% {
        transform: translateX(-300px);
    }
    50% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0px);
    }
}