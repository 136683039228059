.sticky-sidemenu-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: fixed;
    right: 0;
    top: 55%;
    z-index: 5;
}
.sticky-sidemenu-item,
.sticky-sidemenu-item-1,
.sticky-sidemenu-item-2,
.sticky-sidemenu-item-3 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 5;
    max-height: 50px;
}
.sticky-sidemenu-item-1 {
    right: 0;
    top: 50%;
    z-index: 5;
}
.sticky-sidemenu-item-2 {
    right: 0;
    top: 55%;
    z-index: 5;
}
.sticky-sidemenu-item-3 {
    right: 0;
    top: 60%;
    z-index: 5;
}
.sticky-sidemenu-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    max-width: 220px;
    margin-right: -170px;
    background-color: whitesmoke;
    -moz-transition: margin 0.5s;
    -o-transition: margin 0.5s;
    -webkit-transition: margin 0.5s;
    transition: margin 0.5s;
}
.sticky-sidemenu-item-row.active {
    margin-right: 0;
    visibility: visible;
}
.sticky-sidemenu-item-row.inactive {
    margin-right: -170px;
    width: 220px;
    overflow: hidden;
    visibility: hidden;
}
.sticky-sidemenu-item-img {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50px;
    max-height: 50px;
}
.sticky-sidemenu-image {
    width: 50px;
    height: 50px;
}
.sticky-sidemenu-item-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 70%;
    font-size: 14px;
    max-width: 180px;
    max-height: 40px;
}
.sticky-sidemenu-tz-background {
    background-color: rgba(0, 0, 0, .8);
    min-height: 200vh;
    width: 100%;
    position: fixed;
    top: -50%;
    z-index: 5;
    animation: grow;
    animation-duration: 0.3s;
}
.sticky-sidemenu-tz-popup-wrap {
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 800px;
    border: 1px solid #00458B;
    background-color: white;
    z-index: 6;
    border-radius: 10px;
    min-height: 700px;
    padding: 20px;
}
.sticky-sidemenu-tz-popup-wrap h3 {
    max-width: 260px;
    position: relative;
    margin: 0 0 5px 0;
    position: relative;
}
.sticky-sidemenu-tz-form-customer-wrap.valid h3::before,
.sticky-sidemenu-tz-product-quantity-wrap.valid h3::before {
    position: absolute;
    right: 0;
    width: 20px;
    content: url('../../img/stickyMenu/option-ok.svg');
    filter: invert(36%) sepia(45%) saturate(1537%) hue-rotate(137deg) brightness(99%) contrast(95%);
}
.tz-inner-popup-input-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-inner-services-wrap h3::before {
    content: ''!important;
}
.sticky-sidemenu-tz-popup-close-btn-wrap,
.sticky-sidemenu-happy-state-popup-close-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    height: 40px;
    position: absolute;
    top: 5%;
}
.sticky-sidemenu-tz-popup-close-btn::before {
    content: url('../../img/close_popup.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    top:10%;
    right: 2%;
    cursor: pointer;
}
.sticky-sidemenu-tz-popup-close-btn:hover:before {
    content: url('../../img/close_popup_hover.svg');
}
.sticky-sidemenu-tz-popup-body,
.sticky-sidemenu-consult-popup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}
.sticky-sidemenu-tz-popup-body {
    max-height: 700px;
}
.sticky-sidemenu-tz-popup-body.consult {
    min-height: 200px;
    align-items: center;
    justify-content: center;
    margin-top: 13%;
}
.sticky-sidemenu-tz-title-wrap,
.sticky-sidemenu-consult-title-wrap  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-height: 40px;
}
.sticky-sidemenu-tz-title-wrap h2,
.sticky-sidemenu-consult-title-wrap h2 {
    color: #00458B;
    font-family: gothampro bold;
    margin: 0;
}
.sticky-sidemenu-tz-title-wrap {
    margin-bottom: 30px;
}
.sticky-sidemenu-tz-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 700px;
    align-items: center;
}
.sticky-sidemenu-tz-form-customer-row,
.sticky-sidemenu-tz-form-supplies-row,
.sticky-sidemenu-tz-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
    align-items: baseline;
}
.sticky-sidemenu-tz-form-customer-row h3,
.sticky-sidemenu-tz-form-supplies-row h3,
.sticky-sidemenu-tz-form-row {
    margin-bottom: 10px;
    justify-content: space-between;
}
.sticky-sidemenu-tz-form-customer-wrap,
.sticky-sidemenu-tz-form-product-type-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 45%;
    max-width: 300px;
}
.sticky-sidemenu-tz-form-customer-wrap,
.sticky-sidemenu-tz-form-customer-wrap {
    text-align: left;
}

.sticky-sidemenu-tz-form-customer-info-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 256px;
    justify-content: space-between;
}
.sticky-sidemenu-tz-form-customer-info-item-value {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    max-width: 240px;
}
.sticky-sidemenu-tz-form-customer-info-item-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 30%;
    justify-content: space-between;
}
.sticky-sidemenu-tz-edit-btn,
.sticky-sidemenu-tz-remove-btn {
    display: flex;
    width: 20px;
}
.sticky-sidemenu-tz-edit-btn::before {
    content: url('../../img/stickyMenu/tz-edit-btn.svg');
    cursor: pointer;
}
.sticky-sidemenu-tz-remove-btn::before {
    content: url('../../img/stickyMenu/tz-close-btn.svg');
    cursor: pointer;
}
.sticky-sidemenu-tz-add-btn::before {
    content: url('../../img/stickyMenu/tz-add-btn.svg');
    cursor: pointer;
}
.sticky-sidemenu-tz-add-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-options-popup-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 400px;
    min-height: 350px;
    border: 1px solid #00458B;
    background-color: white;
    z-index: 6;
    border-radius: 10px;
}
.sticky-sidemenu-tz-inner-services-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-top: 5%;
}
.sticky-sidemenu-tz-inner-services-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 300px;
    min-height: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    position: relative;
}
.sticky-sidemenu-tz-inner-services-item.selected {
    background-color: gray;
    transition: background .4s ease;
    color: white;
    border-radius: 5px;
}
.sticky-sidemenu-tz-inner-services-item.selected::before {
    position: absolute;
    left: 2%;
    top: 10%;
    content: url('../../img/stickyMenu/option-ok.svg');
    filter: invert(75%) sepia(84%) saturate(347%) hue-rotate(115deg) brightness(85%) contrast(92%);
}
.sticky-sidemenu-tz-inner-services-item:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
    border-radius: 5px;
}
.sticky-sidemenu-tz-inner-services-item.selected:hover {
    background-color: gray;
}
.sticky-sidemenu-tz-additional-options-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-additional-options-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-inner-popup {
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    min-height: 500px;
    border: 1px solid #00458B;
    background-color: white;
    z-index: 6;
    border-radius: 10px;
}
.sticky-sidemenu-tz-inner-popup.consult {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
    max-width: 560px;
}
.sticky-sidemenu-tz-inner-popup-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}
.tz-inner-popup-from-wrap,
.consult-inner-popup-from-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.tz-inner-popup-input-wrap,
.consult-inner-popup-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    margin-bottom: 5px;
}
.sticky-sidemenu-package-options-custom-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 84%;
    margin-bottom: 20px;
}
.tz-inner-popup-input-wrap input,
.consult-inner-popup-input-wrap input,
.sticky-sidemenu-package-options-custom-input-wrap input {
    min-height: 30px;
}
.tz-inner-popup-input-wrap textarea {
    min-height: 100px;
}
.sticky-sidemenu-package-options-custom-input-wrap input {
    max-width: 250px;
    min-width: 238px;
}
.tz-inner-popup-product-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
}
.tz-inner-popup-product-wrap select,
.tz-inner-popup-product-wrap label,
.tz-inner-popup-input-wrap label {
    margin-bottom: 5px;
}
.sticky-sidemenu-package-options-wrap  {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    max-width: 400px;
}
.sticky-sidemenu-package-options-wrap select {
    max-width: 250px;
    margin-bottom: 10px;
}
.tz-inner-popup-product-wrap select,
.sticky-sidemenu-package-options-wrap select {
    min-height: 30px;
}
.tz-inner-popup-from-controls-row,
.consult-inner-popup-from-controls-row  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tz-form-wrap-file-upload input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.tz-form-wrap-file-upload  label {
    font-size: 16px;
    cursor: pointer;
    color: #fff;
}
.tz-form-wrap-file-upload  {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 16px;
    height: 30px;
    padding: 6px 8px;
    box-sizing: border-box;
    border: none;
    transition: background-color 0.2s;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
    max-width: 180px;
    justify-content: center;
    margin: 10px 0;
}
.tz-form-wrap-file-upload-description {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}
.consult-inner-popup-from-controls-row {
    margin-bottom: 0;
    margin-top: 0;
}
.tz-inner-popup-from-controls-btn-wrap,
.consult-popup-from-controls-btn-wrap{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 200px;
    justify-content: center;
    align-items: center;
}
.tz-inner-popup-from-controls-btn,
.consult-popup-from-controls-btn,
.sticky-sidemenu-package-save-btn,
.sticky-sidemenu-tz-send-btn,
.sticky-sidemenu-tz-delivery-save-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    max-width: 150px;
    height: 35px;
    padding: 0 20px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 20px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.sticky-sidemenu-package-save-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 85%;
    justify-content: center;
}
.sticky-sidemenu-package-save-btn,
.sticky-sidemenu-tz-delivery-save-btn {
    height: 18px;
    font-size: 16px;
    padding: 5px;
    max-width: 120px;
}
.sticky-sidemenu-price-segment-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-price-segment-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 85%;
    max-width: 255px;
    justify-content: space-between;
    align-items: center;
}
.sticky-sidemenu-price-segment-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    min-height: 20px;
    padding: 2px 4px;
    border: 1px solid;
    margin-bottom: 10px;
    cursor: pointer;
}
.sticky-sidemenu-price-segment-item:hover {
    color: #00458B;
}
.sticky-sidemenu-price-segment-item.selected {
    background-color: #00458B;
    border: 1px solid #00458B;
    width: 95%;
    color: white;
}
.sticky-sidemenu-price-segment-item-name {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    user-select: none;
    width: 80%;
}
.sticky-sidemenu-price-segment-item-img-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 10%;
    cursor: pointer;
}
.sticky-sidemenu-price-segment-item-img-wrap img {
    max-width: 20px;
    filter: invert(18%) sepia(96%) saturate(2022%) hue-rotate(197deg) brightness(85%) contrast(101%);
}
.sticky-sidemenu-price-segment-item-img-wrap img:hover {
    transition: filter .4s ease;
    filter: invert(68%) sepia(99%) saturate(294%) hue-rotate(121deg) brightness(90%) contrast(90%);
}
.sticky-sidemenu-price-segment-item-description-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    height: 150px;
    position: absolute;
    padding: 20px;
    z-index: 5;
    left: 0%;
    top: -50%;
    color: white;
    background-color: #00458B;
}
.sticky-sidemenu-delivery-checkbox-wrap,
.sticky-sidemenu-self-delivery-checkbox-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-policy-checkbox-wrap  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-delivery-checkbox-wrap span,
.sticky-sidemenu-self-delivery-checkbox-wrap span {
    width: 80%;
}
.sticky-sidemenu-delivery-checkbox,
.sticky-sidemenu-self-delivery-checkbox,
.sticky-sidemenu-tz-policy-checkbox {
    display: none;
}
.sticky-sidemenu-delivery-checkbox + label,
.sticky-sidemenu-self-delivery-checkbox + label {
    position: relative;
    padding-left: 15px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
    width: 5%;
}
.sticky-sidemenu-delivery-checkbox + label:before,
.sticky-sidemenu-self-delivery-checkbox + label:before,
.sticky-sidemenu-tz-policy-checkbox + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.sticky-sidemenu-delivery-checkbox:checked + label::before,
.sticky-sidemenu-self-delivery-checkbox:checked + label::before,
.sticky-sidemenu-tz-policy-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.sticky-sidemenu-tz-delivery-form-input-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.sticky-sidemenu-tz-delivery-form-wrap label {
    font-family: gothampro bold;
    font-weight: bold;
    color: #1E1E1E;
}
.sticky-sidemenu-tz-delivery-form-wrap input {
    min-height: 30px;
    margin-right: 10px;
    max-width: 170px;
}
.sticky-sidemenu-tz-product-quantity-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-tz-quantity-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 300px;
}
.sticky-sidemenu-tz-quantity-title-wrap p {
    margin: 0 0 10px 0;
}
.sticky-sidemenu-tz-final-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.sticky-sidemenu-tz-quantity-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
}
.sticky-sidemenu-tz-quantity-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50%;
}
.sticky-sidemenu-tz-quantity-description p {
    margin: 0;
}
.sticky-sidemenu-tz-send-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.sticky-sidemenu-tz-quantity-input-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-right: 10px;
}
.sticky-sidemenu-tz-quantity-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.sticky-sidemenu-tz-quantity-input-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 20%;
}
.sticky-sidemenu-tz-quantity-input-unit {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 20px;
}

.sticky-sidemenu-tz-quantity-input-wrap input {
    max-width: 150px;
    min-height: 30px;
}
.sticky-sidemenu-tz-send-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.tz-inner-popup-from-controls-btn:hover,
.consult-popup-from-controls-btn:hover,
.tz-form-wrap-file-upload:hover,
.sticky-sidemenu-package-save-btn:hover,
.tz-inner-popup-from-controls-btn:hover,
.consult-popup-from-controls-btn:hover,
.sticky-sidemenu-package-save-btn:hover,
.sticky-sidemenu-tz-send-btn:hover,
.sticky-sidemenu-tz-delivery-save-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}
.sticky-sidemenu-consult-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    justify-content: center;
    margin-bottom: 20px;
}
.sticky-sidemenu-consult-form-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 45%;
    max-width: 300px;
}
.sticky-sidemenu-consult-form-label-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 85%;
    justify-content: flex-start;
    margin-bottom: 5px;
}
.sticky-sidemenu-consult-form-label-wrap label {
    color: #00458B;
    font-family: gothampro bold;
    cursor: pointer;
    font-size: 18px;
}
.sticky-sidemenu-consult-form-input-wrap input  {
    max-width: 180px;
    min-height: 30px;
}
.sticky-sidemenu-consult-messagers-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    top: 8%;
    right: 38.8%;
    width: 100%;
    max-width: 100px;
}
.sticky-sidemenu-consult-messagers-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 40px;
}
.sticky-sidemenu-consult-messagers-item img {
    cursor: pointer;
}
.sticky-sidemenu-consult-checkbox-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}
.sticky-sidemenu-consult-checkbox {
    display: none;
}
.sticky-sidemenu-consult-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
}
.sticky-sidemenu-consult-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.sticky-sidemenu-consult-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.sticky-sidemenu-tz-policy-checkbox-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}
.sticky-sidemenu-tz-policy-checkbox {
    display: none;
}
.sticky-sidemenu-tz-policy-checkbox + label {
    position: relative;
    padding-left: 15px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
    width: 5%;
}
.sticky-sidemenu-tz-policy-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.sticky-sidemenu-tz-policy-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.sticky-sidemenu-tz-policy-checkbox-wrap span {
    width: 80%;
}
.sticky-sidemenu-tz-quantity-min-value,
.sticky-sidemenu-tz-quantity-input-description {
    font-weight: bold;
}
.sticky-sidemenu-happy-state-wrap {
    display: flex;
    align-items: center;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 400px;
    border: 1px solid #00458B;
    background-color: white;
    z-index: 6;
    border-radius: 10px;
    min-height: 400px;
    padding: 20px;
}
.sticky-sidemenu-happy-state-title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
.sticky-sidemenu-happy-state-title-wrap h3 {
    color: #00458B;
}
.sticky-sidemenu-tz-happy-state-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.sticky-sidemenu-tz-happy-state-body p {
    padding: 10px;
    line-height: 1.8;
}
@media (max-width: 1600px) {
    .sticky-sidemenu-tz-popup-wrap {
        padding: 30px;
    }
}
@media (max-width: 1600px) {
    .sticky-sidemenu-tz-title-wrap {
        margin-bottom: 20px;
    }
}
@media (max-width: 1600px) {
    .sticky-sidemenu-tz-popup-close-btn-wrap {
        top: 2%;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-tz-popup-wrap {
        padding: 20px 40px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-tz-title-wrap h2 {
        font-size: 20px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-tz-form-row h3,
    .sticky-sidemenu-tz-product-quantity-wrap h3,
    .sticky-sidemenu-tz-quantity-description,
    .sticky-sidemenu-tz-additional-options-item,
    .sticky-sidemenu-price-segment-item-name {
        font-size: 16px;
    }
}
@media (max-width: 1250px) {
    .tz-inner-popup-product-wrap select, 
    .sticky-sidemenu-package-options-wrap select {
        min-height: 30px;
        margin-bottom: 15px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-tz-delivery-form-wrap input {
        min-height: 30px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-price-segment-item {
        padding: 0px 2px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-consult-checkbox-wrap {
        margin-bottom: 10px;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-delivery-checkbox + label:before, 
    .sticky-sidemenu-self-delivery-checkbox + label:before, 
    .sticky-sidemenu-tz-policy-checkbox + label::before {
        width: 1.3em;
        height: 1.3em;
    }
}
@media (max-width: 1250px) {
    .sticky-sidemenu-package-save-btn, 
    .sticky-sidemenu-tz-delivery-save-btn {
        height: 16px;
    }
}
@media (max-width: 1100px) {
    .sticky-sidemenu-tz-popup-wrap {
        width: 80%;
        min-height: 600px;
        padding: 20px;
    }
}
@media (max-width: 1100px) {
    .sticky-sidemenu-tz-popup-body {
        max-height: 800px;
    }
}
@media (max-width: 1100px) {
    .sticky-sidemenu-tz-form-wrap {
        position: relative;
        top: 10%;
    }
}
@media (max-width: 1090px) {
    .sticky-sidemenu-tz-popup-wrap {
        top: 50%;
    }
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1024px) and (orientation : portrait) {
    .sticky-sidemenu-item-1 {
        top: 30%;
    }
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1024px) and (orientation : portrait) {
    .sticky-sidemenu-item-2{
        top: 33.7%;
    }
}
@media (max-width: 1023px) {
    .sticky-sidemenu-tz-popup-wrap {
        top: 55%;
    }
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1024px) and (orientation : portrait) {
    .sticky-sidemenu-tz-popup-wrap {
        top: 33%;
    }
}
@media only screen and (min-device-width : 820px) and (max-device-width : 820px) and (orientation : portrait) {
    .sticky-sidemenu-tz-popup-wrap {
        top: 40%;
    }
}
@media (max-width: 820px) {
    .sticky-sidemenu-item-1{
        top: 51%;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-popup-wrap {
        position: absolute;
        width: 100%;
        top: 50%;
        padding: 10px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-popup-close-btn-wrap {
        top: 2%;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-background {
        z-index: 99;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-popup-body {
        min-height: 100vh;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-customer-wrap {
        width: 100%;
        flex-wrap: wrap;
    }
}

@media (max-width: 800px) {
    .sticky-sidemenu-tz-title-wrap {
        justify-content: flex-start;
        text-align: left;
        width: 89%;
        margin-top: 40px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-title-wrap.consult-title {
        justify-content: center;
        width: 100%;
        margin:0 0 20px 0;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-wrap {
        flex-direction: row;
        flex-wrap: wrap;
        width: 90%;
        overflow-y: scroll;
        padding: 0 15px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-customer-wrap {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-customer-info-item {
        max-width: 350px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-customer-wrap {
        margin-bottom: 10px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-quantity-wrap,
    .sticky-sidemenu-tz-quantity-description {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-form-row h3, 
    .sticky-sidemenu-tz-product-quantity-wrap h3, 
    .sticky-sidemenu-tz-quantity-description, 
    .sticky-sidemenu-tz-additional-options-item, 
    .sticky-sidemenu-price-segment-item-name {
        font-size: 18px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-delivery-form-wrap input {
        min-height: 30px;
    }
}
@media (max-width: 800px) {
    .tz-inner-popup-product-wrap select, 
    .sticky-sidemenu-package-options-wrap select {
        min-height: 35px;
        margin-bottom: 15px;
    }
}

@media (max-width: 800px) {
    .sticky-sidemenu-tz-quantity-description {
        order: -1;
        margin-bottom: 10px;
    }
}
@media (max-width: 800px) {
    .sticky-sidemenu-tz-inner-popup,
    .tz-inner-popup-input-title {
        width: 90%;
    }
}
@media (max-width: 800px) {
    .tz-inner-popup-product-wrap,
    .tz-inner-popup-input-title {
        align-items: center;
        justify-content: flex-start;
    }
}
@media (max-width: 800px) {
    .tz-inner-popup-input-wrap,
    .tz-inner-popup-input-title,
    .tz-inner-popup-product-wrap select,
    .tz-inner-popup-product-wrap label {
        width: 80%;
        border: 1px;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-happy-state-wrap {
        width: 80%;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-item-1{
        top: 50%;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-tz-inner-popup.consult {
        width: 100%;
        min-height: 400px;
        max-width: 95%;
        border: 1px;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-tz-popup-body.consult {
        width: 100%;
        min-height: 400px;
        border: 1px;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-row,
    .sticky-sidemenu-consult-form-input-wrap,
    .sticky-sidemenu-consult-form-label-wrap {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-label-wrap {
        margin-bottom: 5px;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-checkbox-wrap {
        width: 80%;
    }
}

@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-row {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-item {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-input-wrap {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-form-input-wrap input {
        max-width: 300px;
        width: 100%;
    }
}
@media (max-width: 600px) {
    .sticky-sidemenu-consult-messagers-row {
        right: 31.8%;
    }
}
@media (max-width: 400px) {
    .sticky-sidemenu-tz-popup-body{
        max-height: 600px;
    }
}

@media (max-width: 400px) {
    .sticky-sidemenu-item-1{
        top: 49%;
    }
}
@media (max-width: 400px) {
    .sticky-sidemenu-tz-final-row {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}
@media (max-width: 375px) {
    .sticky-sidemenu-item-1{
        top: 48%;
    }
}