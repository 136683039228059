.inner-page-header-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-bottom: 80px;
}
.inner-page-background-wrap {
    display: flex;
    max-width: 100%;
    width: 100%;
    min-width: 1024px;
    justify-content: center;
    max-width: 100%;
    vertical-align: middle;
    filter: brightness(0.7);
}
/* .inner-page-background-wrap img {
    width: 100%;
    max-height: 400px;
} */
.inner-page-background-wrap .filter-blur-img {
    filter: blur(3px);
}
.inner-page-background-about {
    background-image: url("../src/img/test2.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 1920px;
    height: 400px;
}
.inner-page-main-title-wrap {
    max-width: 1320px;
    width: 700px;
    position: absolute;
    left: 17%;
    top: 17%;
    line-height: 1.2;
    color: white;
    z-index: 1;
}
.inner-page-header-title-wrap h1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    font-size: 60px;
    font-weight: 700;
    text-align: left;
}
.inner-page-main-title-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 500px;
    width: 500px;
    background: white;
    color: black;
    padding: 10px;
}
.inner-page-level2-description-wrap p {
    margin: 0;
    padding: 0;
}
.inner-page-bradcrumbs-wrap {
    top: 12%;
    left: 17%;
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
}
.inner-page-main-wrapper h1,
.inner-page-main-wrapper h2,
.inner-page-main-wrapper h3,
.inner-page-main-wrapper h4,
.inner-page-main-wrapper h5,
.inner-page-main-wrapper h6 {
    font-family: gothampro bold;
    color: #00458B;
}
.inner-page-main-wrapper h3{
    font-size: 26px;
    margin: 0 0 20px 0;
}
.inner-page-title-level2-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.inner-page-level2-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.inner-page-level2-title-wrap h2 {
    margin: 0;
    padding: 0;
    color: black;
}
.inner-page-level2-description-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 500px;
}
.inner-page-bradcrumbs-wrap ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
.inner-page-bradcrumbs-wrap .current {
    opacity: 0.5;
    cursor: default;
}
.inner-page-bradcrumbs-wrap .current a,
.inner-page-bradcrumbs-wrap .current a:hover {
    cursor: default;
    color: white;
}
.inner-page-bradcrumbs-wrap ul li:nth-child(1)::after,
.inner-page-bradcrumbs-wrap ul li:nth-child(2)::after {
    content: '/';
    margin: 0 10px;
}
.inner-page-bradcrumbs-wrap ul li {
    list-style: none;
}
.inner-page-bradcrumbs-wrap a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.inner-page-bradcrumbs-wrap a:hover {
    color:  #00458B;
}
.inner-page-bradcrumbs-wrap .current {
    cursor: default;
}

.inner-page-main-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1230px;
    justify-content: center;
    align-items: center;
}
.inner-page-container-contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    max-width: 1230px;
}
.contact-page-level2-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.inner-page-main-wrapper section {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.contact-page-main-contacts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
}
.contacts-content-wrap {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    flex-wrap: wrap;
    width: 45%;
    min-height: 400px;
}
.contacts-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 600px;
    min-height: 400px;
}
.contacts-form-wrap img {
    max-width: 100%;
}
.contact-list-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    max-width: 500px;
}
.contact-list-social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.contact-list-social span {
    margin-right: 10px;
}
.contact-list-content a {
    color: #00458B;
    font-weight: bold;
    text-decoration: none;
}
.contact-list-content a:hover {
    color: #3FD2C7;
}

.contact-list-content ul {
    margin: 0;
    padding: 0;
}
.contact-list-content ul li {
    list-style: none;
    margin-bottom: 20px;
}
.map-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1230px;
    min-height: 400px;
    padding: 0 16px;
}
.contact-page-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.contact-page-form-wrap form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
}
.contact-page-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.contact-page-form-row label,
.contact-contact-page-form-file-input label,
.contact-page-form-full-row label {
    color: #00458B;
    font-family: gothampro bold;
    font-size: 20px;
    cursor: pointer;
}
.contact-page-form-row input,
.contact-page-form-row select {
    min-width: 250px;
    min-height: 30px;
    max-width: 100%;
}
.contact-page-form-row select  {
    min-height: 37px;
    cursor: pointer;
}
.contact-page-radioset-item input {
    min-width: 20px;
    width: 20px;
    cursor: pointer;
}
.contact-page-form-full-row textarea {
    display: flex;
    flex-direction: row;
    min-height: 150px;
    width: 98%;
    margin-bottom: 20px;
}
.contact-page-input-wrap-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 45%;
    max-width: 50%;
    margin-right: 10px;
    margin-bottom: 20px;
}
.contact-page-input-wrap-right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
    margin-bottom: 20px;
}
.contact-page-form-full-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.contact-page-form-btns-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.contact-page-form-checkbox-wrap {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    align-items: center;
}
.contact-page-form-checkbox-description {
    max-width: 100%;
    font-size: 14px;
}
.contact-page-radioset-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
}
.contact-page-radioset-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 33%;
    justify-content: center;
    align-items: center;
}
.contact-page-input-left-title,
.contact-page-input-right-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
    font-family: gothampro bold;
    font-size: 20px;
}
.contact-contact-page-form-file-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10px;
    font-family: gothampro bold;
    font-size: 20px;
    margin-bottom: 20px;
}
.contact-contact-page-form-file-input input[type='file'] {
    min-height: 30px;
}
.contact-contact-page-form-file-input label {
    margin-bottom: 10px;
}
.contact-page-form-btns-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.contact-page-form-btn-send {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.contact-page-form-btn-send:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
.contact-page-map-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.contact-page-map-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.contact-page-map-title h2 {
    font-size: 34px;
    margin: 0;
}
.contact-page-map-description-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 40px;
}
.contact-page-map-description-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40%;
    padding: 0 20px;
    justify-content: space-between;
}
.contact-page-map-description-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.contact-page-map-description-photo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 55%;
    max-width: 65%;
}
.contact-page-map-description-photo img {
    max-width: 100%;
    border-radius: 10px;
}
.contact-page-find-us-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.contact-page-find-us-image-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 48%;
    margin-right: 10px;
    margin-bottom: 20px;
}
.contact-page-find-us-image-wrap img {
    filter: saturate(0.5);
}
.contact-page-find-us-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    margin-bottom: 20px;
}
.contact-page-map-description-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}
.contact-page-map-description-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.contact-page-map-description-btn:hover {
    background-color: #00458B;
    color: white;
    transition: background .4s ease;
}
.req-list-name {
    font-weight: 700;
}
.about-company-facts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 80px;
}
.about-company-fact-item-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 25%;
    width: 25%;
    align-items: center;
}
.about-conpany-fact-icon-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.about-conpany-fact-icon-wrap img {
    margin: 0 auto; 
}
.about-conpany-fact-text {
    display: flex;
    flex-direction: row;
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 300px;
    justify-content: center;
    align-items: center;
}
.about-conpany-fact-descr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    font-size: 16px;
    max-width: 500px;
    padding: 0 16px;
    line-height: 1.5;
}
.about-company-video-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: sticky;
}
.about-company-video-wrap video {
    width: 100%;
}
.about-company-textcontent-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.about-company-textcontent-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}
.about-company-textcontent-image-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    max-height: 500px;
    z-index: 0;
    position: relative;
    overflow-x: hidden;
    margin-bottom: 20px;
}
.about-company-textcontent-image-wrap img {
    max-width: 100%;
    border-radius: 10px;
}
.about-company-textcontent-texta-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50%;
    width: 50%;
    max-height: 500px;
    text-align: left;
    padding: 0 16px;
}
.about-company-description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1.5;
    padding: 10px;
}
.about-company-description p {
    margin-top: 0px;
}
.about-title-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}
.about-title-wrap h1,
.about-title-wrap h2 {
    font-size: 34px;
    margin: 0 0 20px 0;
}
.inner-page-consult-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    min-height: 400px;
    color: white;
    margin-bottom: 40px;
}
.inner-page-form-background {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 0;
    max-width: 1920px;
    max-height: 400px;
    width: 1230px;
    height: 400px;
    background-image: url("../src/img/from_background.jpg");
    background-size: cover;
    background-repeat:no-repeat;
    background-position: center center;
    filter: brightness(0.4);
}
.inner-page-form form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.inner-page-form form label {
    font-family: gothampro bold;
    font-size: 20px;
    margin-bottom: 5px;
    cursor: pointer;
}
.inner-page-form input {
    min-width: 250px;
    min-height: 30px;
}
.inner-page-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.inner-page-form-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 20px;
}
.inner-page-form-wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    min-height: 200px;
}
.inner-page-form-text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 40px 0;
}
.inner-page-form-text h3 {
    margin: 0;
    font-size: 34px;
    z-index: 1;
    color: #3FD2C7;
}
.inner-page-form-description {
    display: flex;
    text-align: center;
    max-width: 50%;
}
.inner-page-form-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.inner-page-form-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center
}
.inner-page-from-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.inner-page-from-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
/* services */
.services-main-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}
.main-services-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.main-services-wrap h2 {
    font-size: 34px;
    margin: 0 0 20px 0;
    line-height: 1.3;
}
.main-service-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 80px;
    animation:  slide-in 800ms;
    animation-timing-function: linear;
    /* box-shadow: 0px 4px 32px 0px rgba(50,38,5,.06); */
}
.main-service-item-row p {
    max-width: 500px;
}
.main-service-img-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
}
.main-service-img-wrap {
    min-height: 400px;
    align-items: center;
    justify-content: center;
}
.main-service-img-wrap,
.main-service-description-box {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}
.main-service-img-wrap img {
    max-width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0px 4px 32px 0px rgba(50,38,5,.06);
}
.align-img-left {
    align-items: baseline;
    justify-content: flex-start;
}
.align-img-right {
    align-items: baseline;
    justify-content: flex-end;
}
.main-service-img-wrap img p {
    max-width: 100%;
}
.main-service-description-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 50%;
    min-height: 400px;
}
.main-service-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding-bottom: 30px;
    min-height: 400px;
}
.main-service-content-align-left {
    padding: 0 0 30px 30px;
}
.main-service-content-align-right {
    padding: 0 30px 30px 0;
}
.main-service-order-btn-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin-top: auto;
}
.main-service-order-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;
    max-width: 100%;
    align-items: center;
    margin-top: auto;
}
.main-service-order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.main-service-order-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
.main-service-about {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    position: relative;
    font-size: 18px;
    height: 40px;
}
.main-service-order-info-icon img {
    width: 40px;
    max-width: 40px;
    position: absolute;
    padding: 0 10px;
    top: 0;
    cursor: pointer;
    filter: invert(68%) sepia(99%) saturate(294%) hue-rotate(121deg) brightness(90%) contrast(90%);
}
.main-service-order-info-icon img:hover {
    transition: filter .4s ease;
    filter: invert(18%) sepia(96%) saturate(2022%) hue-rotate(197deg) brightness(85%) contrast(101%);
}
.service-item-hover-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    height: 93%;
    visibility: hidden;
    z-index: 5;
    border-radius: 10px;
    top: 0;
    left: 5%;
    color: #F5F5F5;
    position: absolute;
    background-color:#00458B;
}
.service-item-hover-wrap.visible {
    visibility: visible;
    transition: all 1s ease-in;
    animation: service-hover 0.2s forwards;
}
.service-item-hover-wrap::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15.5px 0 15.5px;
    border-color: #00458B transparent;
    position: absolute;
    top: 94%;
    left: 97%;
    transform: rotate(-90deg);
}
.service-item-hover-content-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: 10px;
}
.service-item-hover-wrap h4, .service-item-hover-wrap h3 {
    font-size: 20px;
    margin: 0;
    color: #F5F5F5;
}
.service-item-hover-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 40px;
    justify-content: center;
}
.service-item-hover-description-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.service-item-hover-min-price-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;
    font-size: 18px;
}
.service-item-hover-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
}
.service-item-hover-list li {
    margin-bottom: 5px;
}
.service-popup-wrap {
    max-width: 600px;
    min-height: 400px;
    width: 400px;
    background-color: white;
    color: #1E1E1E;
    position: absolute;
    z-index: 10;
    border: 1px solid #00458B;
    justify-content: center;
    align-items: center;
    padding: 10px;
    animation-duration: 0.3s;
    animation-name: grow;
    border-radius: 10px;
    /* top: 2%; */
}
.service-popup-close-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 2;
    min-height: 40px;
}
.service-popup-close-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 5%;
    top: 15px;
    background-image: url("../src/img/close_popup.svg");
}
.service-popup-close-btn:hover {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 5%;
    top: 15px;
    background-image: url("../src/img/close_popup_hover.svg");
}
.service-popup-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 400px;
    justify-content: center;
    align-items: center;
}
.service-popup-form-wrap label {
    cursor: pointer;
}
.service-popup-form-wrap form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}
.service-form-options-title,
.service-popup-form-title {
    margin-bottom: 5px;
}
.service-popup-form-title label,
.service-form-options-title {
    color: #00458B;
    font-size: 20px;
    font-family: gothampro bold;
}
.service-popup-form-wrap form input {
    min-width: 250px;
    min-height: 30px;
    width: 100%;
    max-width: 250px;
}
.service-popup-form-wrap form select {
    min-height: 37px;
    max-width: 259px;
    border-color: #00458B;
    outline-color: #00458B;
}
.service-popup-form-wrap form textarea {
    min-height: 100px;
    margin-bottom: 40px;
}
.service-form-input-wrap,
.service-form-options-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.service-form-options-wrap {
    margin-bottom: 10px;
}
.service-form-options-add-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 30px;
    position: relative;
}
.service-form-order-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}
.service-form-order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.service-form-order-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
.service-plus-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url("../src/img/plus.svg");
}
.contact-contact-page-form-file-input .input-file {
	position: relative;
	display: inline-flex;
    margin: 0 -3%;
}
.contact-contact-page-form-file-input .input-file-btn {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 16px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	border-radius: 4px;
	background-color: #3FD2C7;
	line-height: 22px;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
	transition: background-color 0.2s;
}
.contact-contact-page-form-file-input .input-file-text {
	padding: 0 10px;
	line-height: 40px;
	display: inline-block;
}
.contact-contact-page-form-file-input .input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: flex;
	width: 0;
	height: 0;
}
.contact-contact-page-form-file-input .input-file:hover .input-file-btn {
	background-color: #00458B;
}
.input-file:active .input-file-btn {
	background-color: #00458B;
}
.contact-contact-page-form-file-input .input-file input[type=file]:disabled + .input-file-btn {
	background-color: #eee;
}
.contact-page-form-file {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
}
.contact-page-form-file .file-loaded {
    color: #00458B;
    font-size: 14px;
    padding: 20px;
}
.contact-page-happy-state-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}
.vacancy-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.vacancy-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 97%;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    margin-bottom: 20px;
}
.vacancy-header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}
.vacancy-item-name,
.vacancy-item-requirements,
.vacancy-item-contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
}
.vacancy-item-contacts {
    justify-content: flex-start;
}
.vacancy-item-contacts ul {
    margin: 0;
    padding: 0;
}
.vacancy-item-contacts ul li {
    list-style: none;
    margin-bottom: 10px;
}
.vacancy-header-item-name,
.vacancy-header-item-requirements,
.vacancy-header-item-contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
    align-items: center;
}
.vacancy-header-item-name h3,
.vacancy-header-item-requirements h3,
.vacancy-header-item-contacts h3 {
    font-size: 20px;
    margin: 0;
}
.vacancy-item-name,
.vacancy-header-item-name {
    width: 25%;
    margin-right: 10px;
}
.vacancy-item-requirements,
.vacancy-header-item-requirements {
    width: 40%;
}
.vacancy-header-item-contacts {
    width: 20%;
    margin-right: 10px;
}
.vacancy-item-contacts {
    width: 25%;
    margin-right: 10px;
    border-left: 1px solid;
    height: 88%;
}
.vacancy-item-requirements {
    border-left: 1px solid;
    height: 88%;
}
.our-team-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}
.our-team-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1230px;
    justify-content: space-between;
}
.our-team-emploee-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 48%;
    max-width: 50%;
    min-width: 600px;
    max-width: 600px;
    min-height: 300px;
    background-color: #F5F5F5;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}
.emploee-info-title-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.emploee-img-wrap,
.emploee-info-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 50%;
}
.emploee-img-wrap {
    width: 50%;
    min-width: 300px;
}
.emploee-info-wrap {
    width: 45%;
}

.emploee-img-wrap img {
    border-radius: 10px 0px 0px 10px;
}

.emploee-info-wrap  {
    flex-direction: row;
    flex-wrap: wrap;
    /* max-height: 300px;
    overflow-y: scroll; */
    padding: 10px;
}
.our-team-emploee-item-row:nth-child(4n+1), .our-team-emploee-item-row:nth-child(4n+2) {
    flex-direction: row-reverse;
}
.our-team-emploee-item-row:nth-child(4n+1) .emploee-img-wrap img, 
.our-team-emploee-item-row:nth-child(4n+2) .emploee-img-wrap img {
    border-radius: 0 10px 10px 0px;
}
.emploee-info-wrap h3,
.emploee-info-wrap h4 {
    margin: 0;
}
.emploee-info-wrap h3 {
    width: 100%;
    font-size: 20px;
}
.emploee-info-wrap h4 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
}
.emploee-info-description {
    font-size: 14px;
    margin-top: auto;
}
.img-service-align-left {
    align-items: flex-start;
}
.img-service-align-right {
    align-items: flex-end;
}

.slide-left {
    animation:  slide-in-left 1000ms;
}
.slide-right {
    animation:  slide-in-right 1000ms;
}
.span-title-bold {
    font-weight: 700;
}

@media (max-width: 1200px) {
    .our-team-emploee-item-row {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 1200px;
    }
}
@media (max-width: 1325px) {
    .our-team-row {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-height: 400px;
        overflow-x: scroll;
    }
}
@media (max-width: 1300px) {
    .our-team-emploee-item-row {
        margin-right: 20px;
    }
}
@media (max-width: 1300px) {
    .our-team-emploee-item-row:nth-child(4n+1), 
    .our-team-emploee-item-row:nth-child(4n+2){
        flex-direction: row;
    }
}
@media (max-width: 1199px) {
    .our-team-emploee-item-row {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 1100px;
    }
}


@media (max-width: 800px) {
    .vacancy-header-row,
    .vacancy-item-row {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
    }
}
@media (max-width: 800px) {
    .vacancy-item-name,
    .vacancy-item-requirements,
    .vacancy-item-contacts {
        width: 100%;
        border-bottom: 1px solid;
        border-left: none;
        height: auto;
    }
}

@media (max-width: 800px) {
    .inner-page-main-title-wrap {
        left: 0;
    }
}
@media (max-width: 800px) {
    .inner-page-main-title-description p {
        width: 350px;
        max-width: 400px;
    }
}
@media (min-width: 200px) and (max-width: 600px) {
    .main-service-img-box,
    .main-service-description-box {
       width: 100%;
    }
}
@media (min-width: 200px) and (max-width: 480px) {
    .main-service-img-box {
       max-height: 320px;
    }
}
@media (min-width: 601px) and (max-width: 1025px) {
    .main-service-img-box{
       max-height: 340px;
    }
}
@media (min-width: 800px) and (max-width: 1023px) {
    .main-service-img-wrap {
       width:60%;
    }
}
@media (min-width: 700px) and (max-width: 1023px) {
    .main-service-content-align-left {
       padding: 0 0 15px 15px;
    }
}
@media (min-width: 700px) and (max-width: 1023px) {
    .main-service-content-align-right {
       padding: 0 15px 15px 0px;
    }
}
@media (min-width: 700px) and (max-width: 1023px) {
    .main-service-description-box,
    .main-service-content {
       width: 100%;
       justify-content: center;
       align-items: center;
    }
}
@media (min-width: 700px) and (max-width: 1023px) {
    .main-service-item-row {
       align-items: flex-start;
    }
}
@media (min-width: 200px) and  (max-width: 600px) {
    .main-service-content-align-left,
    .main-service-content-align-right {
       padding: 0 10px;
       margin-bottom: 20px;
       max-width: 370px;
    }
}
@media (max-width: 800px) {
    .main-service-order-btn-wrap {
        justify-content: center;
    }
}
@media  (min-width: 600px) and (max-width: 1100.98px) {
    .main-service-img-box img {
           max-width: 90%;
       }
   }
@media (max-width: 1400.98px) {
 .main-service-img-box img {
        max-width: 98%;
    }
}
@media (max-width: 900px) {
    .main-service-img-box img {
           max-width: 100%;
       }
   }
@media  (min-width: 1100px) and (max-width: 1300.98px) {
    .main-service-order-btn-row {
           max-width: 400px
       }
}
@media (max-width: 1100px) {
    .main-service-order-btn-row,
    .main-service-about {
        max-width: 95%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media (max-width: 600px) {
    .main-service-order-btn-row {
        margin-top: 20px;
    }
}
@media (max-width: 1100px) {
    .main-service-order-info-icon img {
        right: 15%;
    }
}
@media (max-width: 600px) {
    .main-service-order-info-icon img {
        right: 0%;
    }
}

@media  (min-width: 600px) and (max-width: 900.98px) {
    .main-service-img-box {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media  (min-width: 400px) and (max-width: 1300.98px) {
    .inner-page-main-wrapper section,
    .main-service-order-btn-wrap {
        justify-content: center;
    }
}
@media  (min-width: 701px) and (max-width: 1024.98px) {
    .main-service-img-wrap, 
    .main-service-description-box {
        justify-content: space-around;
        align-items: stretch;
        padding: 10px;
        max-width: 480px;
        width: 100%;
    }
}
@media (max-width: 700px) {
    .main-service-description-box,
    .main-service-content {
        padding: 10px;
        max-width: 563px;
        width: 95%;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 700px) {
    .main-service-content h2 {
        text-align: center;
    }
}
@media (min-width: 800px) and (max-width: 1100.98px) {
    .main-service-content-align-left,
    .main-service-content-align-right {
        padding: 0;
    }
}
@media  (min-width: 300px) and (max-width: 1100.98px) {
    .main-service-item-row,
    .main-service-order-btn-wrap {
        margin-bottom: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .about-company-fact-item-wrap {
        width: 100%;
        max-width: 100%;
        width: 100%;
        max-width: 50%;
        align-items: center;
        margin-bottom: 20px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .about-conpany-fact-text {
        max-width: 150px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .about-company-facts-row,
    .about-company-video-wrap,
    .about-company-textcontent-row {
        margin-bottom: 20px;
        align-items: baseline;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .about-title-wrap h1,
    .about-title-wrap h2 {
        font-size: 32px;
        text-align: center;
        max-width: 400px;
        margin-bottom: 20px;
    }
}
@media  (min-width: 200px) and (max-width: 800px) {
    .about-title-wrap {
        margin: 0;
    }
}

@media  (min-width: 300px) and (max-width: 800px) {
    .about-company-textcontent-texta-wrap{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-background{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        padding: 20px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-row {
        justify-content: center;
        align-items: center;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-description {
        width: 100%;
        max-width: 100%;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-text h3 {
        text-align: center;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-text {
        padding: 0;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-form-description {
        display: none;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .contact-page-main-contacts-row,
    .contacts-content-wrap,
    .contact-page-form-wrap form,
    .contact-page-input-wrap-left,
    .contact-page-form-wrap,
    .contact-page-form-checkbox-wrap {
        min-width: 100%;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .contact-page-input-wrap-right,
    .contact-page-input-wrap-left,
    .contact-page-form-row input, .contact-page-form-row select {
        width: 100%;
        max-width: 320px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .contacts-form-wrap {
       min-width: 90%;
    }
}

@media  (min-width: 300px) and (max-width: 800px) {
    .contact-page-input-wrap-right,
    .contact-page-input-wrap-left,
    .contact-page-input-wrap-right input, 
    .contact-page-input-wrap-left input {
        margin-right: 0;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .contact-page-form-btns-row,
    .contact-page-form-checkbox-description {
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .map-wrap{
        min-width: 100%;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .inner-page-container-contacts {
        padding: 0px;
    }
}
@media  (min-width: 300px) and (max-width: 800px) {
    .contacts-content-wrap {
        margin-right: 0;
        padding: 10px;
    }
}
@media  (min-width: 700px) and (max-width: 850px) {
    .contact-page-main-contacts-row {
        width: 100%;
    }
}

@media  (min-width: 801px) and (max-width: 1300px) {
    .contact-page-form-row input, 
    .contact-page-input-wrap-right input, 
    .contact-page-input-wrap-right select,
    .contact-page-form-row select,
    .contact-page-input-wrap-right select,
    .contact-page-input-wrap-right input,
    .contact-page-input-left input,
    .contact-page-input-left select,
    .contact-page-input-left textaera {
        min-width: 200px;
        width: 100%;
        max-width: 400px;
    }
}
@media  (min-width: 801px) and (max-width: 1300px) {
    .contact-page-input-wrap-right,
    .contact-page-input-wrap-left,
    .contact-page-input-left-title {
        width: 100%;
        max-width: 100%;
        max-width: 600px;
    }
}
@media  (min-width: 801px) and (max-width: 1300px) {
    .contact-page-radioset-item {
        max-width: 150px;
    }
}
@media  (min-width: 801px) and (max-width: 1300px) {
    .contact-page-form-checkbox-wrap{
        width: 100%;
        margin-bottom: 20px;
    }
}
@media  (min-width: 200px) and (max-width: 600px) {
    .inner-page-bradcrumbs-wrap {
        display: none;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .contact-page-find-us-description,
    .contact-page-find-us-image-wrap  {
        width: 100%;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .contact-page-find-us-row {
        justify-content: flex-start;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .contact-list-content,
    .contact-list-content ul,
    .contact-list-content a {
        padding: 0 10px;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .contact-list-content ul li {
        max-width: 90%;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .contact-page-form-wrap form {
        padding: 0;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .inner-page-header-title-wrap h1 {
        font-size: 42px;
        padding: 0 10px;
    }
}
@media  (min-width: 200px) and (max-width: 600px) {
    .inner-page-header-title-wrap h1 {
        max-width: 400px;
        font-size: 42px;
        padding: 0 10px;
    }
}
@media  (min-width: 200px) and (max-width: 700px) {
    .inner-page-main-title-wrap{
        /* top: 25%; */
        height: 85%;
        display: flex;
        left: 0;
        align-items: center;
        flex-wrap: wrap;
    }
}
@media  (min-width: 701px) and (max-width: 1024px) {
    .inner-page-main-title-wrap{
        /* top: 25%; */
        left: 2%;
    }
}

@media  (min-width: 200px) and (max-width: 1100px) {
    .main-service-img-wrap img{
        margin-bottom: 40px;
    }
}
@media  (min-width: 200px) and (max-width: 1100px) {
    .main-service-item-row {
        width: 100%;
       align-items: flex-start;
    }
}
@media  (min-width: 601px) and (max-width: 900px) {
    .main-service-item-row {
        margin-bottom: 80px;
    }
}
@media  (min-width: 200px) and (max-width: 1100px) {
    .main-service-img-wrap img {
        border-radius: 0;
    }
}
@media  (min-width: 200px) and (max-width: 900px) {
    .about-company-textcontent-image-wrap img {
        border-radius: 0;
    }
}
@media  (min-width: 800px) and (max-width: 1200px) {
    .about-company-textcontent-image-wrap {
        width: 45%;
    }
}
@media (min-width: 200px) and (max-width: 1100px) {
    .contact-page-map-description-photo,
    .contact-page-map-description-content {
        width: 100%;
        max-width: 100%;
    }
}
@media (min-width: 200px) and (max-width: 991px){
    .service-popup-wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 97%;
      height: auto;
      padding: 20px 0;
    }
}
@media (max-width: 1300px) {
    .service-item-hover-wrap {
      left: 0%!important;
      right: 0%!important;
    }
}
@media (max-width: 800px){
    .align-img-left,
    .align-img-right {
      justify-content: center;
      align-items: center;
    }
}
@media (max-width: 600px) {
    .inner-page-main-title-description {
        width: 100%;
        max-width: none;
    }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

@keyframes service-hover {
    0% {
      opacity: 0;
      transform: translateX(-10%);
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
        transform: translateX(-2.5%);
    }
    75% {
        opacity: 0.75;
        transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}