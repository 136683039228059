.decorative-cosmetics-types-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
}
.decorative-cosmetics-types-items-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 57%;
    margin-bottom: 20px
}
.decorative-cosmetics-types-image-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40.4%;
    max-height: 500px;
}
.decorative-cosmetics-types-photo-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 40px;
}
.decorative-cosmetics-types-photo-wrap img {
    max-height: 350px;
}
.decorative-cosmetics-type-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    max-width: 50%;
    border-radius: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px
}
.decorative-cosmetics-type-item-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    max-width: 70%;
}
.decorative-cosmetics-type-item-icon {
    display: flex;
    flex-direction: column;
    width: 60px;
    max-width: 50%;
}
.decorative-cosmetics-type-item-icon img {
    border-radius: 50%;
}
.decorative-cosmetics-types-image-wrap img {
    border-radius: 10px;
    max-width: 100%;
}
.decorative-cosmetics-how-to-work-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    min-height: 300px;
}
.decorative-cosmetics-how-to-work-item-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 300px;
    min-height: 150px;
    max-width: 25%;
    margin-bottom: 20px;
}
.decorative-cosmetics-how-to-work-item-wrap::before {
    content: url('../../img/right-arrow-bold.svg');
    position: absolute;
    right: 0;
    top: 25%;
    background-repeat: no-repeat;
    filter: invert(82%) sepia(78%) saturate(435%) hue-rotate(99deg) brightness(89%) contrast(82%);
}
.decorative-cosmetics-how-to-work-item-wrap:nth-child(n + 4)::before {
    content: url('../../img/left-arrow-bold.svg');
    position: absolute;
    right: 0;
    top: 25%;
    background-repeat: no-repeat;
    filter: invert(82%) sepia(78%) saturate(435%) hue-rotate(99deg) brightness(89%) contrast(82%);
}
.decorative-cosmetics-how-to-work-item-wrap:nth-child(4n)::before {
    content: '';
}
.decorative-cosmetics-how-to-work-item-icon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.decorative-cosmetics-how-to-work-item-icon img {
    width: 80px;
    max-width: 80px;
    filter: invert(13%) sepia(92%) saturate(3410%) hue-rotate(197deg) brightness(90%) contrast(104%);
}
.decorative-cosmetics-how-to-work-item-text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 200px;;
    text-align: center;
}
.decorative-cosmetics-information-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    justify-content: space-between;
}
.decorative-cosmetics-information-image-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 45%;
}
.decorative-cosmetics-information-image-wrap img {
    max-width: 100%;
    border-radius: 10px;
}
.decorative-cosmetics-information-text-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    position: relative;
}
.decorative-cosmetics-information-text-wrap p:nth-child(1) {
    margin-top: 0;
}
.decorative-cosmetics-btns-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}
.decorative-cosmetics-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 45%;
    max-width: 50%;
    justify-content: center;
    margin-top: auto;
}
.decorative-cosmetics-types-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.decorative-cosmetics-btn,
.decorative-cosmetics-types-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 280px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.decorative-cosmetics-btn:hover,
.decorative-cosmetics-types-btn:hover,
.decorative-cosmetic-order-form-btn:hover,
.input-file-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}
.decorative-cosmetics-consult-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    min-height: 336px;
    max-height: 368px;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: black;
}
.decorative-cosmetics-consult-form-wrap::before {
    content: url('../../img/questionsFormBg.jpg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
}
.decorative-cosmetics-consult-form-wrap::after{
    content: '';
    position: absolute;
    width: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, .6);
}
.decorative-cosmetics-consult-form-text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.decorative-cosmetics-consult-form-wrap h3,
.decorative-cosmetics-consult-form-text h3 {
    font-size: 28px;
    color: #3FD2C7;
}
.decorative-cosmetics-consult-form {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    width: 100%;
    max-width: 750px;
    height: 70%;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
}
.decorative-cosmetics-consult-form-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 33%;
    min-width:200px;
    max-width: 33%;
    margin-bottom: 15px;
}
.decorative-cosmetics-consult-form-input-wrap input {
    min-height: 30px;
}
.decorative-cosmetics-consult-form-input-wrap label {
    margin-bottom: 5px;
    cursor: pointer;
}
.decorative-cosmetics-consult-form-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.decorative-cosmetics-consult-form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 280px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.decorative-cosmetics-consult-form-btn:hover,
.decorative-cosmetic-consult-popup-form-send-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}
.decorative-cosmetic-consult-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    max-height: 450px;
    min-height: 450px;
    padding: 20px;
    position: fixed;
    top: 25%;
    left: 35%;
    background-color: #fff;
    z-index: 5;
    border-radius: 10px;
    border: 1px solid #00458B;
    animation-duration: 0.3s;
    animation-name: grow;
    border-radius: 10px;
}
.decorative-cosmetic-consult-popup-close-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    top: 4%;
}
.decorative-cosmetic-consult-popup-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.decorative-cosmetic-consult-popup-body h3 {
    margin-bottom: 20px;
}
.decorative-cosmetic-consult-popup-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
}
.decorative-cosmetic-consult-popup-form-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.decorative-cosmetic-consult-popup-form-wrap label {
    color: #00458B;
    font-family: gothampro bold;
    font-size: 18px;
    padding-left: 17%;
    margin-bottom: 5px;
    cursor: pointer;
}
.decorative-cosmetic-consult-popup-form-input input {
    min-height: 30px;
    max-width: 250px;
    min-width: 250px;
}
.decorative-cosmetic-consult-popup-close-btn::before {
    content: url('../../img/close_popup.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    top:10%;
    right: 2%;
    cursor: pointer;
}
.decorative-cosmetic-consult-popup-close-btn:hover::before {
    content: url('../../img/close_popup_hover.svg');
}
.decor-cosmetic-get-consult-checkbox-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}
.decor-cosmetic-get-consult-checkbox {
    display: none;
}
.decor-cosmetic-get-consult-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
}
.decor-cosmetic-get-consult-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.decor-cosmetic-get-consult-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.form-mode-decor-cosmetic-get-consult-checkbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.decorative-cosmetic-consult-popup-form-send-btn,
.decorative-cosmetic-order-form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    max-width: 200px;
    height: 35px;
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.decorative-cosmetic-order-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 550px;
    max-height: 600px;
    min-height: 600px;
    padding: 20px;
    position: fixed;
    top: 20%;
    left: 30%;
    background-color: #fff;
    z-index: 5;
    border-radius: 10px;
    border: 1px solid #00458B;
    animation-duration: 0.3s;
    animation-name: grow;
    border-radius: 10px;
}
.decorative-cosmetic-order-popup-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.decorative-cosmetic-order-popup-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.decorative-cosmetic-order-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 350px;
}
.decorative-cosmetic-order-form-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}
.decorative-cosmetic-order-form-input-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.decorative-cosmetic-order-form-input-title-wrap label {
    color: #00458B;
    font-family: gothampro bold;
    font-size: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}
.decorative-cosmetics-consult-form-input-wrap label {
    color: #3FD2C7;
    font-family: gothampro bold;
    font-size: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}
.decorative-cosmetic-order-form-input-wrap input {
    max-width: 250px;
    min-width: 250px;
    min-height: 30px;
}
.decorative-cosmetic-order-form-input-wrap textarea {
    min-height: 70px;
}
.decorative-cosmetic-order-form-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: auto;
    justify-content: center;
    align-items: center;
}
.decorative-cosmetic-order-form-file-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: gothampro bold;
    font-size: 20px;
}
.decorative-cosmetic-order-form-file-input input[type='file'] {
    min-height: 30px;
    display: none;
    margin: 20px 0;
}
.decorative-cosmetic-order-form-file-input label {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.decorative-cosmetic-order-form-file-input .input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s
}
.input-file-display-name {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.decor-cosmetic-quest-from-checkbox-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.decor-cosmetic-quest-from-checkbox-wrap a {
    color: white;
}
.decor-cosmetic-quest-from-checkbox-wrap a:hover {
    color: #3FD2C7;
}
.decor-cosmetic-quest-from-checkbox {
    display: none;
}
.decor-cosmetic-quest-from-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
    filter: invert(79%) sepia(54%) saturate(513%) hue-rotate(115deg) brightness(85%) contrast(91%);
}
.decor-cosmetic-quest-from-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.decor-cosmetic-quest-from-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.decorative-question-happy-state-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 95%;
    min-height: 65%;
    max-width: 400px;
    max-height: 254px;
    border: 1px solid #00458B;
    top: 10%;
    position: absolute;
    z-index: 6;
    background-color: white;
    padding: 20px;
}
.decorative-question-happy-state-popup-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}
.decorative-question-happy-state-popup-body h3 {
    color: #00458B;
}

@media (max-width: 1400px) { 
    .decorative-cosmetics-btn {
        max-width: 180px;
        min-width: 180px;
    }
}
@media (max-width: 1200px) { 
    .decorative-cosmetic-order-popup-wrap {
        top: 10%;
    }
}
@media (max-width: 1024px) { 
    .decorative-cosmetics-types-row,
    .decorative-cosmetics-information-row,
    .decorative-cosmetics-how-to-work-row,
    .decorative-cosmetics-consult-form-wrap {
        width: 95%;
    }
}
@media (max-width: 900px) { 
    .decorative-cosmetics-btn {
        max-width: 170px;
        min-width: 170px;
        font-size: 16px;
        padding: 0 20px;
    }
}
@media (max-width: 900px) { 
    .decorative-cosmetic-consult-popup-wrap {
        left: 25%;
    }
}
@media (max-width: 900px) { 
    .decorative-cosmetic-order-popup-wrap {
        top: 12%;
        left: 18%;
    }
}
@media (max-width: 850px) { 
    .decorative-cosmetics-btns-row {
        flex-direction: column;
    }
}
@media (max-width: 850px) { 
    .decorative-cosmetics-btn-wrap {
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-types-items-row {
        width: 100%;

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-types-image-wrap {
        width: 100%;

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-types-photo-wrap img {
        min-height: auto;

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-consult-form {
        max-width: 700px

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-consult-form-input-wrap input {
        max-width: 180px;

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetics-consult-form-input-wrap {
        max-width: 180px;

    }
}
@media (max-width: 800px) { 
    .decorative-cosmetic-consult-popup-wrap {
        left: 15%;
    }
}
@media (max-width: 800px) { 
    .decorative-cosmetic-order-popup-wrap {
        left: 10%;
    }
}
@media (max-width: 700px) { 
    .decorative-cosmetics-consult-form {
        max-width: 600px

    }
}
@media (max-width: 700px) { 
    .decorative-cosmetics-consult-form-input-wrap input {
        max-width: 150px;
    }
}
@media (max-width: 700px) { 
    .decorative-cosmetics-consult-form-text {
        text-align: center;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-information-text-wrap {
        width: 100%;
        order: 2;
    }
}

@media (max-width: 600px) { 
    .decorative-cosmetics-btns-row {
        flex-direction: row;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-btn-wrap {
        width: 50%;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-information-image-wrap {
        width: 100%;
        margin-bottom: 20px;
        order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap {
       max-width: 100%;
       order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap,
    .decorative-cosmetics-how-to-work-item-wrap {
       max-width: 50%;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(1) {
       order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(2) {
       order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(3) {
       order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(4) {
       order: 1;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(5) {
       order: 2;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(6) {
       order: 2;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-how-to-work-item-wrap:nth-child(n)::before {
        content: '';
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form-wrap {
        max-height: auto;
        height: auto;
        min-height: 600px;
        background-color:#171717;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form-wrap::before {
        content: '';
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form-text {
        flex-direction: column;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form-input-wrap {
        margin-bottom: 10px;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetics-consult-form-input-wrap input {
        min-width: 200px;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetic-consult-popup-wrap {
        left: 8%;
    }
}
@media (max-width: 600px) { 
    .decorative-cosmetic-order-popup-wrap {
        max-width: 450px;
        left: 8%;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetics-type-item-row{
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetics-btns-row {
        flex-direction: column;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetics-btn {
        max-width: 250px;
        width: 250px;
        min-width: 200px;
    }
}
@media (max-width: 500px) { 
    .for-clients-faq-accordion-item {
        width: 90%;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetic-consult-popup-wrap{
        max-width: 350px;
        left: 4%;
    }
}
@media (max-width: 500px) { 
    .decor-cosmetic-get-consult-checkbox-wrap {
        max-width: 75%;
        text-align: center;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetic-consult-popup-form-wrap label {
        padding-left: 13%;
    }
}
@media (max-width: 500px) { 
    .decorative-cosmetic-order-popup-wrap {
        max-width: 350px;
        left: 4%;
    }
}
@media (max-width: 400px) { 
    .decorative-cosmetic-order-popup-wrap,
    .decorative-cosmetic-consult-popup-wrap {
        left: 2%;
        max-width: 330px;
    }
}
@media (max-width: 400px) { 
    .decor-cosmetic-quest-from-checkbox-wrap {
        flex-wrap: nowrap;
        text-align: center;
        width: 80%;
    }
}

@media (max-width: 380px) { 
    .decorative-cosmetic-order-popup-wrap,
    .decorative-cosmetic-consult-popup-wrap {
        left: 0%;
        top: 10%;
        max-width: 330px;
    }
}
@media (max-width: 360px) { 
    .decorative-cosmetic-order-popup-wrap,
    .decorative-cosmetic-consult-popup-wrap {
        left: 2%;
        max-width: 300px;
    }
}
