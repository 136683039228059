.for-clients-main-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 97%;
  min-height: 500px;
}
.for-clients-main-wrap h1,
.for-clients-main-wrap h2,
.for-clients-main-wrap h3,
.for-clients-main-wrap h4,
.for-clients-main-wrap h5 {
  font-family: gothampro bold;
  color: #00458B;
}
.for-clients-main-suppliers-description-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #f5f5f5;
  font-size: 18px;
  margin-bottom: 40px;
  border-radius: 0 0 10px 10px;
}
.for-clients-main-suppliers-description-row::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 33px 33px 0 33px;
  border-color: #f5f5f5 transparent transparent transparent;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
}
.for-clients-main-suppliers-consult-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 48%;
  align-items: center;
  justify-content: center;
}
.for-clients-main-suppliers-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 44%;
  max-width: 800px;
  padding: 20px;
  line-height: 1.8em;
  position: relative;
}
.for-clients-main-suppliers-consult-btn,
.for-clients-not-found-tz-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}
.for-clients-main-suppliers-consult-btn:hover,
.for-clients-not-found-tz-btn:hover,
.for-clients-get-consult-btn:hover {
  background-color: #00458B;
  transition: background .4s ease;
  color: white;
}
.for-clients-main-wrap h2 {
  font-size: 34px;
  margin: 0 0 20px 0;
}
.for-clients-main-suppliers-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
.for-clients-suppliers-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 46%;
  padding: 20px;
  border-radius: 10px;
  max-width: 50%;
  background-color: whitesmoke;
  border-radius: 10px;
  margin-bottom: 40px;
}
.for-clients-supplier-main-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.for-clients-supplier-main-title h3 {
  font-size: 26px;
}
.clients-suppliers-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 97%;
  padding: 10px;
}
.clients-supplier-item-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}
.clients-supplier-item-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.clients-supplier-item-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.clients-supplier-item-head-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 33%;
}
.clients-supplier-item-head-city,
.clients-supplier-item-head-phone,
.clients-supplier-item-head-logo {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  font-weight: bold;
}
.clients-supplier-item-head-phone {
  justify-content: center;
}
.clients-supplier-item-head-logo {
  justify-content: flex-end;;
}
.clients-supplier-item-description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 65%;
  max-width: 100%;
}
.clients-supplier-item-description a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 50%;
}
.clients-supplier-item-logo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 35%;
  max-width: 100%;
  max-height: 40px;
  min-height: 40px;
  align-items: flex-end;
}
.clients-supplier-item-logo a {
  color:#666;
  background-image: linear-gradient(to right, #65d9d3, #65d9d3);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
  text-decoration: none;
  padding: 5px 15px;
  transition: 0.3s;
}
.clients-supplier-item-logo a:hover {
  color: white;
  background-size: 100% 100%;
 }
.clients-supplier-item-logo img {
  max-width: 100%;
  max-height: 40px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.clients-supplier-item-logo img:hover {
  transition: opacity 0.35s, transform 0.35s;
transform: scale(1.1);
}
.clients-supplier-city {
  font-weight: bold;
  margin-left: 10px;
}
.clients-supplier-phone {
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
}
.for-clients-howtowork-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.for-clients-howtowork-wrap ol {
  margin: 0;
  list-style: none;
  padding: 0;
  --hue: 1;
  --unit: 1rem;
}
.for-clients-howtowork-wrap h1 {
  text-align: center;
}
.for-clients-howtowork-wrap p {
  line-height: 1.3;
}
.for-clients-howtowork-wrap .event-date {
  margin: 0 0 1.25rem;
  font-weight: bold;
  color: #00458B;
}
.for-clients-howtowork-wrap .event-description {
  margin: 0;
}
.for-clients-howtowork-wrap li {
--height: 7rem;
position: relative;
display: block;
background-color: #f5f5f5;
border-color: #3FD2C7;
color: #1E1E1E;
padding: 1rem;
margin: 2rem 0;
}
.for-clients-howtowork-wrap li::before {
content: "";
background-color:#3FD2C7;
position: absolute;
display: block;
width: var(--unit);
height: var(--unit);
top: 100%;
left: calc(50% - (var(--unit)/2));
}
.for-clients-howtowork-wrap li::after {
content: "";
position: absolute;
display: block;
top: calc(100% + var(--unit));
left: calc(50% - (var(--unit)));
border: var(--unit) solid transparent;
border-top-color: #3FD2C7;
}
.for-clients-howtowork-wrap li:last-child::before,
.for-clients-howtowork-wrap li:last-child::after {
content: none;
}

.for-clients-howtowork-wrap li:nth-child(2n+2){
background-color:#f5f5f5;
color: #1E1E1E;
}
.for-clients-howtowork-wrap li:nth-child(20n+1){
--hue: 1;
}
.for-clients-howtowork-wrap li:nth-child(20n+2){
--hue: 2;
}
.for-clients-howtowork-wrap li:nth-child(20n+3){
--hue: 3;
}
.for-clients-howtowork-wrap li:nth-child(20n+4){
--hue: 4;
}
.for-clients-howtowork-wrap li:nth-child(20n+5){
--hue: 5;
}
.for-clients-howtowork-wrap li:nth-child(20n+6){
--hue: 6;
}
.for-clients-howtowork-wrap li:nth-child(20n+7){
--hue: 7;
}
.for-clients-howtowork-wrap li:nth-child(20n+8){
--hue: 8;
}
.for-clients-howtowork-wrap  li:nth-child(20n+9){
--hue: 9;
}
.for-clients-howtowork-wrap li:nth-child(20n+10){
  --hue: 10;
}
.for-clients-howtowork-wrap li:nth-child(20n+11){
--hue: 11;
}
.for-clients-howtowork-wrap li:nth-child(20n+12){
--hue: 12;
}
.for-clients-howtowork-wrap li:nth-child(20n+13){
  --hue: 13;
}
.for-clients-howtowork-wrap li:nth-child(20n+14){
--hue: 14;
}
.for-clients-howtowork-wrap li:nth-child(20n+15){
--hue: 15;
}
.for-clients-howtowork-wrap li:nth-child(20n+16){
--hue: 16;
}
.for-clients-howtowork-wrap li:nth-child(20n+17){
--hue: 17;
}
.for-clients-howtowork-wrap li:nth-child(20n+18){
--hue: 18;
}
.for-clients-howtowork-wrap li:nth-child(20n+19){
--hue: 19;
}
.for-clients-howtowork-wrap li:nth-child(20n+20){
  --hue: 20;
}

@media (min-width: 550px) and (max-width: 899px){
  .for-clients-howtowork-wrap li {
    margin: 1rem;
    width: calc(50% - 4rem);
    float: left;
    min-height: var(--height);
  }
  .for-clients-howtowork-wrap li:nth-child(4n+3),
  .for-clients-howtowork-wrap li:nth-child(4n+4) {
    float: right;
  }
  .for-clients-howtowork-wrap li:nth-child(4n+1)::before {
    top: calc(var(--height)/2 + var(--unit)/2);
    left: 100%;
  }
  .for-clients-howtowork-wrap li:nth-child(4n+1)::after {
    top: calc(var(--height)/2);
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: inherit;
  }
  .for-clients-howtowork-wrap li:nth-child(4n+3)::before {
    top: calc(var(--height)/2 + var(--unit)/2);
    left: -1rem;
  }
  .for-clients-howtowork-wrap li:nth-child(4n+3)::after {
    top: calc(var(--height)/2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: inherit;
  }
}
@media (min-width: 900px) and (max-width: 1199px){
  .for-clients-howtowork-wrap li {
    margin: 1rem;
    width: calc(33.33% - 4rem);
    float: left;
    min-height: 7rem;
  }
  .for-clients-howtowork-wrap li:nth-child(6n+4),
  .for-clients-howtowork-wrap li:nth-child(6n+5),
  .for-clients-howtowork-wrap li:nth-child(6n+6) {
    float: right;
  }
  .for-clients-howtowork-wrap li:nth-child(6n+1)::before,
  .for-clients-howtowork-wrap li:nth-child(6n+2)::before {
    top: calc(var(--height)/2 + var(--unit)/2);
    left: 100%;
  }
  .for-clients-howtowork-wrap li:nth-child(6n+1)::after,
  .for-clients-howtowork-wrap li:nth-child(6n+2)::after {
    top: 3.5rem;
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: inherit;
  }
  .for-clients-howtowork-wrap li:nth-child(6n+4)::before,
  .for-clients-howtowork-wrap li:nth-child(6n+5)::before{
    top: calc(var(--height)/2 + var(--unit)/2);
    left: -1rem;
  }
  .for-clients-howtowork-wrap li:nth-child(6n+4)::after,
  .for-clients-howtowork-wrap li:nth-child(6n+5)::after{
    top: calc(var(--height)/2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: inherit;
  }
}
@media (min-width: 1200px){
  .for-clients-howtowork-wrap ol {
    max-width: 1600px;
    margin: 0 auto;
  }
  .for-clients-howtowork-wrap li {
    margin: 1rem;
    width: calc(25% - 4rem);
    float: left;
    min-height: 7rem;
  }
  .for-clients-howtowork-wrap li:nth-child(8n+5),
  .for-clients-howtowork-wrap li:nth-child(8n+6),
  .for-clients-howtowork-wrap li:nth-child(8n+7),
  li:nth-child(8n+8){
    float: right;
  }
  .for-clients-howtowork-wrap li:nth-child(8n+1)::before,
  .for-clients-howtowork-wrap li:nth-child(8n+2)::before,
  .for-clients-howtowork-wrap li:nth-child(8n+3)::before{
    top: calc(var(--height)/2 + var(--unit)/2);
    left: 100%;
  }
  .for-clients-howtowork-wrap li:nth-child(8n+1)::after,
  .for-clients-howtowork-wrap li:nth-child(8n+2)::after,
  .for-clients-howtowork-wrap li:nth-child(8n+3)::after{
    top: calc(var(--height)/2);
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: #3FD2C7;
  }
  .for-clients-howtowork-wrap li:nth-child(8n+5)::before,
  .for-clients-howtowork-wrap li:nth-child(8n+6)::before,
  .for-clients-howtowork-wrap li:nth-child(8n+7)::before {
    top: calc(var(--height)/2 + var(--unit)/2);
    left: -1rem;
  }
  .for-clients-howtowork-wrap li:nth-child(8n+5)::after,
  .for-clients-howtowork-wrap li:nth-child(8n+6)::after,
  .for-clients-howtowork-wrap li:nth-child(8n+7)::after {
    top: calc(var(--height)/2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: #3FD2C7;
  }
}
.for-clients-howtowork-send-consult-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.forclients-faq-main-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.for-clients-faq-accordion-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
  justify-content: space-between;
  border: 1px solid rgb(0%, 27%, 55%, 0.8);
  margin-bottom: 10px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
}
.for-clients-faq-accordion-item.active {
  border-color: rgb(25%, 82%, 78%, 0.8);
}
.for-clients-faq-accordion-item.active h4 {
  color: #00458B;
}
.for-clients-faq-accordion-item-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  padding: 10px;
}
.for-clients-faq-accordion-item-title h4 {
  font-size: 18px;
  color: #1E1E1E;
  margin: 0;
}
.for-clients-faq-accordion-item-btn-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 5%;
  max-width: 10%;
  position: relative;
}
.for-clients-faq-accordion-item-btn::before {
    position: absolute;
    top: 10%;
    right: 50%;
    content: '\2795';
    cursor: pointer;
    font-size: 25px;
    color: #00458B;
}
.for-clients-faq-accordion-item-btn.active::before {
  content: '\2796';
  color: #3FD2C7;
}
.for-clients-faq-accordion-item-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 0;
  max-height: 0;
  visibility: hidden;
}
.for-clients-faq-accordion-item-description.active {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: static;
  height: auto;
  z-index: 5;
  visibility: visible;
  animation: accordionAnimate;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.for-clients-not-found-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  max-width: 1024px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.for-clients-not-found-row h2 {
  text-align: center;
  margin: 0;
}
.for-clients-not-found-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.for-clients-not-found-tz-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.for-clients-not-found-tz-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.for-clients-not-found-btn-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  max-width: 50%;
}
.for-clients-not-found-description a,
.clients-supplier-item-description a {
  text-decoration: none;
}
.for-clients-not-found-description a:hover,
.clients-supplier-item-description a:hover {
  text-decoration: underline;
}
.for-clients-not-found-tz-wrap a:hover {
  text-decoration: none;
}
.for-clients-suppliers-logo-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
}
.for-clients-suppliers-logo-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 97%;
  padding: 20px;
  justify-content:space-between;
}
.for-clients-suppliers-logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.66%;
  border: 1px solid rgba(30, 30, 30, 0.1);
  max-width: 16.66%;
  height: 100px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}
.for-clients-consult-form-background,
.for-clients-detail-form-background {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 5;
}
.for-clients-consult-from-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 460px;
  min-height: 300px;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: white;
  top: 25%;
  border-radius: 10px;
  border: 1px solid #00458B;
  animation-duration: 0.3s;
  animation-name: grow;
}
.for-clients-inputs-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.for-clients-consult-input-item-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin-right: 20px;
  max-width: 50%;
}
.for-clients-consult-input-item-wrap:nth-child(2) {
  margin: 0;
}
.for-clients-consult-input-label-wrap,
.for-clients-consult-input-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.for-clients-consult-input-label-wrap label,
.for-detail-detail-title-wrap label {
  width: 100%;
  max-width: 240px;
  align-items: center;
  color: #00458B;
  font-family: gothampro bold;
  font-size: 18px;
  margin-bottom: 5px;
  cursor: pointer;
}
.for-clients-consult-input-wrap input {
  max-width: 260px;
  min-height: 25px;
}
.for-clients-consult-main-title-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
}
.for-clients-consult-main-title-form h3,
.for-clients-detail-main-title-form h3 {
  font-size: 22px;
}
.for-clients-get-consult-btn-wrap,
.for-clients-detail-btn-wrap  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 13%;
}
.for-clients-get-consult-btn,
.for-clients-get-detail-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 35px;
  padding: 0 40px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  background: #3FD2C7;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}
.for-clients-consult-popup-close-btn,
.for-clients-detail-popup-close-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 2%;
  top: 7%;
  background-image: url("../../img/close_popup.svg");
  z-index: 5;
}
.for-clients-consult-popup-close-btn:hover,
.for-clients-detail-popup-close-btn {
  background-image: url("../../img/close_popup_hover.svg");
}
.for-clients-get-consult-checkbox-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.for-clients-get-consult-checkbox {
  display: none;
}
.for-clients-get-consult-checkbox + label {
  position: relative;
  padding-left: 30px;
  height: 1.5em;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  user-select: none;
}
.for-clients-get-consult-checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  background-image: url('../../img/form_checkbox.svg');
  background-size: contain;
}
.for-clients-get-consult-checkbox:checked + label::before {
  border-color: #000;
  background-image: url('../../img/form_checkbox_checked.svg');
  background-size: contain;
}
.form-mode-for-clients-get-consult-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.for-clients-detail-from-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  min-height: 450px;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: white;
  top: 25%;
  border-radius: 10px;
  border: 1px solid #00458B;
  animation-duration: 0.3s;
  animation-name: grow;
}
.for-clients-detail-main-title-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
}
.for-clients-detail-inputs-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
.for-clients-detail-input-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 250px;
  width: 85%;
  justify-content: center;
  margin-bottom: 10px;
}
.for-clients-detail-input-wrap input {
  min-width: 200px;
  max-width: 200px;
  min-height: 25px;
}
.for-clients-detail-input-wrap textarea {
  min-width: 250px;
  max-width: 440px;
  min-height: 100px;
}
.for-clients-detail-btn-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
}
.for-clients-get-detail-checkbox-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.form-mode-for-clients-get-detail-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.form-mode-for-clients-get-detail-checkbox span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.for-clients-get-detail-checkbox {
  display: none;
}
.for-clients-get-detail-checkbox + label {
  position: relative;
  padding-left: 30px;
  height: 1.5em;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  user-select: none;
}
.for-clients-get-detail-checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  background-image: url('../../img/form_checkbox.svg');
  background-size: contain;
}
.for-clients-get-detail-checkbox:checked + label::before {
  border-color: #000;
  background-image: url('../../img/form_checkbox_checked.svg');
  background-size: contain;
}
.for-clients-happystate--message-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1310px) {
  .for-clients-suppliers-item {
    padding: 15px;
  }
}
@media (max-width: 1310px) {
  .clients-supplier-item-logo a{
    padding: 0;
  }
}
@media (max-width: 1000px) {
  .for-clients-main-suppliers-row{
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}
@media (max-width: 1000px) {
  .for-clients-suppliers-item {
    max-width: 600px;
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .for-clients-suppliers-item:last-child {
    margin: 0;
  }
}
@media (max-width: 1000px) {
  .for-clients-suppliers-logo-item {
    width: 20%;
    max-width: 20%;
  }
}
@media (max-width: 900px) {
  .for-clients-faq-accordion-item {
    width: 92%;
  }
}
@media (max-width: 800px) {
  .for-clients-suppliers-logo-row {
    width: 97%;
    align-items: center;
    justify-content: space-around;
    padding: 20px 6px;
  }
}
@media (max-width: 800px) {
  .for-clients-suppliers-logo-item {
    width: 50%;
    max-width: 45%;
  }
}
@media (max-width: 800px) {
  .for-clients-main-suppliers-description,
  .for-clients-main-suppliers-consult-wrap {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 800px) {
  .for-clients-main-suppliers-description-row{
    padding: 20px 0;
  }
}
@media (max-width: 800px) {
  .for-clients-consult-from-row {
    width: 80%;
    top: 70%;
    min-height: 400px;
  }
}
@media (max-width: 800px) {
  .for-clients-inputs-row,
  .for-clients-consult-input-item-wrap {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;

  }
}
@media (max-width: 800px) {
  .for-clients-inputs-row, 
  .for-clients-consult-input-item-wrap  {
    margin-right: 0;
  }
}
@media (max-width: 800px) {
  .for-clients-consult-input-label-wrap, 
  .for-clients-consult-input-wrap {
    width: 70%;
  }
}
@media (max-width: 800px) {
  .for-clients-consult-input-wrap {
    margin-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .form-mode-for-clients-get-consult-checkbox {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .form-mode-for-clients-get-consult-checkbox span {
    width: 60%;
    justify-content: flex-start;
  }
}
@media (max-width: 800px) {
  .for-clients-suppliers-item {
    max-width: 370px;
  }
}

@media (max-width: 800px) {
  .clients-supplier-item-head{
    display: none;
  }
}
@media (max-width: 800px) {
  .clients-supplier-item-row,
  .clients-supplier-item-description,
  .clients-supplier-item-logo {
    width: 95%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .clients-supplier-item-logo {
    order: 1;
  }
}
@media (max-width: 800px) {
  .clients-supplier-item-description {
    order: 2;
  }
}
@media (max-width: 800px) {
  .clients-supplier-item-description{
    margin-top: 10px;
  }
}
@media (max-width: 800px) {
  .clients-supplier-item-description {
    padding: 10px;
    border-bottom: 1px solid rgba(30, 30, 30, 0.1)
  }
}

@media (max-width: 800px) {
  .for-clients-supplier-main-title {
    margin: 0;
  }
}
@media (max-width: 800px) {
  .for-clients-howtowork-wrap,
  .for-clients-suppliers-logo-wrap,
  .for-clients-main-suppliers-description-row,
  .forclients-faq-main-row,
  .for-clients-not-found-row {
    width: 97%;
  }
}
@media (max-width: 800px) {
  .for-clients-faq-accordion-item-title {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .for-clients-faq-accordion-item-btn-wrap {
    position: relative;
    width: 10%;
    top: 0%;
    right: 0;
  }
}
@media (max-width: 800px) {
  .for-clients-not-found-row{
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .for-clients-not-found-row,
  .for-clients-not-found-btn-item {
    flex-direction: column;
    flex-wrap: wrap;
    width: 97%;
    max-width: 97%;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .for-clients-not-found-tz-wrap {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 800px) {
  .for-clients-not-found-description {
    text-align: center;
    padding: 0;
  }
}
@media (max-width: 800px) {
  .for-clients-howtowork .for-clients-detail-from-row {
    max-width: 80%;
    width: 80%;
    top:130%;
  }
}
@media (max-width: 800px) {
  .for-clients-consult-from-row {
    max-width: 80%;
    width: 80%;
    top:100%;
  }
}

@keyframes accordionAnimate {
  0% {
    max-height: 0px;
    min-height: 0px;
  }
  100% {
    max-height: 0px;
    min-height: 100px;
  }
}