.quiz-mainpage-popup-background {
    background-color: rgba(0,0,0,.8);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 100;
    position: fixed;
    top: 0;
}
.quiz-mainpage-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 100;
    max-width: 1024px;
    min-width: 1024px;
    min-height: 400px;
    top: 0%;
    background-color: white;
    border: 1px solid #00458B;
    border-radius: 10px;
    animation-duration: 0.5s;
    animation-name: grow;
}
.quiz-mainpage-body-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-height: 100vh;
    align-items: stretch;
    /* min-height: 800px; */
}
.quiz-controlpanel-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    left: 0;
    min-height: 600px;
    max-width: 20%;
    min-width: 220px;
    background-color: whitesmoke;
}
.quiz-controlpanel-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 220px;
    min-height: 70px;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.quiz-controlpanel-item-name {
    max-width: 200px;
    text-align: center;
    padding: 0 20px;
    user-select: none;
}
.quiz-controlpanel-item-active {
    color: white;
    background-color: #3FD2C7;
}
.quiz-controlpanel-item-active::after {
    content: '';
    right: -20px;
    width: 0; height: 0;
    border-style: solid;
    border-width: 10px;
    position: absolute;
    border-color: transparent transparent transparent #3FD2C7;
}
.quiz-content-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 720px;
    max-width: 75%;
    min-width: 75%;
    padding: 20px 10px 10px 0px;
    overflow: hidden;
    position: relative;

    right: 0;
}
.quiz-content-wrap-aimate {
    opacity: 0;
    transition: fade-in 1s ease-in;
    animation: quiz_forward_opacity 1s forwards;
}
.quiz-content-wrap label {
    font-size: 18px;
    font-family: gothampro bold;
}
.quiz-content-wrap fieldset label {
    font-weight: normal;
    font-size: 16px;
    font-family: centurygothic;
}
.step-main-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 700px;
    justify-content: center;
}
.quiz-mainpage-title-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}
.quiz-mainpage-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    max-width: 80%;
}
.quiz-mainpage-title-wrap h2 {
    text-align: center;
    font-size: 22px;
    margin: 0;
    color: #00458B;
}
.quiz-mainpage-close-btn-wrap {
    display: flex;
    flex-direction: column;
    right: 3.5%;
    flex-wrap: wrap;
    position: absolute;
    min-height: 30px;
}
.quiz-mainpage-content-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 88%;
    min-width: 750px;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
}
.quiz-products-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 650px;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    /* margin-bottom: 20px; */
}
.quiz-products-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-width: 33%;
    max-height: 300px;
    /* min-height: 320px;
    min-width: 180px;
    max-height: 333px; */
    height: 50%;
    width: 30%;
    margin-bottom: 17px;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    opacity: 1;
    transform: translateX(-150%);
    animation: quiz-product-types-in 1s forwards;
}
.quiz-products-item:nth-child(n+4) {
    margin-bottom: 0;
}
.quiz-products-item img {
    max-width: 100%;
    width: 100%;
    max-height: 300px;
}
.quiz-products-item-not-select {
    filter: brightness(0.5);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
/* .quiz-product-img-wrap:hover {
    border: 1px solid #3FD2C7;
    transition: border-color 0.5s ease-in-out;
} */
.quiz-product-selected-icon-wrap,
.quiz-product-selected-icon-wrap-active {
    position: absolute;
    /* top: 44%; */
    right: 43%;
    bottom: 5%;
}
.quiz-product-decription-wrap,
.quiz-product-img-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    /* max-width: 300px;
    min-width: 180px; */
    text-align: center;
    font-size: 16px;
}
.quiz-product-img-wrap img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}
.quiz-products-item h4 {
    margin: 0;
    padding: 2px 0;
    /* background-color: rgb(63, 210, 199, 0.4); */
    background-color: #3FD2C7;
    color: white;
}
.quiz-products-category-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 150px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 17%;
    pointer-events:visible;
}
.quiz-products-category-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.quiz-products-category-wrap select,
.quiz-package-item-size-wrap select {
    background-color: #00458B;
    color: white;
    padding: 4px;
    width: 150px;
    border: none;
    font-size: 15px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
    min-width: 150px;
    max-width: 150px;
}
  
.quiz-products-category-wrap:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}
.next-step-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: auto;
}
.next-step-btn-wrap,
.prev-step-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 33%;
    justify-content: center;
}
.next-step-btn-wrap-hidden {
    display: none;
}
.step-counter-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 20%;
    padding: 0 10px;
    padding-right: 4%;
}
.next-step-btn,
.prev-step-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 130px;
    max-width: 200px;
    height: 30px;
    padding: 0 40px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.prev-step-btn {
    width: 200px;
    background: none;
    color: #1E1E1E;
}
.next-step-btn::before {
    content:'\2192';
    position: absolute;
    font-size: 20px;
    top: -8%;
    right: 5%;
}
.prev-step-btn::before {
    content: '\2190';
    position: absolute;
    font-size: 20px;
    top: -8%;
    left: 5%;
}
.step-counter-value {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.quantity-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 94.5%;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5% 0;
}
#quiz-qnt {
    color:#00458B;
    margin: 0 10px;
    letter-spacing: 2px;
}
.quantity-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.quantity-value-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.quantity-title-wrap h3 {
    margin: 0;
}
.quantity-quiz-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.quantity-quiz-range-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    max-width: 550px;
}
.quantity-value-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.quantity-quiz-range {
    width: 100%;
    cursor: pointer;
    padding: 0;
    border: 0;
    accent-color: #00458B;
    cursor: ew-resize;
}
.quantity-quiz-range-hide {
    visibility: hidden;
}
.quantity-quiz-range:hover,
.quantity-quiz-range:focus {
    accent-color: #00458B;
}
.quantity-custom-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.quantity-description-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.quantity-description-wrap .quiz-description-text {
    padding: 0 40px;
    margin: 0;
    text-align: center;
    max-width: 80%;
}
.quiz-description-text p {
    margin: 5px 0;
}
.qnt-apply-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}
.qnt-apply-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 200px;
    height: 25px;
    padding: 0 20px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.quiz-deadline-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    padding: 10px;
}
.quiz-description-text {
    font-size: 14px;
    justify-content: center;
    font-style: italic;
}
.quiz-description-text::before {
    content: "'' ";
}
.quiz-description-text::after {
    content: " ''";
}
.quiz-deadline-wrap h3 {
    margin: 0;
}
.quiz-deadline-radio-wrap fieldset {
    border: none;
}
.quiz-deadline-radio-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
}
.quiz-deadline-radio-item input {
    width: 16px;
    margin-right: 10px;
}
.quiz-deadline-radio-item input,
.quiz-deadline-radio-item label {
    cursor: pointer;
    color: #1E1E1E;
}
.quiz-deadline-custom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-deadline-custom-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-deadline-custom-title-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
}
.quiz-deadline-custom-title-wrap label {
    font-size: 18px;
    font-weight: bold;
    font-family: gothampro bold;
}
.quiz-deadline-custom-input-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-deadline-custom-input-wrap textarea {
    min-width: 300px;
}
.quiz-deadline-custom-input-wrap textarea,
.quiz-deadline-custom-input-wrap button {
    min-height: 40px;
    max-height: 40px;
}
.quiz-deadline-custom-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.quiz-deadline-custom-input-wrap button,
.quiz-package-custom-package-btn,
.quiz-advanced-settings-comment-btn-wrap button,
.quiz-advanced-settings-tz-btn-save,
.quiz-advanced-settings-delivery-custom-field-btn button,
.result-form-get-contacts-send-btn button,
.result-form-get-contacts-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 200px;
    height: 20px;
    padding: 0 20px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.next-step-btn:hover,
.quiz-package-custom-package-input-file-btn:hover,
.quiz-package-custom-package-btn:hover,
.quiz-advanced-settings-tz-input-file-btn:hover,
.result-form-get-contacts-send-btn:hover,
.quiz-advanced-settings-delivery-custom-field-btn button:hover,
.qnt-apply-button:hover,
.quiz-question-send-btn-wrap button:hover,
.tz-download-template-btn-wrap a:hover {
    background-color: #00458B;
    transition: background .4s ease;
}
.quiz-package-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-package-items-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: baseline;
}
.quiz-package-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    width: 29%;
    /* min-height: 225px; */
    min-height: 225px;
    max-height: 260px;
    max-width: 33%;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transform: translateX(-150%);
    animation: quiz-product-types-in 0.7s forwards;
}
.quiz-package-item-img-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
}
.quiz-package-item:hover {
    border-color: #3FD2C7;
}
.quiz-package-item-img-wrap img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-shrink: 0;
    z-index: 0;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.quiz-package-item-title-wrap {
    width: 100%;
    flex-wrap: wrap;
    line-height: 1.2;
    font-size: 16px;
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 0.5%;
    padding: 4px 0;
    pointer-events: none;
    background-color: #3FD2C7;
    color: white;
}
.quiz-package-item-title-wrap h4 {
    margin: 0;
}
.quiz-package-item-size-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 10%;
}
.quiz-package-item-size-wrap select {
    width: 150px;
    cursor: pointer;
}
.item-size-selected {
    background-color: red;
    border-color: red;
}
.quiz-package-pages-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-package-pages-items {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    min-height: 20px;
}
.quiz-package-page-item {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
}
.quiz-package-page-item button {
    cursor: pointer;
}
.quiz-package-page-item-slide-btn,
.quiz-package-page-item-slide-btn-active  {
    background-color: gray;
    border-radius: 10px;
    border:none;
    min-height: 8px;
    max-height: 8px;
    width: 70px;
}
.quiz-package-page-item-slide-btn-active {
    background-color: #00458B;
}
.quiz-package-page-item-slide-btn:hover {
    background-color: #00458B;
}
.quiz-package-custom-package-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.quiz-package-custom-package-wrap .quantity-custom-wrap {
    margin-bottom: 5px;
}
.quiz-package-custom-input-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.quiz-package-custom-input-wrap textarea {
    width: 300px;
    margin-right: 20px;
    max-height: 35px;
}
.quiz-package-input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
}
.quiz-package-custom-input-wrap input[type=file],
.quiz-question-file-input-wrap  input[type=file]{
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.quiz-package-custom-input-wrap .input-file {
    position: relative;
    display: inline-flex;
    margin-right: 15px;
}
.quiz-package-custom-package-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 800px;
}
.quiz-package-custom-package-input-file-btn,
.quiz-advanced-settings-tz-input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 19px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
}
.quiz-package-custom-package-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.quiz-package-custom-package-btn {
    height: 40px;
}
.quiz-package-checkbox-wrap {
    bottom: 42%;
    left: 44%;
    right: 30%;
    max-width: 30px;
    position: absolute;
    pointer-events: none;
}
.quiz-package-custom-package-description-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
    width: 100%;
}
.quiz-package-custom-package-upload-filename {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.input-file-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.quiz-advanced-settings-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 700px;
    padding: 10px;
}
.quiz-advanced-settings-delivery-wrap {
    max-width: 700px;
}
.quiz-advanced-settings-services-wrap,
.quiz-advanced-settings-budget-wrap,
.quiz-advanced-settings-delivery-wrap  {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}
.quiz-advanced-settings-delivery-wrap,
.quiz-advanced-settings-services-wrap fieldset,
.quiz-advanced-settings-budget-wrap fieldset,
.quiz-advanced-settings-delivery-wrap fieldset {
    border: none;
    padding: 0;
}
.quiz-advanced-settings-service-item-wrap input[type="radio"],
.quiz-advanced-settings-service-item-wrap label,
.quiz-advanced-settings-budget-item-wrap input[type="radio"],
.quiz-advanced-settings-budget-item-wrap label,
.quiz-advanced-settings-delivery-wrap label,
.quiz-advanced-settings-delivery-wrap input[type="radio"] {
    cursor: pointer;
}
.quiz-advanced-settings-service-item-wrap label,
.quiz-advanced-settings-budget-item-wrap label {
    color: #1E1E1E;
}
.quiz-advanced-settings-buget-custom-range-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 700px;
    margin-bottom: 10px;
}
.quiz-advanced-settings-buget-custom-range-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    letter-spacing: 5px;
    justify-content: center;
}
.quiz-advanced-settings-buget-custom-range {
    width: 100%;
    cursor: pointer;
    padding: 0;
    border: 0;
    accent-color: #00458B;
    cursor: ew-resize
}
.quiz-advanced-settings-tz-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.quiz-advanced-settings-budget-title,
.quiz-advanced-settings-services-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-advanced-settings-budget-title h3,
.quiz-result-title h3 {
    color: #1E1E1E;
}
.quiz-advanced-settings-budget-title h3,
.quiz-advanced-settings-services-title h3,
.quiz-advanced-settings-comment-wrap h3,
.quiz-question-form-options-wrap h3,
.quiz-result-title h3,
.quiz-deadline-wrap h3,
.quiz-deadline-custom-title-wrap label,
.quiz-advanced-settings-delivery-custom-field-input label,
.result-form-contacts-item label,
.quiz-quistion-form-input-wrap label,
.quiz-question-form-comment-wrap label {
    margin: 0;
    font-family: gothampro bold;
    color: #00458B;
}
.quiz-question-form-options-wrap h3 {
    font-weight: normal;
}
.quiz-advanced-settings-service-item-wrap,
.quiz-advanced-settings-budget-item-wrap,
.quiz-advanced-settings-delivery-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
}
.quiz-advanced-settings-delivery-item label {
    color: #1E1E1E;
}
.quiz-advanced-settings-tz-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}
.quiz-advanced-settings-tz-filename {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 200px;
}
.quiz-advanced-settings-comment-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.quiz-advanced-settings-tz-btn-wrap input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.quiz-advanced-settings-comment-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.quiz-advanced-settings-comment-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 440px;
}
.quiz-advanced-settings-comment-row textarea {
    min-width: 300px;
    min-height: 75px;
}
.quiz-advanced-settings-comment-btn-wrap button,
.quiz-advanced-settings-tz-btn-save,
.quiz-advanced-settings-delivery-custom-field-btn button {
    min-height: 30px;
}
.result-form-get-contacts-send-btn {
    min-height: 35px;
}
.quiz-advanced-settings-tz-btn-wrap label{
    margin-right: 20px;
}
.quiz-advanced-settings-delivery-custom-field-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    margin-left: 10px;
    align-items: flex-end;
}
.quiz-advanced-settings-delivery-custom-field-row h3 {
    margin: 0;
    margin-bottom: 5px;
}
.quiz-advanced-settings-delivery-custom-field-row input {
    min-height: 25px;
    max-height: 25px;
}
.quiz-advanced-settings-delivery-custom-field-btn {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
}
.quiz-result-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 97.5%;
    max-height: 230px;
    overflow-y: scroll;
    margin-bottom: 10px
}
.quiz-result-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-result-title h3 {
    margin: 0;
}
.result-quiz-table-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
}
.quiz-result-table-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
    overflow: hidden;
}
.quiz-result-value-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 99%;
    margin-bottom: 5px;
    padding: 2px;
    justify-content: center;
    align-items: center;
}
.quiz-result-value-row:nth-child(even) {
    background-color: #DDDEEE;
}
.quiz-result-value-row:nth-child(odd) {
    background-color: #D3ECD7;
}
.quiz-result-value-title {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}
.quiz-result-value-title h4 {
    margin: 0 5px;
    font-size: 16px;
}
.quiz-result-value {
    display: flex;
    flex-wrap: wrap;
    width: 47%;
    justify-content: flex-end;
}
.result-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.result-description p {
    margin: 5px 0;
}
.quiz-advanced-settings-delivery-custom-field-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
}
.result-get-contacts-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.result-form-get-contacts-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.result-form-get-contacts-description {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
}
.result-form-get-contacts-title h3 {
    font-size: 20px;
    text-align: center;
    color: #00458B;
    margin: 0;
}
.result-form-get-contacts-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.result-form-get-contacts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.result-form-contacts-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 33%;
}
.result-form-contacts-item-input {
    display: flex;
    flex-wrap: wrap;
    width: 30%;
    margin-right: 10px;
}
.result-form-contacts-item-input input {
    min-height: 25px;
    max-width: 175px;
}
.result-form-get-contacts-send-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.quiz-description-result-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.quiz-popup-fade-out {
    animation: quiz-fadeOut ease 8s;
    -webkit-animation: quiz-fadeOut ease 8s;
    -moz-animation: quiz-fadeOut ease 8s;
    -o-animation: quiz-fadeOut ease 8s;
    -ms-animation: quiz-fadeOut ease 8s;
}
.delivery-city-apply {
    content: 'ok'
}
.quiz-advanced-settings-delivery-custom-input-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.quiz-advanced-settings-delivery-custom-active-city {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    margin-bottom: 5px;
}
.quiz-question-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    left: 4.4%;
    width: 100%;
    max-width: 700px;
    min-height: 150px;
    border-radius: 10px;
    background-color: #F5F5F5;
    animation: flipTop 1s forwards;
}
.quiz-question-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.quiz-quistion-form-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 30%;
    max-width: 33%;
}
.quiz-quistion-form-input-wrap label,
.quiz-question-form-comment-wrap label,
.tz-form-wrap label {
    cursor: pointer;
}
.quiz-quistion-form-input-wrap input {
    max-width: 160px;
    min-height: 25px;
}
.quiz-question-additional-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
}

.quiz-question-form-comment-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 60%;
    max-width: 70%;
}
.quiz-question-form-comment-wrap textarea {
    width: 88.3%;
    min-height: 150px;
}
.quiz-question-form-options-wrap  {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 30%;
    max-width: 40%;
}
.quiz-question-form-options-wrap h3 {
    text-align: center;
    font-size: 18px;
    max-height: 30px;
}
.quiz-question-file-input-wrap {
    display: flex;
    flex-direction: column;
}
.quiz-question-select-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50px;
    position: absolute;
    top: 18%;
}
.quiz-question-select-wrap select {
    max-width: 100%;
    font-size: 16px;
    max-width: 200px;
}
.quiz-question-select-wrap option {
    font-size: 16px;
}
.quiz-question-send-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
}
.quiz-question-send-btn-wrap button,
.tz-form-send-btn-wrap button,
.tz-download-template-btn-wrap a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 217px;
    min-width: 150px;
    height: 30px;
    padding: 0 20px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}
.quiz-question-img-banner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 700px;
    max-width: 100%;
    margin-bottom: 20px;
}
.quiz-question-img-banner img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
}
.tz-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    left: 4.4%;
    width: 100%;
    max-width: 700px;
    justify-content: space-between;
    background-color: #F5F5F5;
    border: 1px solid transparent;
    border-radius: 10px;
    animation: flipTop 1s forwards;
}
.tz-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
    align-items: center;
}
.tz-form-wrap label {
    text-align: left;
    margin: 0;
    font-family: gothampro bold;
    color: #00458B;
}
.tz-form-wrap form {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 300px;
    padding: 10px;
    display: flex;
}
.tz-download-template-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
    align-items: center;
}
.tz-download-template-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.tz-form-input-file-wrap .input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    height: 30px;
    padding: 4px 6px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
}
.tz-form-input-file-wrap .input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.tz-form-input-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.tz-form-input-wrap input {
    height: 25px;
}
.tz-form-send-btn-wrap,
.tz-form-input-file-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
}
.tz-form-send-btn-wrap button {
    width: 100%;
}
.tz-download-template-text-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 85%;
    margin-bottom: 20px;
    padding: 10px;
    margin-top: 20px;
}
.tz-download-template-text-wrap p {
    margin: 0;
}
.tz-form-wrap-file-input input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: flex;
    width: 0;
    height: 0;
}
.tz-form-wrap-file-input label {
    font-size: 16px;
    cursor: pointer;
    color: #fff;
}
.tz-form-wrap-file-input {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #3FD2C7;
    line-height: 16px;
    height: 30px;
    padding: 6px 8px;
    box-sizing: border-box;
    border: none;
    transition: background-color 0.2s;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
}
.tz-form-wrap-file-input-description {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}
.tz-form-img-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.tz-form-img-wrap img {
    max-width: 100%;
    max-width: 100%;
    border-radius: 10px 10px 0 0;
}
.quiz-controlpanel-contacts-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 94%;
    position: absolute;
    bottom: 3.5%;
}
.quiz-controlpanel-contact-phone a:hover, 
.quiz-controlpanel-contact-email a:hover,
.quiz-controlpanel-contact-phone a, 
.quiz-controlpanel-contact-email a {
    text-decoration: none;
}
.quiz-controlpanel-contact-email,
.quiz-controlpanel-contact-phone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}
.quiz-controlpanel-contact-phone img,
.quiz-controlpanel-contact-email img {
    max-width: 28px;
    max-height: 28px;
    padding-right: 5%;
}
.quiz-happy-state-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
}
.quiz-happy-state-order {
    font-weight: bold;
}
.quiz-happy-state-wrap p {
    text-align: center;
    margin: 0;
    padding: 20px;
}
.quiz-happy-state-to-mainpage a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 217px;
    min-width: 150px;
    height: 30px;
    padding: 0 20px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}

@keyframes quiz-fadeOut {
    0% {opacity:1;}
    100% {
        opacity:0;
    }
}

@keyframes quiz-product-types-in {
    0% {transform: translateX(-150%);}
    100% {transform: translateY(0);}
}

@keyframes quiz_forward_opacity {
    0% {opacity: 0.5;}
    100% {
        opacity: 1;     
        visibility: visible;
    }
}

@keyframes flipTop {
    0% {transform: translateX(-100%);}
  100% {transform: translateY(0);}
}

@media (max-width: 1025px) {
    .quiz-mainpage-popup-background {
        justify-content: flex-start;
        align-items: baseline;
    }
}
@media (max-width: 1025px) {
    .quiz-mainpage-popup-wrap {
        min-width: 99%;
        max-width: 99%;
        justify-content: space-around;
    }
}
@media (max-width: 1025px) {
    .quiz-controlpanel-wrap {
        min-height: 400px;
        min-width: 200px;
    }
}
@media (max-width: 1025px) {
    .next-step-btn, .prev-step-btn {
        padding: 0 30px;
    }
}

@media (max-width: 1025px) {
    .quiz-controlpanel-item,
    .quiz-controlpanel-item-active {
       min-width: 200px;
    }
}
@media (max-width: 1025px) {
    .quiz-content-wrap {
        width: 100%;
        padding: 20px 10px;
    }
}
@media (max-width: 1025px) {
    .quiz-products-row {
       justify-content: space-around;
    }
}

@media (max-width: 1025px) {
    .quiz-mainpage-body-row{
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}
@media (max-width: 1025px) {
    .quiz-mainpage-content-wrap{
        min-width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 820px) {
    .quiz-controlpanel-wrap {
        min-width: 150px;
        max-width: 150px;
    }
}
@media (max-width: 820px) {
    .quiz-controlpanel-item,
    .quiz-controlpanel-item-active {
        min-width: 150px;
        max-width: 150px;
    }
}
@media (max-width: 820px) {
    .quiz-products-item {
       max-height: 240px;
    }
}
@media (max-width: 820px) {
    .quiz-products-row {
       max-height: 80%
    }
}
@media (max-width: 820px) {
    .quiz-controlpanel-wrap {
       font-size: 14px;
    }
}
@media (max-width: 820px) {
    .prev-step-btn-wrap {
       width: 40%;
    }
}
@media (max-width: 820px) {
    .quiz-package-item,
    .quiz-package-item 
    .quiz-products-item-not-select {
        width: 30%;
        min-height: 190px;
    }
}
@media (max-width: 820px) {
    .result-quiz-table-wrap fieldset {
        margin: 0;
    }
}
@media (max-width: 820px) {
    .result-form-contacts-item-input input {
        margin-right: 10px;
        max-width: 150px;
    }
}
/* modbile */

@media (max-width: 600px) {
    .quiz-mainpage-popup-wrap {
        width: 100%;
        height: 100vh;
        justify-content: space-between;
    }
}
@media (max-width: 600px) {
    .quiz-mainpage-body-row {
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 100vh;
    }
}
@media (max-width: 600px) {
    .quiz-controlpanel-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 100px;
        max-height: 150px;
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .quiz-controlpanel-item,
    .quiz-controlpanel-item-active {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 33%;
        min-height: 40px;
        font-size: 14px;
        transform: scale(0.9);
    }
}
@media (max-width: 600px) {
    .quiz-controlpanel-item-name {
        max-width: 150px;
        font-size: 16px;
        padding: 0 2px;
        font-size: 14px;
    }
}
@media (max-width: 600px) {
    .quiz-controlpanel-item, 
    .quiz-controlpanel-item-active {
        min-width: 30%;
        max-width: 33%;
        max-height: 40px;
        height: 40px;
        padding: 10px 0;
    }
}
@media (max-width: 600px) {
    .quiz-controlpanel-contacts-wrap {
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
    }
}

@media (max-width: 600px) {
    .quiz-controlpanel-contact-phone,
    .quiz-controlpanel-contact-email {
        min-width: 45%;
        max-width: 50%;
        padding: 0;
        margin: 0;
        align-items: center;
    }
}
@media (max-width: 600px) {
    .quiz-content-wrap {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        overflow-y: scroll;
        padding: 10px 0;
    }
}
@media (max-width: 600px) {
    .quiz-mainpage-title-wrap {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        align-items: center;
    }
}
@media (max-width: 600px) {
    .quiz-mainpage-title-row {
       margin-bottom: 10px;
    }
}
@media (max-width: 600px){
    .quiz-mainpage-title-wrap h2 {
        font-size: 18px;
        text-align: center;
    }
}
@media (max-width: 600px){
    .quiz-products-row {
        max-height: 100vh;
        overflow-y: scroll;
        align-items: baseline;
    }
}
@media (max-width: 600px) {
    .quiz-products-item,
    .quiz-product-img-wrap 
    .quiz-products-item-not-select {
        max-width: 50%;
        min-height: 200px;
        width: 45%;
        margin-bottom: 20px;
    }
}
@media (max-width: 600px) {
    .quiz-products-item,
    .quiz-product-img-wrap 
    .quiz-products-item-not-select {
        max-width: 50%;
        min-height: 200px;
        width: 45%;
        margin-bottom: 20px;
    }
}
@media (max-width: 600px) {
    .quiz-products-item img {
        max-height: 250px;
    }
}
@media (max-width: 600px){
    .quiz-product-decription-wrap {
        font-size: 15px;
    }
}

@media (max-width: 600px) {
    .quiz-mainpage-content-wrap {
        min-width: 100%;
        max-width: 100%;
        max-height: 600px;
        overflow-y: scroll;
        padding: 0 0 10px 0;
        align-items: self-start;
    }
}

@media (max-width: 600px){
    .prev-step-btn-wrap,
    .next-step-btn-wrap {
        max-width: 45%;
        text-align: center;
    }
}
@media (max-width: 600px){
    .next-step-btn, 
    .prev-step-btn {
       padding: 0;
    }
}
@media (max-width: 600px) {
    .next-step-btn::before, 
    .prev-step-btn::before{
       content: '';
    }
}

@media (max-width: 600px) {
    .step-counter-wrap {
       width: 10%;
    }
}
@media (max-width: 600px) {
    .next-step-wrap{
        justify-content: space-between;
        width: 100%;
        padding: 0 5px;
    }
}
@media (max-width: 600px) {
    .quiz-package-item,
    .quiz-package-item .quiz-products-item-not-select  {
       max-width: 50%;
       width: 45%;
       min-height: 200px;
       margin: 0;
    }
}
@media (max-width: 600px) {
    .quiz-package-item,
    .quiz-package-item .quiz-products-item-not-select  {
       margin: 0 0 10px 0;
    }
}

@media (max-width: 600px) {
    .quiz-package-item-img-wrap  {
        align-items: normal;
    }
}
@media (max-width: 800px) {
    .quiz-package-item-img-wrap img  {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .quiz-package-items-row {
       justify-content: space-evenly;
       max-height: 420px;
       margin-bottom: 10px;
       overflow-y: scroll;
    }
}
@media (max-width: 600px) {
    .quiz-package-custom-input-wrap textarea{
        width: 60%;
        margin-bottom: 10px;
        max-height: 150px;
        min-height: 150px;
    }
}
@media (max-width: 600px) {
    .quiz-package-custom-package-input {
        padding: 0 10px;
    }
}
@media (max-width: 600px) {
    .quiz-advanced-settings-services-wrap,
    .quiz-advanced-settings-budget-wrap,
    .quiz-advanced-settings-comment-wrap,
    .quiz-advanced-settings-delivery-wrap fieldset,
    .quiz-advanced-settings-budget-title {
        padding: 0 10px;
    }
}
@media (max-width: 600px) {
    .quiz-content-wrap fieldset label {
        max-width: 340px;
        padding: 0 10px;
    }
}
@media (max-width: 600px) {
    .quiz-result-wrap {
        width: 95%;
        padding: 0 10px;
        max-height: 100vh;
        overflow-y: scroll;
    }
}
@media (max-width: 600px){
    .quantity-wrap {
        width: 95%;
        margin: 0 10px;
        padding: 10px 0;
    }
}

@media (max-width: 600px) {
    .result-form-get-contacts-title{
        margin: 0;
    }
}
@media (max-width: 600px) {
    .result-form-get-contacts-row {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width: 600px) {
    .result-form-contacts-item {
        width: 50%;
        min-width: 200px;
    }
}

@media (max-width: 600px) {
    .result-form-contacts-item-input input,
    .result-form-contacts-item-input label {
        max-width: 200px;
    }
}
@media (max-width: 600px) {
    .result-form-contacts-item label {
        width: 100%;
        min-width: 200px;
    }
}
@media (max-width: 600px) {
    .quiz-advanced-settings-delivery-custom-field-row {
        margin-bottom: 20px;
    }
}
@media (max-width: 600px) {
    .quiz-advanced-settings-service-item-wrap,
    .quiz-advanced-settings-budget-item-wrap,
    .quiz-advanced-settings-delivery-item  {
        flex-wrap: nowrap;
    }
}
/* quest && tz forms */
@media (max-width: 1025px) {
    .quiz-question-form-wrap,
    .tz-form-row {
        width: 90%;
        max-width: 400px;
        padding: 20px 0;
        margin-left: 15px;
    }
}
@media (max-width: 820px) {
    .quiz-happy-state-wrap {
        width: 90%;
        max-width: 400px;
        padding: 20px 0;
        margin-left: 15px;
    }
}
@media (max-width: 600px) {
    .quiz-happy-state-wrap {
        margin-left: 10px;
        width: 95%;
        padding: 20px 0
    }
}
@media (max-width: 820px) {
    .quiz-quistion-form-input-wrap label,
    .quiz-quistion-form-input-wrap input {
        max-width: 130px;
        margin-right: 10px;
    }
}
@media (max-width: 600px) {
    .quiz-question-form-row {
        flex-direction: column;
        flex-wrap: wrap;
        width: 60%;
    }
}
@media (max-width: 600px)  {
    .quiz-quistion-form-input-wrap {
        width: 100%;
        min-width: 100%;
        margin: 5px 17px;
    }
}
@media (max-width: 600px)  {
    .quiz-quistion-form-input-wrap input,
    .quiz-quistion-form-input-wrap label {
        width: 100%;
        min-width: 88.3%;
    }
}

@media (max-width: 600px)  {
    .quiz-question-send-btn-wrap button,
    .quiz-question-select-wrap select {
        min-width: 100px;
        max-width: 100%;
    }
}
@media (max-width: 860px) {
    .tz-form-row {
        width: 90%;
    }
}
@media (max-width: 860px) {
    .tz-form-input-wrap input {
        max-width: 70%;
        min-width: 50%;
    }
}
@media (max-width: 600px) {
    .tz-form-row {
        flex-direction: column;
        flex-wrap: wrap;
    }
}
@media (max-width: 600px)  {
    .tz-form-wrap {
        width: 100%;
        min-width: 100%;
        order: 1;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 600px)  {
    .tz-form-wrap form {
        max-width: 400px;
    }
}
@media (max-width: 600px)  {
    .tz-form-wrap form input,
    .tz-form-wrap form label {
        width: 100%;
        min-width: 250px;
    }
}
@media (max-width: 600px)  {
    .tz-download-template-wrap {
        width: 100%;
        min-width: 100%;
    }
}
@media (max-width: 600px) {
    .tz-download-template-text-wrap {
        margin: 0 0 20px 0;
    }
}
@media (max-width: 600px) {
    .tz-form-wrap .input-file {
        max-width: 140px;
        min-width: 140px;
        text-align: center;
    }
}
@media (max-width: 480px) {
    .tz-form-send-btn-wrap, .tz-form-input-file-wrap {
        justify-content: center;
    }
}