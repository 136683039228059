.container-center-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 100%;
    justify-content: center;
    align-items: center;
}
.main-header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1320px;
    min-width: 1024px;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
} 
.logo-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    max-width: 200px;
    width: 20%;
    min-height: 25px;
    justify-content: flex-start;
    position: relative;
    min-height: 70px;
}
.logo-wrap img {
    max-width: 200px;
}
.main-nav-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
}
.main-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    padding: 10px 0 0 10%;
}
.main-nav ul {
    margin: 0;
    padding: 0;
}
.main-nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    list-style: none;
}
.main-nav-list li {
    position: relative;
    list-style: none;
    margin-right: 10px;
    font-size: 18px;
    min-height: 25px;
    margin-right: 20px;
    /* text-transform: capitalize; */
}
.main-nav-list li a {
    position: relative;
    text-decoration: none;
}
.main-nav-list li a:hover {
    color: #00458B;
    position: relative;
    /* text-decoration: none; */
}
.main-nav-list li a::before {
    content: "";
    position: absolute;
    display: flex;
    width: 100%;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: #3FD2C7;
    transform: scaleX(0);
    transform-origin:left center;
    transition: transform 0.3s ease;
}
.main-nav-list li a:hover::before {
    transform: scaleX(1);
}
.callback-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    display: flex;
    max-height: 30px;
    min-height: 30px;
    font-size: 16px;
    margin: 5px;
}
.callback-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    user-select: none;
    background: #3FD2C7;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    min-height: 18px;
    border-radius: 2px;
}
.contacts-header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 30%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    min-width: 200px;
}
.header-worktime {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 10px;
}
.contacts-header-row a {
    text-decoration: none;
    color: #000;
}
.contacts-header-row a:hover {
    color: #00458B;
}
.callback-wrap {
    margin-right: 2%;
}
.callback-wrap,
.email-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.callback-icon,
.email-icon {
    padding: 0 10px;
    color: #3FD2C7;
    align-items: center;
    display: flex;
    max-height: 30px;
    min-height: 30px;
    font-size: 20px;
}
.filter-green {
    fill: red;
}
.email-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    justify-content: center;
    align-items: center;
}
.social-links-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}
.social-links-list li {
    max-width: 45%;
    list-style: none;
    margin-right: 10px;
}
.main-nav-submenu-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    top: 209%;
    position: absolute;
    z-index: 6;
    background-color: white;
    border: 1px solid #00458B;
    border-top: 0;
    visibility: hidden;
    transition: all 1s ease-in;
}
.main-nav-submenu-list.active {
    min-width: 270px;
    visibility: visible;
    padding-bottom: 10px;
}
.main-nav-submenu-list li {
    margin: 0;
    padding: 10px;
    border-top: 0;
}
.has-submenu::after {
    content: url('../../img/submenuBtn.svg');
    position: absolute;
    opacity: 0.4;
    right: 40%;
    bottom: -100%;
    transition: all 0.3s ease-in;
    filter: invert(21%) sepia(95%) saturate(1363%) hue-rotate(191deg) brightness(85%) contrast(107%);
}
.has-submenu-active::after {
    content: url('../../img/submenuBtn.svg');
    position: absolute;
    right: 40%;
    bottom: -150%;
    transition: all 0.3s ease-in;
    filter: invert(77%) sepia(65%) saturate(469%) hue-rotate(114deg) brightness(90%) contrast(82%);
}
.main-nav-submenu-list li a {
    width: 100%;
}

/* mobile menu */
.container-mobile {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    z-index: 10;
    max-width: 1024px;
    width: 100%;
    min-height: 65px;
    background-color: #00458B;
    border-bottom: 1px solid #00458B;
    justify-content: center;
    align-items: center;
}
.mobile-menu-logo-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 20%;
}
.mobile-menu-logo-wrap img {
    max-height: 50px;
}
.mobile-contact-info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    justify-content: space-between;
    align-items: center;
}
.contact-phone-mobile-wrap,
.contact-email-mobile-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
}
.contact-phone-mobile-wrap a {
    text-decoration: none;
    font-size: 16px;
}
.container-mobile ul {
    padding: 0;
    list-style-type: none;
}
#menu-mobile li {
    padding: 0 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}
#menu-mobile li:nth-child(1) {
    margin-top: 20px;
}
#menu-mobile #mobile-contacts-wrap-li {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    position: relative;
}
#mobile-footer-links-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
#mobile-footer-links-wrap  li {
    margin: 0;
    padding: 0;
}
#mobile-footer-links-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}
#mobile-footer-links-menu li {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    margin: 0 0 10px 0;
}
.content-mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    position: absolute;
}
.container-mobile  nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20%;
    max-width: 20%;
    background-color: white;
    height: 65px;
}
  
#menuToggle-mobile {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    max-width: 75px;
    z-index: 5;
    -webkit-user-select: none;
    user-select: none;
}
#menu-mobile {
    width: 100%;
    min-width: 400px;
}
.mobile-contacts-email {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
#menuToggle-mobile input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}
  
#menuToggle-mobile span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #00458B;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}
.mobile-menu {
    width: 100%;
}
#menu-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 430px;
    min-height: 450px;
    background-color: white;
    z-index: 6;
    border-top: none;
    top: 25px;
    left: -25px;
    border: 1px solid #00458B;
    border-top: 0;
    border-left: 0;
    animation-name: fadeInDown;
    animation-duration: 0.3s;
}
#menu-mobile a {
    text-decoration: none;
    color: #00458B;
}
#menu-mobile a:hover {
    color: #00458B;
}
#mobile-contacts-wrap-li {
    padding: 0;
    width: 100%;
}
.contacts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.mobile-contacts-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    margin-top: auto;
}
.mobile-contacts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.contact-item-mobile{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-width: 50%;
    height: 25px;
    margin-right: 20px;
}
.contact-item-mobile.social {
    flex-direction: row;
}
.contact-item-mobile.social img {
    max-width: 25px;
    margin: 0 2px;
}
.contact-item-mobile.social a {
    margin-right: 10px;
}
.mobile-social-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 40px;
    width: 30%;
    margin-right: 10px;
}
.mobile-social-item img {
    opacity: 0.9;
    cursor: pointer;
}
.mobile-social-item-whatsapp {
    background-color: white;
}
.mobile-email-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    margin-bottom: 20px;
}
.mobile-menu-item-has-submenu:first-child:after {
    content: url('../../img/submenuBtn.svg');
    position: absolute;
    top: -2%;
    right: 35%;
    transition: all 0.3s ease-in;
    filter: invert(21%) sepia(95%) saturate(1363%) hue-rotate(191deg) brightness(85%) contrast(107%);
}
.mobile-menu-item-has-submenu ul {
    margin-bottom: 0;
}
.mobile-menu-item-has-submenu ul li {
    margin: 5px!important;
}
@media (max-width: 1300px) {
    .main-header-row {
        justify-content: space-between;
        max-width: 1200px;
    }
}
@media (max-width: 1400px) {
    .container-center-header {
        padding: 0;
    }
}
@media (max-width: 1200px) {
    .contacts-header-row {
        justify-content: center;
        width: 30%;
    }
}
@media (max-width: 1200px) {
    .main-header-row {
        justify-content: center;
    }
}
@media (max-width: 1200px) {
    .contacts-header-row {
        justify-content: center;
    }
}
@media (max-width: 1200px) {
    .main-nav-wrap{
        max-width: 500px;
    }
}
@media (max-width: 1150px) {
    .main-header-row{
        max-width: 1100px;
    }
}
@media (max-width: 1024px) {
    .callback-wrap,
    .email-wrap {
        width: 48.5%;
        margin: 10px 0;
    }
}
@media (max-width: 1024px) {
    .container-center-header {
        display: none;
        margin: 0;
    }
}
@media (max-width: 600px) {
    .contacts-row {
        flex-direction: column;
    }
}
@media (max-width: 600px) {
    .contact-item-mobile {
        width: 100%;
        max-width: 100%;
        margin: 2px 0;
        justify-content: center;
    }
}
@media (max-width: 600px) {
    .mobile-menu-logo-wrap{
        margin-right: 15px;
    }
}
@media (max-width: 500px) {
    .mobile-contacts-wrap {
        width: 100%;
    }
}
@media (max-width: 399px) {
    .mobile-contacts-wrap {
        width: 90%;
    }
}
@media (max-width: 320px) {
    .mobile-contacts-wrap {
        width: 80%;
    }
}
@media (max-width: 600px) {
    .contact-item-mobile.social {
        display: none;
    }
}
@keyframes mobile-items-ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }