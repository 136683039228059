.image-gallery-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
}
.image-gallery-wrap.view-mode {
    position: unset;
}
.image-gallery-row {
    display: flex;
    flex-direction: row;
    overflow:hidden;
    justify-content: space-between;
    width: 100%;
}
.image-gallery-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 370px;
    width: 33%;
    margin-right: 20px;
}
.image-gallery-img-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100%;
    cursor: pointer;
    position: relative;
    filter: brightness(0.5);
    animation: galleryImageMinMoveRight 0.3s;
}
.image-gallery-img-wrap img {
    border-radius: 10px;
}
.image-gallery-img-wrap:hover {
    transition: 1s;
    filter: brightness(100%);
}
.image-gallery-img-wrap.active img {
    visibility: hidden;
}
.image-gallery-img-wrap.active {
    background-color: rgba(0,0,0,.5);
}
.image-gallery-icon-view {
    position: absolute;
    top: 50%;
    left: 45%;
    z-index: 5;
    visibility: hidden;
}
.image-gallery-icon-view.active {
    visibility: visible;
    transition: all ease-in-out;
    animation: galleryPopupOpen 1s forwards;
}
.image-gallery-icon-view img {
    max-width: 48px;
    filter: invert(65%) sepia(82%) saturate(328%) hue-rotate(126deg) brightness(94%) contrast(91%);
}
.image-gallery-prev-btn {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    left: -2%;
    top: 48%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_left.svg');
}
.image-gallery-next-btn {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    right: -1%;
    top: 48%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_right.svg');
}
.image-gallery-popup-background {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    min-width: 100%;
    top: 0%;
    position: fixed;
    z-index: 5;
    background-color: rgba(0, 0, 0, .9);
    opacity: 0;
    animation: galleryPopupOpen 0.5s forwards;
}
.image-gallery-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    min-height: 400px;
    position: relative;
}
.image-gallery-popup-img-item-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.image-gallery-popup-img-item-wrap img {
    max-width: 1408px;
    max-height: 880px;;
    animation: galleryPopupOpen 0.3s forwards;
    width: 100%;
    height: 100%;
   
}
.image-gallery-popup-img-item-description {
    width: 100%;
    position: absolute;
    bottom: -3.8%;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #3FD2C7;
    max-height: 40px;
    height: 40px;
}
.image-gallery-popup-img-item-description p {
    margin: 0;
    height: 100%;
}
.image-gallery-popup-close-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    top: 5%;
    right: 20%;
    z-index: 15;
}
.image-gallery-popup-close-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 5%;
    right: 9%;
    background-image: url('../../img/close_popup_gallery.svg');
    background-repeat: no-repeat;
    filter: invert(97%) sepia(48%) saturate(3249%) hue-rotate(135deg) brightness(88%) contrast(83%);
}
.image-gallery-popup-arrow-left {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: 50%;
    left: -10%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_left.svg');
}
.image-gallery-popup-arrow-right {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    cursor: pointer;
    right: -10%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_right.svg');
}
.image-gallery-popup-close-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    z-index: 5;
}
.image-gallery-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.image-gallery-title-wrap h2 {
    font-size: 34px;
    margin: 0 0 20px 0;
}
@media (max-width: 1400px) { 
    .image-gallery-item {
        width: 33%;
        max-width: 370px;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-item {
        width: 33%;
        max-width: 320px;
    }
}
@media (max-width: 1400px) { 
    .image-gallery-prev-btn{
        left: -2%;
    }
}
@media (max-width: 1400px) { 
    .image-gallery-next-btn {
        right: -1%;
    }
}
@media (max-width: 1400px) { 
    .image-gallery-popup-img-item-wrap img {
        max-width: 900px;
        width: 100%;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-popup-img-item-wrap img,
    .image-gallery-popup-img-item-description {
        max-width: 800px;
        width: 100%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-img-item-wrap img,
    .image-gallery-popup-img-item-description {
        max-width: 700px;
        width: 100%;
    }
}
@media (max-width: 990px) { 
    .image-gallery-popup-img-item-wrap img,
    .image-gallery-popup-img-item-description {
        max-width: 600px;
        width: 100%;
    }
}

@media (max-width: 1300px) { 
    .image-gallery-prev-btn{
        left: 4%;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-next-btn {
        right: 4%;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-popup-arrow-left {
        left: -2%;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-popup-arrow-right {
        right: -2%;
    }
}
@media (max-width: 1400px) { 
    .image-gallery-popup-background {
        height: 100vh;
        min-height: auto;
    }
}
@media (max-width: 1300px) { 
    .image-gallery-popup-background {
        height: 100vh;
        min-height: auto;
    }
}
@media (max-width: 1400px) { 
    .image-gallery-popup-close-btn {
        right: 4%;
        top: 10%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-arrow-left {
        left: 0%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-arrow-right {
        right: 0%;
    }
}

@media (max-width: 1100px) { 
    .image-gallery-popup-img-item-wrap img {
        width: 100%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-close-btn {
        right: 3%;
        top: 12%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-item {
        width: 33%;
        max-width: 310px;
    }
}

@media (max-width: 1100px) { 
    .image-gallery-prev-btn{
        left: 5%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-next-btn {
        right: 5%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-row {
        width: 95%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-wrap {
        width: 95%;
        min-height: auto;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-background {
        height: 70vh;
        min-height: auto;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-popup-close-btn-wrap {
        top: 15%;
    }
}
@media (max-width: 1100px) { 
    .image-gallery-item {
        width: 33%;
        max-width: 260px;
    }
}
@media (max-width: 1024px) { 
    .image-gallery-popup-background {
        height: 70vh;
        min-height: auto;
    }
}
@media (max-width: 800px) { 
    .image-gallery-popup-close-btn {
        right: 3%;
        top: 28%;
    }
}
@media (max-width: 700px) { 
    .image-gallery-popup-close-btn {
        right: 2%;
        top: 15%;
    }
}
@media (max-width: 600px) { 
    .image-gallery-popup-close-btn {
        right: 5%;
        top: 28%;
    }
}
@media (max-width: 900px) { 
    .image-gallery-item {
        width: 33%;
        max-width: 240px;
    }
}

@media (max-width: 600px) { 
    .image-gallery-item {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}

@media (max-width: 600px) { 
    .image-gallery-popup-img-item-wrap {
        width: 95%;
        max-width: 100%;
    }
}
@media (max-width: 990px) { 
    .image-gallery-popup-background {
        height: 100vh;
        min-height: auto;
    }
}
@media (max-width: 900px) { 
    .image-gallery-popup-background {
        height: 80vh;
        min-height: auto;
    }
}
@media (max-width: 800px) { 
    .image-gallery-popup-background {
        height: 100vh;
        min-height: auto;
    }
}

@media (max-width: 600px) { 
    .image-gallery-popup-close-btn-wrap {
        top: 25%;
    }
}
@media (max-width: 600px) { 
    .image-gallery-popup-arrow-right {
        right: 5%;
    }
}
@media (max-width: 600px) { 
    .image-gallery-popup-arrow-left {
        left: 5%;
    }
}
@media (max-width: 600px) { 
    .image-gallery-popup-img-item-description {
        width: 95%;
        bottom: -10%;
    }
}
@media (max-width: 600px) { 
    .image-gallery-popup-img-item-description {
        height: 25px;
    }
}




@keyframes galleryImageMinMoveRight {
    0% {transform: translateX(-300%)}
    100% {transform: translateX(0);}
}
@keyframes galleryPopupOpen {
    0% {opacity: 0;}
    100% {opacity: 1;}
}