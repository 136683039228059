.about-company-prezentation-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.about-company-text-content-wrap,
.about-company-video-content-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
}
.about-company-video-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.about-company-video-wrap video {
    max-width: 100%;
    min-height: 400px;
    object-fit: cover;
}
.about-company-icons-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
}
.about-company-icon-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    max-width: 300px;
    margin-bottom: 20px;
}
.about-company-icon {
    display: flex;
    flex-wrap: wrap;
    max-width: 80px;
    margin-right: 10px;
}
.about-company-icon img {
    width: 50px;
    filter: invert(72%) sepia(3%) saturate(14%) hue-rotate(11deg) brightness(102%) contrast(93%);
}
.about-company-text {
    display: flex;
    flex-wrap: wrap;
}
.about-company-prezentation-btn-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.about-company-prezentation-btn,
.about-company-production-consult-btn,
.about-page-production-req-btn,
.about-page-req-download-btn,
.about-page-tab-map-map-btn,
.about-production-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.about-company-title-wrap {
    margin-bottom: 20px;
}
.about-company-title-wrap h2 {
    font-size: 32px;
    margin: 0;
}
.about-company-prezentation-btn:hover,
.about-company-production-consult-btn:hover,
.about-page-production-req-btn:hover,
.about-page-req-download-btn:hover,
.about-page-tab-map-map-btn:hover,
.about-production-send-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}

/* prodTypes */
.about-company-production-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.about-company-production-goods-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 57%;
    margin-bottom: 20px;
}
.about-company-production-good-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    max-width: 50%;
    border-radius: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
}
.about-company-production-good-item-icon {
    display: flex;
    flex-direction: column;
    width: 60px;
    max-width: 50%;
}
.about-company-production-good-item-icon img {
    border-radius: 50%;
    filter: grayscale(0.4);
}
.about-company-production-good-item-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    max-width: 70%;
}
.about-company-production-photo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40.4%;
}
.about-company-production-photo-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px;
    width: 100%;
}
.about-company-production-photo img {
    max-width: 100%;
    border-radius: 10px;
}
.about-company-production-consult-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}
.about-company-production-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.about-company-production-title-wrap h2 {
    font-size: 34px;
    margin: 0 0 20px 0;
}
/* prod_content */
.about-page-production-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
}
.about-page-short-content-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    height: 100%;
}
.about-page-short-text-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
}
.about-page-short-content-wrap img {
    object-fit: contain;
    border-radius: 10px;
}
.about-page-large-content-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
}
.about-page-large-content-wrap h3 {
    font-size: 32px;
    margin: 0 0 20px 0;
}
.about-page-production-legal-info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    align-items: baseline;
    justify-content: space-between;
}
.about-page-production-legal-title,
.about-page-production-legal-download {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
}
.about-page-production-tab-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    min-height:600px;
}
.about-page-production-tab-links-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-height: 60px;
    height: 60px;
    overflow-y: hidden;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #00458B;
    color: white;
    
    border-radius: 10px 10px 0 0;
}
.about-page-production-tab-link-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 25%;
    max-width: 25%;
    cursor: pointer;
}
.about-page-production-tab-link-wrap.tab-active {
    background-color: #3FD2C7;
}
.about-page-production-tab-link-wrap a {
    color:white;
    padding: 20px;
    text-decoration: none;
}
.about-page-production-tab-link-wrap:hover {
    transition: background 0.3s;
    background-color: #3FD2C7;
}
.about-page-production-tab-link-wrap:first-child:hover,
.about-page-production-tab-link-wrap.tab-active:first-child {
    border-radius: 10px 0 0 0;
}
.about-page-production-tab-link-wrap:last-child:hover,
.about-page-production-tab-link-wrap.tab-active:last-child {
    border-radius: 0 10px 0 0;
}
.about-page-production-tab-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.about-page-production-tab-coop-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    align-items: center;
}
.about-page-production-tab-coop-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    align-items: center;
    justify-content: space-between;
}
.about-page-tab-cooperation-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    align-items: baseline;
    justify-content: space-between;
}
.about-page-tab-cooperation-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 40%;
    min-width: 200px;
    max-width: 50%;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    margin-bottom: 20px;
    background-color: #f5f5f5;
}
.about-page-tab-cooperation-item h4 {
    margin: 0 0 20px 0;
}
.about-page-production-reviews-logo-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 200px;
    max-width: 800px;
}
.about-page-production-review-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 25%;
    width: 25%;
}
.about-page-production-review-item img {
    max-width: 150px;
    padding: 10px;
}
.about-page-production-review-item img:hover {
    transform: scale(1.05);
    transition: transform .2s ease-in;
}
.about-page-req-table-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid #f5f5f5;
}
.about-page-req-table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 97.5%;
    justify-content: space-between;
    border-bottom: 1px solid #dbd4d4;
    padding: 0 10px;
    margin-bottom: 10px;
}
.about-page-req-table-row:last-child {
    border: none;
}
.about-page-req-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 30%;
    max-width: 35%;
    height: 100%;
    font-weight: bold;
    border-right:1px solid #dbd4d4;
}
.about-page-req-value {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 65%;
    max-width: 70%;
}
.about-page-req-download-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}
.about-page-contacts-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1100px;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
}
.about-page-worktime-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 60%;
}
.about-page-tab-map-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    max-width: 50%;
}
.about-page-contacts-row h4 {
    margin: 0 0 10px 0;
    font-size: 22px;
}
.about-page-contacts-row ul {
    margin: 0 0 20px 0;
}
.about-page-contacts-row ul li {
    margin-bottom: 10px;
}
.about-page-contacts-row p {
    margin: 0 0 10px 0;
}
.about-page-contacts-social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.about-page-contacts-social li {
    width: 40px;
    list-style: none;
    margin-right: 5px;
}
.about-page-contacts-row li::marker {
    color: #00458B;
}
.about-page-tab-map-wrap a {
    padding: 0 10px;
}
.about-page-social-img img {
    transition: transform 0.2s;
}
.about-page-social-img img:hover {
    transform: scale(1.05);
}
.about-production-consult-popup-background {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 80vh;
    position: absolute;
    justify-content: center;
    align-items: center;
}
.about-production-consult-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    max-width: 550px;
    min-height: 400px;
    border: 1px solid;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    border: 1px solid #00458B;
    z-index: 5;
    animation-duration: 0.3s;
    animation-name: grow;
}
.about-production-consult-popup-wrap h3,
.about-production-consult-popup-wrap h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
}
.about-production-form-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    max-width: 400px;
}
.about-production-consult-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.about-production-consult-form-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 45%;
    max-width: 50%;
}
.about-production-consult-popup-wrap label {
    font-size: 18px;
    font-family: gothampro bold;
    color: #00458B;
    cursor: pointer;
}
.about-production-form-textarea-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
}
.about-production-form-textarea-wrap label {
    text-align: left;
}
.about-production-form-textarea-wrap textarea {
    width: 100%;
    max-width: 480px;
}
.about-production-send-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.about-production-checkbox-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}
.about-production-checkbox {
    display: none;
}
.about-production-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
}
.about-production-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.about-production-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.form-mode-about-production-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.about-production-consult-form-item input {
    min-height: 30px;
    max-width: 180px;
}
.about-production-consult-form-item label,
.about-production-form-input-wrap {
    width: 100%;
}
.about-production-form-textarea-wrap textarea {
    width: 100%;
    min-height: 80px;
}
.about-production-consult-popup-close-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 40px;
    position: relative;
}
.about-production-consult-popup-close-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0;
    background-image: url("../../img/close_popup.svg");
  }
.about-production-consult-popup-close-btn:hover {
    background-image: url("../../img/close_popup_hover.svg");
}
.about-production-consult-popup-happy-state-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 1400px) { 
    .about-company-facts-row{
        justify-content: space-between;
    }
}
@media (max-width: 1400px) { 
    .about-conpany-fact-text {
        max-width: 300px;
    }
}
@media (max-width: 1400px) { 
    .about-page-production-tab-content iframe {
        max-width: 470px;
    }
}

@media (max-width: 1400px) { 
    .about-page-production-row {
        align-items: center;
    }
}
@media (max-width: 1400px) { 
    .about-page-large-content-wrap,
    .about-company-video-content-wrap {
      width: 45%;
    }
}
@media (max-width: 1400px) { 
    .about-page-short-content-wrap img {
      max-width: 100%;
    }
}
@media (max-width: 1024px) { 
    .about-company-facts-row,
    .about-company-prezentation-row,
    .about-company-production-row,
    .about-page-production-row,
    .about-page-production-tab-wrap,
    .about-page-contacts-row {
      max-width: 95%;
    }
}
@media (max-width: 1024px) { 
    .about-page-production-tab-link-wrap {
      max-width: 200px;
      font-size: 16px;
    }
}
@media (max-width: 1024px) { 
    .about-page-production-tab-link-wrap a {
        padding: 10px;
        text-align: center;
    }
}
@media (max-width: 1200px) { 
    .about-page-production-tab-content iframe {
        max-width: 400px;
    }
}
@media (max-width: 900px) { 
    .about-page-production-tab-content iframe {
        max-width: 450px;
    }
}
@media (max-width: 1023px) { 
    .about-company-fact-item-wrap {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        max-width: 50%;
        min-height: 250px;
        max-height: 250px;
        margin-bottom: 20px;
    }
}

@media (max-width: 1023px) { 
    .about-company-title-wrap,
    .about-company-text-content-wrap,
    .about-company-prezentation-btn-row {
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1023px) { 
    .about-company-prezentation-row,
    .about-company-icons-row,
    .about-page-production-row {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1023px) { 
    .about-company-prezentation-row,
    .about-company-production-row {
        margin-bottom: 40px;
    }
}
@media (max-width: 1023px) { 
    .about-company-prezentation-btn-row {
        margin-bottom: 20px;
    }
}

@media (max-width: 1023px) { 
    .about-company-text-content-wrap {
        width: 100%;
        max-width: 100%;
        order: 1;
    }
}

@media (max-width: 1023px) { 
    .about-company-video-content-wrap {
        width: 100%;
        max-width: 95%;
    }
}
@media (max-width: 1023px) { 
    .about-company-production-goods-row {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 1023px) { 
    .about-company-production-photo,
    .about-page-short-content-wrap {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 1023px) { 
    .about-page-short-content-wrap {
        width: 100%;
        max-width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1023px) { 
    .about-page-large-content-wrap {
        width: 95%;
        max-width: 95%;
        padding: 20px;
    }
}

@media (max-width: 1023px) { 
    .about-page-short-content-wrap img {
        width: 95%;
        max-width: 95%;
    }
}
@media (max-width: 1023px) { 
    .inner-page-form-row {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
}
@media (max-width: 1023px) { 
    .inner-page-form-item {
        width: 30%;
        max-width: 33%;
        margin-right: 10px;
    }
}
@media (max-width: 1023px) { 
    .inner-page-form input {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
    }
}
@media (max-width: 991px) { 
    .about-production-consult-popup-background {
        bottom: 50%;
    }
}
@media (max-width: 600px) { 
    .about-company-facts-row {
        flex-direction: column;
        flex-wrap: wrap;
        width: 95%;
    }
}
@media (max-width: 600px) { 
    .about-company-fact-item-wrap,
    .about-conpany-fact-icon-wrap {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 95%;
    }
}
@media (max-width: 600px) { 
    .about-company-text-content-wrap {
        width: 95%;
    }
}
@media (max-width: 600px) { 
    .about-company-production-title-wrap,
    .about-company-title-wrap {
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 600px) { 
    .about-company-production-good-item-row {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 600px) { 
    .about-page-production-tab-links-row {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        max-height: 150px;
        min-height: 150px;
        height: auto;
    }
}
@media (max-width: 600px) { 
    .about-page-production-tab-link-wrap {
        width: 50%;
        max-width: 50%;
        min-height: 60px;
        font-size: 14px;
        margin-bottom: 10px;
    }
}
@media (max-width: 600px) { 
    .about-page-production-tab-link-wrap.tab-active:last-child {
        border-radius: 0;
    }
}
@media (max-width: 600px) { 
    .about-page-tab-cooperation-item {
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        min-width: 150px;
        padding: 10px;
    }
}

@media (max-width: 600px) { 
    .about-page-req-table-wrap{
        font-size: 16px;
    }
}
@media (max-width: 600px) { 
    .about-page-production-tab-content {
        width: 100%;
    }
}
@media (max-width: 600px) { 
    .about-page-req-table-row {
        width: 95%;
        font-size: 14px;
        padding: 0;
    }
}
@media (max-width: 600px) { 
    .about-page-req-title {
        width: 40%;
        max-width: 40%;
    }
}
@media (max-width: 600px) { 
    .about-page-req-value {
        width: 55%;
        max-width: 60%;
    }
}
@media (max-width: 600px) { 
    .about-page-production-reviews-logo-row {
        width: 95%;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}
@media (max-width: 600px) { 
    .about-page-production-review-item {
        width: 50%;
        max-width: 50%;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 600px) { 
    .about-page-contacts-row,
    .about-page-tab-map-wrap {
        flex-direction: row;
        flex-wrap: wrap;
        width: 95%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}
@media (max-width: 600px) { 
    .about-page-tab-map-wrap {
        text-align: center;
    }
}
@media (max-width: 600px) { 
    .about-page-worktime-wrap {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 600px) { 
    .inner-page-form {
        width: 100%;
        min-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 600px) { 
    .inner-page-form-row,
    .inner-page-form-wrap {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) { 
    .inner-page-form-item {
        width: 65%;
        max-width: 100%;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 600px) { 
    .inner-page-form input {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 600px) { 
    .about-production-consult-popup-background {
        bottom: 45%;
    }
}
@media (max-width: 600px) { 
    .about-production-consult-popup-background,
    .about-production-consult-popup-wrap {
        width: 90%;
    }
}
@media (max-width: 600px) { 
    .about-production-consult-form-row {
        flex-direction: column;
    }
}
@media (max-width: 600px) { 
    .about-production-form-textarea-wrap textarea {
        max-width: 300px;
        min-width: 300px;
    }
}
@media (max-width: 600px) { 
    .form-mode-about-production-checkbox span {
        max-width: 90%;
    }
}









