.crafted-series-title-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
}
.crafted-series-title-wrap h3 {
    color: #00458B;
    font-size: 46px;
    line-height: 42px;
    margin: 0;
}
.crafted-series-row {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
    justify-content: center;
    overflow: hidden;
}
.crafted-series-slide-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    max-width: 800px;
    margin: 0 2% 0 2%;
    -moz-transition: margin 1s;
    -o-transition: margin 1s;
    -webkit-transition: margin 1s;
    transition: margin 1s;
}
.crafted-series-slide-item img {
    width: 600px;
    height: 400px;
    max-width: 600px;
    max-height: 600px;
    filter: brightness(0.5);
}
.crafted-series-slide-img img {
    display: flex;
    max-width: 420px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}
.crafted-series-slide-control {
    position: absolute;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    bottom: 60%;
}
.slide-item-arrow-left {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    left: 18%;
    top: 50%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_left.svg');
}
.slide-item-arrow-right {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    right: 18%;
    top: 50%;
    z-index: 5;
    background-image: url('../../img/slide_arrow_right.svg');
}
.slide-active {
    animation-name: rocket;
    animation-duration: 1s;
}
.slide-active img {
    filter: none;
}
.crafted-series-slide-img {
    max-width: 528px;
    object-fit: cover;
}
.crafted-series-slide-title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    bottom: 2%;
    min-height: 30px;
    max-height: 40px;
    background-color: #3FD2C7;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
}
.crafted-series-slide-title h5 {
    padding: 10px;
    color: white;
    font-size: 20px;
    margin: 0;
    width: 100%;
}
.rocket {
    opacity: 0;
    animation: ani 2.5s forwards;
}
.animated{
    animation-name: rocket;
    animation-duration: 1s;
}

@media (min-width: 200px) and (max-width: 800px)  {
    .crafted-series-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: hidden;
        margin: 0;
        padding: 0;
    }
}
@media (min-width: 200px) and (max-width: 800px)  {
    .crafted-series-slide-item {
        display:  none;
    }
}
@media (min-width: 200px) and (max-width: 800px)  {
    .slide-active {
        display:  flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 560px;
        justify-content: center;
        /* align-items: center; */
    }
}
@media (min-width: 200px) and (max-width: 800px)  {
    .crafted-series-slide-item img,
    .crafted-series-slide-title {
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        height: auto;
    }
}
@media (min-width: 200px) and (max-width: 800px)  {
    .slide-active {
        max-width: 528px;
    }
}

@media (min-width: 200px) and (max-width: 600px)  {
    .slide-item-arrow-left {
        left: 12%;
    }
}
@media (min-width: 200px) and (max-width: 600px)  {
    .slide-item-arrow-right {
        right: 12%;
    }
}
@media (min-width: 601px) and (max-width: 1000px)  {
    .slide-item-arrow-left {
        left: 0%;
    }
}
@media (min-width: 601px) and (max-width: 1000px)  {
    .slide-item-arrow-right {
        right: 0%;
    }
}
@keyframes rocket {
    0% {
        opacity: 0;
        filter: brightness(0);
    }
    0% {
        opacity: 0.5;
        filter: brightness(0.5);
    }
    100% {
        opacity: 1; 
        filter: brightness(1);
    }
}