.excursion-production-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
    position: relative; 
}
.excursion-production-row::before {
    max-width: 100%;
    max-height: 275px;
    overflow: hidden;
    content:  url('../../img/test22.jpg');
    position: absolute;
    opacity: 0.8;
    background-position: center center;
    z-index:-1;
    position:absolute;
    left:0;
    top:0;
    border-radius: 10px;
}
.excursion-production-text-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 60%;
    max-width: 70%;
    padding: 20px;
}
.excursion-production-text-wrap h3 {
    margin-bottom: 20px;
}
.excursion-production-text-wrap p {
    margin: 0 0 20px 0;
    padding-right: 20px;
}
.excursion-production-btn-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 35%;
    max-width: 35%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.excursion-production-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 280px;
    height: 40px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
}
.excursion-production-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}
.excursion-production-popup-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 900px;
    min-height: 300px;
    position: absolute;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #00458B;
    border-radius: 10px;
    padding: 20px;
    animation-name: grow;
    animation-duration: 0.3s;
}
.excursion-production-popup-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    min-height: 220px;
}
.excursion-production-close-btn::before {
    content: url('../../img/close_popup.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
}
.excursion-production-close-btn:hover::before {
    content: url('../../img/close_popup_hover.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
}
.excursion-production-title-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.excursion-production-form-input-wrap label {
    color: #00458B;
    font-family: gothampro bold;
    font-size: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}
.excursion-production-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 750px;
    margin-bottom: 30px;
}
.excursion-production-form-input-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 25%;
    margin-right: 10px;
}
.excursion-production-form-input-wrap input {
    min-height: 30px;
    max-width: 150px;
}

.excursion-production-send-btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.excursion-production-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    max-width: 200px;
    height: 35px;
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #3FD2C7;
    padding: 0 40px;
    outline: none;
    cursor: pointer;
}
.excursion-production-send-btn:hover {
    background-color: #00458B;
    transition: background .4s ease;
    color: white;
}

.get-excursion-popup-checkbox-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}
.get-excursion-popup-checkbox {
    display: none;
}
.get-excursion-popup-checkbox + label {
    position: relative;
    padding-left: 30px;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    user-select: none;
}
.get-excursion-popup-checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    background-image: url('../../img/form_checkbox.svg');
    background-size: contain;
}
.get-excursion-popup-checkbox:checked + label::before {
    border-color: #000;
    background-image: url('../../img/form_checkbox_checked.svg');
    background-size: contain;
}
.form-mode-get-excursion-popup-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
@media (max-width: 1024px) { 
    .excursion-production-row {
        width: 95%;
        padding: 10px;
    }
}
@media (max-width: 900px) { 
    .excursion-production-btn {
        padding: 0 20px;
        font-size: 16px;
    }
}
@media (max-width: 900px) { 
    .excursion-production-btn-wrap {
        width: 30%;
    }
}
@media (max-width: 900px) { 
    .excursion-production-popup-wrap {
        max-width: 750px;
    }
}
@media (max-width: 850px) { 
    .excursion-production-text-wrap{
        max-width: 55%;
        width: 55%;
    }
}
@media (max-width: 850px) { 
    .excursion-production-btn {
        padding: 0 10px;
    }
}
@media (max-width: 850px) { 
    .excursion-production-btn {
        padding: 0 10px;
    }
}
@media (max-width: 800px) { 
    .excursion-production-popup-wrap {
        max-width: 700px;
    }
}
@media (max-width: 800px) { 
    .excursion-production-form-input-wrap {
        max-width:22%
    }
}
@media (max-width: 800px) { 
    .excursion-production-popup-wrap {
        max-width: 650px;
    }
}
@media (max-width: 800px) { 
    .excursion-production-btn {
        padding: 0 10px;
        font-size: 16px;
    }
}
@media (max-width: 600px) { 
    .excursion-production-row {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 600px) { 
    .excursion-production-text-wrap {
        width: 90%;
        max-width: 90%;
    }
}
@media (max-width: 600px) { 
    .excursion-production-btn-wrap {
        width: 90%;
        max-width: 90%;
        height: auto;
    }
}
@media (max-width: 600px) { 
    .excursion-production-row {
        max-width: 450px;
    }
}

@media (max-width: 600px) { 
    .excursion-production-popup-body {
        max-width: 450px;
    }
}
@media (max-width: 600px) { 
    .excursion-production-form-row {
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
    }
}
@media (max-width: 600px) { 
    .excursion-production-form-input-wrap {
        width: 100%;
        max-width: 250px;
    }
}

@media (max-width: 600px) { 
    .excursion-production-title-wrap {
        text-align: center;
        width: 100%;
        max-width: 450px;
        margin-top: 30px;
    }
}
@media (max-width: 600px) { 
    .excursion-production-form-input-wrap input {
        min-width: 200px;
        max-width: 300px;
    }
}
@media (max-width: 600px) { 
    .excursion-production-popup-wrap {
        top: -25%;
        left: -5%;
    }
}
@media (max-width: 500px) { 
    .excursion-production-popup-wrap {
        max-width: 350px;
    }
}
@media (max-width: 500px) { 
    .form-mode-get-excursion-popup-checkbox {
        flex-wrap: nowrap;
        text-align: center;
        width: 75%;
    }
}
@media (max-width: 400px) { 
    .excursion-production-popup-wrap {
        max-width: 330px;
        left: 2%;
        margin: 0;
    }
}
@media (max-width: 360px) { 
    .excursion-production-popup-wrap {
        left: 2%;
        max-width: 300px;
    }
}
